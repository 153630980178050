import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');
type AOA = any[][];
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-main-clients',
  templateUrl: './main-clients.component.html',
  styleUrls: ['./main-clients.component.css']
})
export class MainClientsComponent implements OnInit {


  results: Array<any> = [];
  ListeFactureClient: Array<any> = [];
  ListeReglementsClient: Array<any> = [];
  ListeFichierImporterToSave: Array<any> = [];
  ListeLignesSorties :  Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItemsDetailLigneSortie : number = 0;

  totalItems = 0;
  currentPage = 1;
  currentPageDetailLigneSortie = 1;
  itemsPerPageDetailLigneSortie = 10;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  oldValueSearch: String;
  isChangeItermPerPage: boolean = false;
  refreshCreditPourTousLeMonde: number;
  refreshCreditParClient: number;
  refreshVersementClient: number;
  enumStatut: any = {};
  totalItemsDetailFacture: number;
  itemsPerPageDetailFacture: number = 4;
  currentPageDetailFacture: number = 1;

  totalItemsReglement: number;
  itemsPerPageReglement: number = 4;
  currentPageReglement: number = 1;
  enumStatutReglement: any = {};


  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {
      filtreStatutSelected : 'reste_a_payer'
    };
    this.oldValueSearch = "";
    this.enumStatutReglement = enumStatutReglementStorePro;
  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified, isSuiviCredit?: boolean,isSuiviLigneSortie ? : boolean) {
    this.cancelSave();

    if (itemToModified) {
      // console.log("le item to modified ",itemToModified);
      this.itemToSave = Object.assign({}, itemToModified);

      if (isSuiviCredit) {
        this.getDataSortieByClient(this.itemToSave.id);

        this.ListeReglementsClient = [];
        this.getDataReglementByClient(this.itemToSave.id);
      }

      if(isSuiviLigneSortie)
      {
        this.currentPageDetailLigneSortie = 1;
        this.getDataLigneSortieByClient(this.itemToSave.id)
      }
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom' }));
  }

  selectedChangeValue(index) {
    // console.log("l'index seectionné "+index);
    if (index == 1) {
      this.refreshCreditPourTousLeMonde = new Date().getTime();
    }

    if (index == 2) {
      this.refreshCreditParClient = new Date().getTime();
    }

    if (index == 3) {
      this.refreshVersementClient = new Date().getTime();
    }

  }

  refreshAfterSaveVersement($event) {
    if ($event) {
      this.hideModal();
      this.getData();
    }
  }

  openFormModalModalVersement(template, itemToModified) {
    this.cancelSave();

    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-sm' }));
  }

  getDataSortieByClient(idClient) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientId: idClient
      },
      index: (this.currentPageDetailFacture - 1),
      size: this.itemsPerPageDetailFacture ? this.itemsPerPageDetailFacture : null
    }

    this.busySave = this.api.post('sortie/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.ListeFactureClient = res['items']; //_.orderBy(res['items'], ['id'], ['asc']);
            this.totalItemsDetailFacture = res['count'] ? res['count'] : 0;
          } else {
            this.ListeFactureClient = [];
          }
        },
        err => {
        }
      );
  }

  getDataReglementByClient(idClient) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientId: idClient
      },
      index: (this.currentPageReglement - 1),
      size: this.itemsPerPageReglement ? this.itemsPerPageReglement : null
    }

    this.busySave = this.api.post('reglement/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici les lignes reglements', res);
          if (res && res['items']) {
            this.ListeReglementsClient = res['items'];
            this.totalItemsReglement = res['count'] ? res['count'] : 0; 
          } else {
            this.ListeReglementsClient = [];
          }
        },
        err => {
        }
      );
  }


  getDataLigneSortieByClient(idClient) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientId: idClient
      },
      index: (this.currentPageDetailLigneSortie - 1),
      size: this.itemsPerPageDetailLigneSortie ? this.itemsPerPageDetailLigneSortie : null
    }

    this.busySave = this.api.post('viewLigneSortie/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.ListeLignesSorties = res['items']; //_.orderBy(res['items'], ['id'], ['asc']);
            this.totalItemsDetailLigneSortie = res['count'] ? res['count'] : 0;
          } else {
            this.ListeLignesSorties = [];
          }
        },
        err => {
        }
      );
  }

  pageChangedLigneSortie(event: any): void {
    this.currentPageDetailLigneSortie = event.page;
    this.getDataLigneSortieByClient(this.itemToSave.id);
  }

  pageChangedReglement(event: any): void {
    this.currentPageReglement = event.page;
    this.getDataReglementByClient(this.itemToSave.id);
  }

  pageChangedDetailFacture(event: any): void {
    this.currentPageDetailFacture = event.page;
    this.getDataSortieByClient(this.itemToSave.id);
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.gotoFiltreStatutClient();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.gotoFiltreStatutClient();
  }

  getData() {

    let search_value = this.itemToSearch.designation;
    

    var request = {
      user: this.user.proxy,
      data: {
        libelle: search_value ? search_value : null,
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        // statut : enumStatutStorePro.ACTIF
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    // this.busyGet = this.api.post('viewClientAyantResteAPayer/getByCriteria', request)

    this.busyGet = this.api.post('client/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );

  }

  // getDataAllClient() {

  //   let search_value = this.itemToSearch.designation;
    

  //   var request = {
  //     user: this.user.proxy,
  //     data: {
  //       libelle: search_value ? search_value : null,
  //       boutiqueId: this.userService.getCurrentBoutiqueUser(),
  //       // statut : this.itemToSearch.filtreStatutSelected == "all" ? enumStatutStorePro.ACTIF : enumStatutStorePro.SUSPENDRE
  //     },
  //     index: (this.currentPage - 1),
  //     size: this.itemsPerPage ? this.itemsPerPage : null
  //   }

  //   this.busyGet = this.api.post('client/getByCriteria', request)
  //     .subscribe(
  //       res => {
  //         console.log(res);
  //         this.isChangeItermPerPage = false;
  //         if (res && res['items']) {
  //           this.results = res['items'];
  //           this.totalItems = res['count'] ? res['count'] : 0;
  //         } else {
  //           this.results = [];
  //           this.totalItems = 0;
  //         }
  //       },
  //       err => {
  //       }
  //     );

  // }

  confirmSaveItem(obj) {

    let item = Object.assign({}, obj);

    if (!item || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé du client.")
      return;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();

    if (!item.statut) {
      item.statut = this.enumStatut.ACTIF
    }

    swal({
      title: (!obj.id) ? "Enregistrement d'un client" : "Modification d'un client",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  confirmSuspendreClient(obj) {

    let item = Object.assign({}, obj);

    if (!item || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé du client.")
      return;
    }

    let title = "Supprimer";

    if (item.statut == enumStatutStorePro.SUSPENDRE) {
      item.statut = enumStatutStorePro.ACTIF;
      title = "Aciver";

    }
    else {
      item.statut = enumStatutStorePro.SUSPENDRE;
    }

    swal({
      title: title, //"Supprimer client",
      text: 'Désirez-vous supprimer ce client ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item, true);
      } else {
      }
    })
  }

  gotoFiltreStatutClient()
  {
    if(this.itemToSearch.filtreStatutSelected == 'reste_a_payer')
    {
      // On appel la focntion des reste a payer
      this.getData();
    }
    else
    {
      // this.getDataAllClient();
    }
  }

  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item, isSuspendre?) {


    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('client/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Client", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            if (!isSuspendre) {
              this.hideModal();
            }

            this.cancelSave();
            this.gotoFiltreStatutClient();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  onFileChange(evt: any) {

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      var dataSheet1 = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      this.formatDataXlsx(dataSheet1);

    };

    reader.readAsBinaryString(target.files[0]);
  }

  formatDataXlsx(listeRowExcel) {
    console.log("ligne excle", listeRowExcel);

    if (listeRowExcel.length > 0) {
      for (var i = 1; i < listeRowExcel.length - 1; i++) {
        let currentRow = listeRowExcel[i];

        console.log("currentRow currentRow", currentRow)

        if (!currentRow[1]) {
          console.log("le i", i);
          console.log("le currentRow ", currentRow);
        }

        let objExport = {
          libelle: currentRow[1],
          contact: currentRow[2],
          statut: "ACTIF",
          boutiqueId: this.userService.getCurrentBoutiqueUser()
        }

        this.ListeFichierImporterToSave.push(objExport);
      }
    }
  }


  importItem() {
    
    if (!this.ListeFichierImporterToSave || this.ListeFichierImporterToSave.length == 0) {
      this.notificationService.showMessageInfo("Information", "Veuillez importer un fichier excel.")
      return;
    }

    var request = {
      user: this.user.id,
      datas: this.ListeFichierImporterToSave
    }

    this.busySave = this.api.post('client/create', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Importation", 'Opération effectuée avec succès.');
            this.currentPage = 1;
            this.modalRef.hide();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.notificationService.defaultMessage());
        }
      );
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['isRefresh'] && changes['isRefresh'].currentValue) {
      this.getData();
    }
  }


  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1;
        this.getData();
      }
    });

  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
