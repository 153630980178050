import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing'
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';

// Core providers
import { CoreModule} from "./core/core.module";
import { SmartadminLayoutModule} from "./shared/layout/layout.module";
import { SmartadminModule} from "./shared/smartadmin.module";
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { LoginComponent} from "./+auth/+login/login.component";
import { NgDatepickerModule } from 'ng2-datepicker';
import { MainProtocoleComponent } from './+components/main-protocole/main-protocole.component';
import { MainEquipementComponent } from './+components/main-equipement/main-equipement.component';
import { DaterangepickerModule } from 'angular-2-daterangepicker';
import { FormReinitPasswordComponent } from './+components/form-reinit-password/form-reinit-password.component';
import { LoggedInGuardService} from "./shared/utils/guards/logged-in-guard.service";
import { MainParametreLogoComponent } from './+components/main-parametre-logo/main-parametre-logo.component';
import { MainUpdateProfilUserConnectedComponent } from './+components/main-update-profil-user-connected/main-update-profil-user-connected.component';
import { MainHeureLeveSoleilDesktopComponent } from './+components/main-heure-leve-soleil-desktop/main-heure-leve-soleil-desktop.component';
import { MainHeureHauteurMareeDesktopComponent } from './+components/main-heure-hauteur-maree-desktop/main-heure-hauteur-maree-desktop.component';
import { MainLoaderWaitComponent } from './+components/main-loader-wait/main-loader-wait.component';
import { MainParametreTypeCarteComponent } from './+components/condicaf/main-parametre-type-carte/main-parametre-type-carte.component';
import { MainMenuComponent } from './+components/condicaf/main-menu/main-menu.component';
import { MainConsommationsComponent } from './+components/condicaf/main-consommations/main-consommations.component';
import { MainParametreCarteComponent } from './+components/condicaf/main-parametre-carte/main-parametre-carte.component';
import { MainHistoriqueConnexionComponent } from './+components/shell-boutique/main-historique-connexion/main-historique-connexion.component';
import { BusyModule } from 'angular2-busy';
import { MainStatistiqueCommercialComponent } from './+components/app-boutique/main-statistique-commercial/main-statistique-commercial.component';
// import { MainCreditVersementComponent } from './+components/app-boutique/main-credit-versement/main-credit-versement.component';
// import { UtilisateurStoreProComponent } from './+components/app-boutique/utilisateur-store-pro/utilisateur-store-pro.component';
// import { ProfilStoreProComponent } from './+components/app-boutique/profil-store-pro/profil-store-pro.component';
// import { FormPreviewPrintComponent } from './+components/app-boutique/form-preview-print/form-preview-print.component';
// import { FormCommandeComponent } from './+components/app-boutique/form-commande/form-commande.component';
// import { HistoriqueCommandeComponent } from './+components/app-boutique/historique-commande/historique-commande.component';
// import { MainCapaciteParametrageComponent } from './+components/app-boutique/main-capacite-parametrage/main-capacite-parametrage.component';
// import { MainCreditPourToutLeMondeComponent } from './+components/app-boutique/main-credit-pour-tout-le-monde/main-credit-pour-tout-le-monde.component';
// import { MainCouleurTelephoneComponent } from './+components/app-boutique/main-couleur-telephone/main-couleur-telephone.component';
// import { MainHistoriqueTrocAvecSortieArticleComponent } from './+components/app-boutique/main-historique-troc-avec-sortie-article/main-historique-troc-avec-sortie-article.component';
// import { MainHistoriqueTrocSansSortieArticleComponent } from './+components/app-boutique/main-historique-troc-sans-sortie-article/main-historique-troc-sans-sortie-article.component';
// import { MainParametreBaseComponent } from './+components/app-boutique/main-parametre-base/main-parametre-base.component';
// import { MainDashbordComponent } from './+components/app-boutique/main-dashbord/main-dashbord.component';
// import { SearchInputArticleComponent } from './+components/app-boutique/search-input-article/search-input-article.component';
// import { SearchInputIntervenantComponent } from './+components/app-boutique/search-input-intervenant/search-input-intervenant.component';
// import { MainTechnicienReparateurComponent } from './+components/app-boutique/main-technicien-reparateur/main-technicien-reparateur.component';


// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
  LoggedInGuardService
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
   // FormReinitPasswordComponent,   
    LoginComponent,
  
  //  FormCommandeComponent,
  //  HistoriqueCommandeComponent,
  //  MainCapaciteParametrageComponent,
  // MainCouleurTelephoneComponent,
  //  MainHistoriqueTrocAvecSortieArticleComponent,
  //  MainHistoriqueTrocSansSortieArticleComponent,
  //  MainParametreBaseComponent,
  //  MainDashbordComponent,
  //  SearchInputArticleComponent,
  //  SearchInputIntervenantComponent,
  //  MainTechnicienReparateurComponent,

  //  FormTrocSansSortieArticleComponent,
  //  FormTrocAvecSortieArticleComponent,
  //  MainCompteIcloudComponent,
  //  MainImeiFournisseurComponent,
  //  MainBoutiqueComponent,
  //  MainCreditPourToutLeMondeComponent,
   
  //  FormPreviewPrintComponent,

  //  MainCreditVersementComponent,
  //  MainEcartApproVenteComponent,
  //  FormReglementComponent,
 
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SmartadminModule,
    CoreModule,
    SmartadminLayoutModule,
    DaterangepickerModule,
    NgDatepickerModule,
    BusyModule,
    routing
  ],
  exports: [
    LoginComponent
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    APP_PROVIDERS,
    
  ],
 // bootstrap: [FormReinitPasswordComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}


}

