import {Component, ViewContainerRef} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { UserService } from './shared/user';
import { Api } from './shared/utils/api/api';
import { NotificationService } from './shared/utils/notification.service';
import { UtilitiesService } from './shared/utils/utilities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormReconnectComponent } from './+components/shell-boutique/form-reconnect/form-reconnect.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'  // '<router-outlet></router-outlet>'
})
export class AppComponent {
  public title = 'app works!';

 
  private config:any = {};
  user : any;
  waitRefresh : boolean;
  bsModalRef : BsModalRef;
  intervalle = 1000;
  countTer = 0;
  activite_detectee : boolean; 
  logoutModalIsLunch : boolean = false;
  lunchFonction : any;
  deconnectionTimer : number = 5; 

  public constructor(private router: Router,private activedRoute: ActivatedRoute,private viewContainerRef: ViewContainerRef,private modalService: BsModalService,private userService: UserService,private api:Api,private notificationService : NotificationService,private utilities : UtilitiesService) {
 
    //this.user = this.userService.getCurrentUser();  
    //this.config = config;
    //this.user = this.userService.getCurrentUser() ? this.userService.getCurrentUser() : null;
   // this.deconnectionTimer = (this.user.deconnectionTimer ? this.user.deconnectionTimer : this.deconnectionTimer) * 60000;
   // this.initTimoutActivityScreem();  
   

  }


  
}
