import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap";
import {Subscription} from 'rxjs/Subscription';
import {Api} from '../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {NotificationService} from '../../shared/utils/notification.service';
import {UtilitiesService} from '../../shared/utils/utilities.service';
import {EnumCodeParametreBase} from '../../shared/utils/enumerator';
import { FormSearchReferenceSaisieCorrectionComponent } from '../form-search-reference-saisie-correction/form-search-reference-saisie-correction.component';

import {ModelService} from '../../shared/utils/model.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormReinitPasswordComponent } from '../../+components/form-reinit-password/form-reinit-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component_2.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  itemToSave : any;
  bsModalRef: BsModalRef;
  busyConnecte : Subscription;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api:Api,public router:Router,private route: ActivatedRoute,private modalService: BsModalService,private notificationService : NotificationService,private utilities : UtilitiesService,private modelService : ModelService,private _localeService: BsLocaleService) {
       
    this.itemToSave = {};
  }

  ngOnInit() {
  }

  openChangePassword(user) {
    const initialState = {
      title : "Changer votre mot de passe.",
      config : this.config
    };
    this.bsModalRef = this.modalService.show(FormReinitPasswordComponent, Object.assign({}, this.config, { class: 'gray modal-sm modal-custom-reinit' }));
    this.bsModalRef.content.title = 'Changer votre mot de passe';
    this.bsModalRef.content.currentUser = user;
  }

  login(item)
  {
    console.log("le item",item);
    item.showLoader = true;
    if(!item  || !item.login || !item.password)
    {
      item.showLoader = false;
      this.notificationService.showMessageError("Erreur","Veuillez renseigner les champs obligatoires.");
      return;
    }
    // let currentUser = {
    //   nom : "ADOU ",
    //   prenoms : "VENANCE ",
    //   id : 1,
    //   proxy : 1
    // }
    // localStorage.setItem("yz-backoffice-app-boutique-store-pro",JSON.stringify(currentUser));
    // this.router.navigate(['/home']);
    
    // return;

    var request = {
      //user : "1",
      "data":
        {
          "login": item.login,
          "password": item.password,
          "action" : "Connexion",
          "source":"Web",
          "referenceSource":  navigator.userAgent
  
        } 
    }

     this.busyConnecte = this.api.connexion('user/connexion', request)
          .subscribe(
              res => {
                  console.log('get user',res); 
                  item.showLoader = false;
                  if(!res['hasError'] && res['items'])
                  {
                   
                    var currentUser = res['items'][0];
                    currentUser.proxy = currentUser.id;
                    if(currentUser && currentUser.fonctionnalites && currentUser.fonctionnalites.length > 0)
                    {
                      currentUser.fonctionnalites.push({code : "DASH",libelle : 'DASHBORD'});
                    }
                    
                    localStorage.setItem("yz-backoffice-app-boutique-store-pro",JSON.stringify(currentUser));

                    if(currentUser.isDefaultPassword)
                    {
                      this.itemToSave = {};
                      currentUser.isFromLogin = true;
                      localStorage.setItem("yz-backoffice-app-boutique-store-pro",JSON.stringify(currentUser));
                      this.openChangePassword(currentUser);
                      return;
                    }
                   
                    this.router.navigate(['/home']);
                  }
                  else
                  {
                    this.notificationService.showMessageError("Connexion", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                  }
              },
              err => {
                  console.log("Error occured G",err);
                  item.showLoader = false;
              }
          );

    }

    motDePassOublie(item)
    {

      console.log("le item",item);
      item.showLoader = true;
      if(!item  || !item.libellePassForget)
      {
        item.showLoader = false;
        this.notificationService.showMessageError("Erreur","Veuillez renseigner le Login / email / Contact.");
        return;
      }
      
      var request = {
        //user : "1",
        "datas":[
          {
            "login": item.libellePassForget 
            } 
         ]
        
      }
  
       this.busyConnecte = this.api.connexion('user/motDePasseOublie', request)
            .subscribe(
                res => {
                    console.log('get user',res); 
                    item.showLoader = false;
                    if(!res['hasError'] && res['items'])
                    {
                      this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
                      this.notificationService.showMessageSucces("Mot de passe oublié",this.itemToSave.message);
                      this.updatePassWord();
                    }
                    else
                    {
                      this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
                      this.notificationService.showMessageError("Connexion",res['status'].message);
                    }
                },
                err => {
                    console.log("Error occured G",err);
                    item.showLoader = false;
                }
            );

            
    }

    updatePassWord()
    {
      this.itemToSave.isPasswordForget = !this.itemToSave.isPasswordForget;
    }

}
