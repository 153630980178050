import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'app-main-gestion-produit',
  templateUrl: './main-gestion-produit.component.html',
  styleUrls: ['./main-gestion-produit.component.css']
})
export class MainGestionProduitComponent implements OnInit {

  refreshValueIndice : number = 1;
  user : any = {};
  constructor(private userService: UserService) {
    this.user = this.userService.getCurrentUser();
  }

  
  selectedChangeValue(indiceTab)
  {
    
    if(indiceTab == 0)
    {
      this.refreshValueIndice = this.refreshValueIndice + 1;
    }
  }

  
  ngOnInit() {
  }

}
