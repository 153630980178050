import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumLibelleStatutDesktop, enumActionLibelle, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-ecart-appro-vente',
  templateUrl: './main-ecart-appro-vente.component.html',
  styleUrls: ['./main-ecart-appro-vente.component.css']
})
export class MainEcartApproVenteComponent implements OnInit {

  
  results: Array<any> = [];
  ListeProduits: Array<any> = [];
  ListeApproToSave: Array<any> = [];
  ListeClients: Array<any> = [];
  ListeApprovisionnements : Array<any> = [];
  busyGet: Subscription;
  busyGetProduit: Subscription;
  itemToSave: any = {};
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  currentSymboleDevise: any = {};
  currentArticleSelectedForSearch : any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  montantTotalPeriode: number = 0;
  quantiteTotalPeriode: number = 0;
  montantTotalReglementPeriode : number = 0;

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  totalItemsApprovisionnement : number = 0;
  itemsPerPageApprovisionnement : number = 5;
  currentPageApprovisionnement : number = 1;

  quantiteTotalVenduePeriode : any = 0;
  quantiteTotalApprovisionnePeriode : any = 0;
  enumStatutReglement : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  // @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
  /*private daterangepickerOptions: DaterangepickerConfig*/) {

    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);

    let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);
    this.enumStatutReglement = enumStatutReglementStorePro;


    this.user = this.userService.getCurrentUser();
    this.currentSymboleDevise = this.userService.getCurrentDevise();
    this.itemsPerPage = 5;
    this.itemToSearch = {
      dateDebut: new Date(), //dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin: new Date() //dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };
    this.oldValueSearch = "";

    const tableauMois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    let libelleMois = "Ce mois de " + tableauMois[new Date().getMonth()];

  }

  maxDate = new Date();


  selectedChangeValue(indiceTab) {

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getDataVente();
  }

  pageChangedApprovisionnement(event: any): void {
    this.currentPageApprovisionnement = event.page;
    this.getDataApprovisionnement();
  }


  // getDataProduits() {

  //   var request = {
  //     user: this.user.proxy,
  //     "data": {
  //       boutiqueId: this.userService.getCurrentBoutiqueUser()
  //     }
  //   }

  //   this.busyGetProduit = this.api.post('article/getByCriteria', request)
  //     .subscribe(
  //       res => {
  //         console.log('voici le resultat', res);
  //         if (res && res['items']) {
  //           this.ListeProduits = _.orderBy(res['items'], ['libelle'], ['asc']);
  //           this.itemToSave.produitSelected = this.ListeProduits[0];
  //           this.getDataVente(this.itemToSave.produitSelected.id);
  //           this.getDataApprovisionnement(this.itemToSave.produitSelected.id);
  //         } else {
  //           this.ListeProduits = [];
  //           // this.itemToSave.produitSelected = null;  
  //         }
  //       },
  //       err => {
  //       }
  //     );
  // }


  selectProduct = function (item) {
    console.log(item);
    console.log(this.currentArticleSelectedForSearch);
    if (!item) {
      this.notificationService.showMessageError("Erreur","Veuillez sélectionner un article.");
      this.ListeApprovisionnements = [];
      this.results = [];
      this.quantiteTotalVenduePeriode = 0;
      this.totalItemsApprovisionnement = 0;
      this.totalItems = 0;
      this.quantiteTotalApprovisionnePeriode = 0; 
      this.itemToSearch.quantiteEnStock = 0;
      this.getQuantiteArticleEnStock(0,0);
      return; 
    };
    
    this.currentArticleSelectedForSearch =  Object.assign({}, item);
    this.getDataVente(item.id);
    this.getDataApprovisionnement(item.id);
  };

  getDataVente(idArticle?, idClient?) {

    var dateDebut = moment(this.itemToSearch.dateDebut, "YYYYMMDD");
    var dateFin = moment(this.itemToSearch.dateFin, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") < 0) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la période sélectionnée.");
      this.results = [];
      this.montantTotalPeriode = 0;
      this.quantiteTotalPeriode = 0;
      return;
    }

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        isGetForStatistique: true,
        articleId : idArticle,
        dateSortie: null,
        dateSortieParam: {}
      },
      // index: (this.currentPage - 1),
      // size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateSortie = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateSortieParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post('ligneSortie/getByCriteria', request)
      .subscribe(
        res => {
          console.log('liste des ventes',res);
          if (res && res['items']) {
            this.isChangeItermPerPage = false;
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.itemToSearch.qteVenduByPeriode = res['quantiteTotalPeriode'];
            //this.montantTotalPeriode = res['montantTotalPeriode'] ? res['montantTotalPeriode'] : 0;
            this.quantiteTotalVenduePeriode = res['quantiteTotalPeriode'] ? this.userService.getFormatMillierInput(res['quantiteTotalPeriode'],true) : 0;
          } else {
            this.results = [];
            //this.montantTotalPeriode = 0;
            this.quantiteTotalVenduePeriode = 0;
            this.itemToSearch.qteVenduByPeriode = 0;
          }

          this.getQuantiteArticleEnStock(this.itemToSearch.qteApproByPeriode,this.itemToSearch.qteVenduByPeriode);
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  
  getDataApprovisionnement(idArticle?) {

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        isGetForStatistique: true,
        articleId : idArticle,
        dateOperation: null,
        dateOperationParam: {},
        // action : enumActionLibelle.APPROVISIONNEMENT
      },
      // index: (this.currentPageApprovisionnement - 1),
      // size: this.itemsPerPageApprovisionnement ? this.itemsPerPageApprovisionnement : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateOperation = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateOperationParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post('approvisionnement/getByCriteria', request)
      .subscribe(
        res => {
          console.log(" voici la liste des approvisionnements ",res);
          if (res && res['items']) {
            this.ListeApprovisionnements = res['items'];
            this.totalItemsApprovisionnement = res['count'] ? res['count'] : 0;
            this.itemToSearch.qteApproByPeriode = res['quantiteTotalPeriode'];
            this.quantiteTotalApprovisionnePeriode = res['quantiteTotalPeriode'] ? this.userService.getFormatMillierInput(res['quantiteTotalPeriode'],true) : 0;
            // this.quantiteTotalPeriode = res['quantiteTotalPeriode'] ? res['quantiteTotalPeriode'] : 0;
          } else {
            this.ListeApprovisionnements = [];
            this.quantiteTotalApprovisionnePeriode = 0;
            this.totalItemsApprovisionnement = 0;
          }
          this.getQuantiteArticleEnStock(this.itemToSearch.qteApproByPeriode,this.itemToSearch.qteVenduByPeriode);
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  getQuantiteArticleEnStock(qteAppro,qteVendu)
  {
    this.itemToSearch.quantiteEnStock = (qteAppro || 0) - (qteVendu || 0);
    this.itemToSearch.quantiteEnStock = this.userService.getFormatMillierInput(this.itemToSearch.quantiteEnStock,true);
  }

  searchItem()
  {
    this.getDataVente(this.itemToSearch.produitSelected.id);
    this.getDataApprovisionnement(this.itemToSearch.produitSelected.id);
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 5) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
   
    //this.getMontantTotalDepenses();

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        // this.currentPage = 1; 
        // this.getDataProduits();
      } 
    });
  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
