import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-couleur-telephone',
  templateUrl: './main-couleur-telephone.component.html',
  styleUrls: ['./main-couleur-telephone.component.css']
})
export class MainCouleurTelephoneComponent implements OnInit {

  
  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  oldValueSearch : String;
  isChangeItermPerPage : boolean = false;
  enumStatut : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {};
    this.oldValueSearch = "";  
  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
   
    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);
     }
    
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-md' }));
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }


  getData() {

    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;

    if(search_value && this.oldValueSearch)
    {
      if(search_value != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }
   
    if(search_value){
       search_value = search_value.trim();
       
       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
         //this.currentPage = 1;
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }

      this.oldValueSearch = ""; 
    } 

    var request = {
      user: this.user.proxy,
      data: {
        libelle : search_value ? search_value : null,
        // boutiqueId : this.userService.getCurrentBoutiqueUser()
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('couleurTelephone/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false; 
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
        }
      );

  }


  confirmSaveItem(obj) {
    let item =  Object.assign({}, obj);

    if (!item || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé.")
      return;
    }


    swal({
      title: (!obj.id) ? "Enregistrement d'une couleur téléphone" : "Modification d'une couleur téléphone",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }



  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item,isSuspendre?) {

  
    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('couleurTelephone/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Couleur téléphone", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            if(!isSuspendre)
            {
              this.hideModal();
            }
          
            this.cancelSave(); 
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  confirmDeleteItem(obj) {
   
    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj);
      }
    })

  }

  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id
    }]
    }

    this.busyGet = this.api.post('couleurTelephone/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getData();
  }

}
