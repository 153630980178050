import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserService} from "../../shared/user/user.service";
import {Api} from '../../shared/utils/api/api';
import {NotificationService} from '../../shared/utils/notification.service';
import { Subscription } from 'rxjs';
import {Router} from "@angular/router";
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Component({
  selector: 'app-form-reinit-password',
  templateUrl: './form-reinit-password.component.html',
  styleUrls: ['./form-reinit-password.component.css']
})
export class FormReinitPasswordComponent implements OnInit {

  itemToSave : any;
  user : any;
  busySave : Subscription;
  currentUser : any;

  constructor(public bsModalRef: BsModalRef,private api:Api,private notificationService : NotificationService,private userService: UserService,private router: Router,private utilities : UtilitiesService) {
    this.itemToSave = {};
    //this.user = this.userService.getCurrentUser();

    setTimeout(() => {
      this.itemToSave  = Object.assign({},this.currentUser);
    }, 1000);

   }


saveItem(item)
{
    if(!item || !item.password || !item.newpassword)
    {
        this.notificationService.showMessageInfo("Information","Veuillez renseigner l'ancien et le nouveau mot de passe.")
        return;
    }

    if(item.ConfirmNewPassword != item.newpassword)
    {
      this.notificationService.showMessageInfo("Information","Veuillez confirmer votre mot de passe.")
      return;
    }

    item.proxy = this.itemToSave.proxy;
   
    var request = {
      user : this.itemToSave.proxy,
      "data" : {
        id : item.id,
        password : item.password,
        nom : item.nom,
        newPassword : item.newpassword,
        prenom : item.prenom,
        login : item.login
      }
    }

    this.busySave = this.api.post('user/changerMotDePasse', request)
        .subscribe(
            res => {
              console.log("resul",res);
                if(!res['hasError'])
                {
                    this.notificationService.showMessageSucces("Mot de passe",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                    // on le deconnecte
                    this.bsModalRef.hide();
                    
                    // localStorage.removeItem("yz-backoffice-app-boutique-store-pro");
                    // var currentUser = res['items'][0];
                    // //localStorage.setItem("yz-backoffice-app-boutique-shell",JSON.stringify(currentUser));

                    // if(currentUser && currentUser.fonctionnalites && currentUser.fonctionnalites.length > 0)
                    // {
                    //   currentUser.fonctionnalites.push({code : "DASH",libelle : 'DASHBORD'});
                    // }
                    
                    //localStorage.setItem("yz-backoffice-app-boutique-store-pro",JSON.stringify(currentUser));
                    
                    if(this.currentUser.isFromLogin)
                    {
                      this.router.navigate(['/home']);
                    }
                    else
                    {
                      //this.router.navigate(['/auth/login']);
                    }
                 

                }else
                {
                  if(res['status'] && res['status']['message'])
                  {
                    this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                  }
                }
            },
            err => {
                console.log("Error occured",err);
                //this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
            }
        );
}


  ngOnInit() {
  }

}
