import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-credit-pour-tout-le-monde',
  templateUrl: './main-credit-pour-tout-le-monde.component.html',
  styleUrls: ['./main-credit-pour-tout-le-monde.component.css']
})
export class MainCreditPourToutLeMondeComponent implements OnInit {

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  results: Array<any> = [];
  ListeProduits: Array<any> = [];
  ListeApproToSave: Array<any> = [];
  ListeClients: Array<any> = [];
  busyGet: Subscription;
  busyGetProduit: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  montantTotalPeriode: any = 0;
  quantiteTotalPeriode: any = 0;
  beneficeVenteByPeriode : any = 0;
  linkPdfFile : String;
  bsModalRefPreview: BsModalRef;
  enumStatutReglement : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  // @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
  /*private daterangepickerOptions: DaterangepickerConfig*/) {

    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);

    let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);
    this.enumStatutReglement = enumStatutReglementStorePro;

    this.user = this.userService.getCurrentUser();

    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut: new Date(),// dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin: new Date(), // dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };

  }

  maxDate = new Date();
  @Input() isRefresh;
  
  selectedChangeValue(indiceTab) {
  }

 
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  getData() {
    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        isGetForStatistique: true,
        
        //action : enumActionApproStorePro.APPROVISIONNEMENT,
        dateSortie: null,
        dateSortieParam: {}
      },
      // index: 0,
      // size: 10,  //this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('reglement/getByCriteria', request)
      .subscribe(
        res => {
          console.log('get credit pour tout le monde',res);
          if (res && res['items']) {
            this.isChangeItermPerPage = false;
            this.results =  res['items']; //_.filter(res['items'], (o)=> { return o.resteAPayer > 0; }); //res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;

            //Recuperer ceux qui ont des quantité superieurs a zero
            let resultItemsNonAnnule = _.filter(this.results, function (o) { return o.statut == enumStatutReglementStorePro.Valide; });

            this.itemToSearch.montantTotalPeriode = _.sumBy(resultItemsNonAnnule, function (o) {return (o.montantTotalPayerSortie )});
            this.itemToSearch.montantTotalReglementPeriode = _.sumBy(resultItemsNonAnnule, function (o) {return (o.montantPayer )});
            this.itemToSearch.montantResteAPayer = parseFloat(this.itemToSearch.montantTotalPeriode) - parseFloat(this.itemToSearch.montantTotalReglementPeriode); // _.sumBy(resultItemsNonAnnule, function (o) {return (o.resteAPayer )});
            

            
            this.itemToSearch.montantTotalPeriode = this.userService.getFormatMillierInput(this.itemToSearch.montantTotalPeriode) || 0;
            this.itemToSearch.montantTotalReglementPeriode = this.userService.getFormatMillierInput(this.itemToSearch.montantTotalReglementPeriode) || 0;
            this.itemToSearch.montantResteAPayer = this.userService.getFormatMillierInput(this.itemToSearch.montantResteAPayer) || 0;
            
          } else { 
            this.results = [];
            this.itemToSearch.montantTotalPeriode = 0;
            this.itemToSearch.montantTotalReglementPeriode = 0;
            this.itemToSearch.montantResteAPayer = 0;
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //this.getData();

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        this.getData();
      } 
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['isRefresh'] && changes['isRefresh'].currentValue)
    {
      this.getData();
    }
  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
