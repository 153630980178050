import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
type AOA = any[][];
import * as XLSX from 'xlsx';
import { FormTrocSansSortieArticleComponent } from '../form-troc-sans-sortie-article/form-troc-sans-sortie-article.component';
import { FormTrocAvecSortieArticleComponent } from '../form-troc-avec-sortie-article/form-troc-avec-sortie-article.component';
import { enumStatutStorePro } from '../../../shared/utils/enumerator';

@Component({
  selector: 'app-main-produit',
  templateUrl: './main-produit.component.html',
  styleUrls: ['./main-produit.component.css']
})
export class MainProduitComponent implements OnInit {


  results: Array<any> = [];
  ListeTypeProduits: Array<any> = [];
  ListeFichierImporterToSave: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;

  totalItems = 0;
  currentPage = 1;
  user: any;
  itemStepWizard: number;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  imageDisplay: any = {};
  currentItemImage: any = {};
  itemStatistique: any = {};
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  stationSelected: any = {};
  conditionnementToSave: any = {};
  isNewItem: boolean;
  suscribStation: Subscription;
  oldValueSearch: String;
  isChangeItermPerPage: boolean = false;

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  bsModalRefTroc: BsModalRef;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    this.itemStepWizard = 1;
    this.oldValueSearch = "";
  }
  @Input() isRefresh;

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified, isDetails?: boolean) {
    this.cancelSave();
    this.getDataCategorieProduit();

    if (itemToModified) {

      this.itemToSave = Object.assign({}, itemToModified);
      // setTimeout(() => {
      //   this.itemToSave.typeProduitSelected =  _.find(this.ListeTypeProduits, { 'id': parseInt(itemToModified.categorieArticleId) });  
      // }, 2000);
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom' }));
  }


  openFormTrocSansSortie(user) {
    this.bsModalRefTroc = this.modalService.show(FormTrocSansSortieArticleComponent, Object.assign({}, this.config, { class: 'gray modal-lg' }));

    this.bsModalRefTroc.content.isSaveSuccesTrocSansSortie = false;
    let callBackModal = this.modalService.onHide.subscribe(r => {

      if (this.bsModalRefTroc.content.isSaveSuccesTrocSansSortie) {
        this.getData();
      }

    });
  }


  openFormTrocAvecSortie(user) {
    this.bsModalRefTroc = this.modalService.show(FormTrocAvecSortieArticleComponent, Object.assign({}, this.config, { class: 'gray modal-lg' }));

    this.bsModalRefTroc.content.isSaveSuccesTrocAvecSortie = false;
    let callBackModal = this.modalService.onHide.subscribe(r => {

      if (this.bsModalRefTroc.content.isSaveSuccesTrocAvecSortie) {
        this.getData();
      }

    });
  }


  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }



  getData() {

    let search_value = this.itemToSearch.designation;

    var request = {
      user: this.user.proxy,
      recuperationInformationForStatistique: true,
      data: {
        searchString: search_value ? search_value : null,
        statut: enumStatutStorePro.ACTIF,
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('article/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;

            this.itemStatistique.coutAchatGlobal = res['montantTotalCoutAchat'] ? res['montantTotalCoutAchat'] : 0;
            this.itemStatistique.coutVenteGlobal = res['montantTotalCoutVente'] ? res['montantTotalCoutVente'] : 0;
            this.itemStatistique.qteTotalDisponible = res['quantiteDisponibleEnStockParPeriode'] ? res['quantiteDisponibleEnStockParPeriode'] : 0;

          } else {
            this.results = [];

            this.itemStatistique.coutAchatGlobal = 0;
            this.itemStatistique.coutVenteGlobal = 0;
            this.itemStatistique.qteTotalDisponible = 0;

          }
        },
        err => {
        }
      );

  }


  getDataCategorieProduit() {

    var request = {
      user: this.user.proxy,
      "data": {
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busySave = this.api.post('categorieArticle/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeTypeProduits = res['items'];
            this.itemToSave.typeProduitSelected = _.find(this.ListeTypeProduits, { 'id': parseInt(this.itemToSave.categorieArticleId) });
          } else {
            this.ListeTypeProduits = [];
          }
        },
        err => {

        }
      );
  }


  confirmSaveItem(obj) {

    let item = Object.assign({}, obj);

    if (!item || !item.typeProduitSelected || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez préciser la designation et la catégorie de produit.")
      return;
    }

    if (!item || !item.code) {
      this.notificationService.showMessageError("Information", "Veuillez préciser lle numéro de l'article.")
      return;
    }

    if (!item || !item.prixAchat || !item.prixVente) {
      this.notificationService.showMessageError("Information", "Veuillez définir le prix d'achat et le prix de vente.")
      return;
    }

    if (parseFloat(item.prixAchat) > parseFloat(item.prixVente)) {
      this.notificationService.showMessageError("Information", "Le prix d'achat ne doit pas être supérieur au prix de vente.")
      return;
    }

    item.categorieArticleId = item.typeProduitSelected.id;
    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    item.statut = enumStatutStorePro.ACTIF;

    swal({
      title: (!obj.id) ? "Création d'un produit" : "Modification d'un produit",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  confirmDeleteItem(obj, isActive) {

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

        obj.statut = enumStatutStorePro.SUSPENDRE;
        this.saveItem(obj, true);

      }
    })

  }


  onFileChange(evt: any) {

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      var dataSheet1 = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log("read import data 1", dataSheet1);

      this.formatDataXlsx(dataSheet1);

    };

    reader.readAsBinaryString(target.files[0]);
  }

  formatDataXlsx(listeRowExcel) {
    console.log("ligne excle", listeRowExcel);

    if (listeRowExcel.length > 0) {
      for (var i = 1; i < listeRowExcel.length - 1; i++) {
        let currentRow = listeRowExcel[i];

        console.log("currentRow currentRow", currentRow)

        if (!currentRow[1]) {
          console.log("le i", i);
          console.log("le currentRow ", currentRow);
        }

        let objExport = {
          libelle: currentRow[1],
          code: currentRow[2],
          prixAchat: currentRow[5],
          prixVente: currentRow[6],
          quantiteDisponible: currentRow[4],
          quantiteSeuil: currentRow[7],
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          categorieArticleId: 3 //Pour les telephone portable
        }

        this.ListeFichierImporterToSave.push(objExport);
      }
    }
  }

  deleteImport(indice) {
    this.ListeFichierImporterToSave.splice(indice);
  }

  importItem() {
    if (!this.ListeFichierImporterToSave || this.ListeFichierImporterToSave.length == 0) {
      this.notificationService.showMessageInfo("Information", "Veuillez importer un fichier excel.")
      return;
    }

    var request = {
      user: this.user.id,
      datas: this.ListeFichierImporterToSave
    }

    this.busySave = this.api.post('article/create', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Importation", 'Opération effectuée avec succès.');
            this.currentPage = 1;
            this.modalRef.hide();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.notificationService.defaultMessage());
        }
      );
  }


  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item, isUpdateStatut?) {
    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('article/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Produit", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;

            if (!isUpdateStatut) {
              this.hideModal();
            }

            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj, isActive) {
    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id,

      }]
    }

    this.busySave = this.api.post('article/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }


  exportData(): void {

    let search_value = this.itemToSearch.designation;

    var request = {
      user: this.user.proxy,
      recuperationInformationForStatistique: true,
      data: {
        searchString: search_value ? search_value : null,
        statut: enumStatutStorePro.ACTIF,
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busyGet = this.api.post('article/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {

            let datasToExport = res['items'];

            console.log(" datasToExport ",datasToExport);
            

            /* generate worksheet */

            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[1, 2], [3, 4]]);

            /* generate workbook and add the worksheet */
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Articles');

            let resultObj: any = {
              "!ref": "A1:AW100000",
              A1: { t: 's', v: "Désignation" },
              B1: { t: 's', v: "Modèle" },
              C1: { v: "Prix d'achat", t: "s" },
              D1: { v: "Prix de vente", t: "s" },
              E1: { v: "Qté dispo", t: "s" },
              F1: { v: "Catégorie", t: "s" }
            };

            let sheet = {};
            for (var i = 3; i < datasToExport.length + 3; i++) {
                let currentoBJ = datasToExport[i - 3];
                resultObj[`A${i}`] = { t: 's', v: currentoBJ.libelle || "" };
                resultObj[`B${i}`] = { t: 's', v: currentoBJ.code || "" },
                resultObj[`C${i}`] = { t: 's', v: currentoBJ.prixAchat || "" };
                resultObj[`D${i}`] = { v: currentoBJ.prixVente || "", t: "s" },
                resultObj[`E${i}`] = { v: currentoBJ.quantiteDisponible || "", t: "s" },
                resultObj[`F${i}`] = { v: currentoBJ.categorieArticleLibelle || '', t: "s" }

                sheet = { ...sheet, ...resultObj }
            }

            XLSX.writeFile({
              SheetNames: ["Sheet1"],
              Sheets: {
                Sheet1: sheet
              }
            }, 'Liste_des_articles.xlsx', { cellStyles: true });

          } else {


          }
        },
        err => {
        }
      );


  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isRefresh'] && changes['isRefresh'].currentValue) {
      this.getData();
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1;
        this.getData();
      }
    });
  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
