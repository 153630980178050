import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
type AOA = any[][];
import * as XLSX from 'xlsx';
import { enumCategorieArticleId } from '../../../shared/utils/enumerator';


@Component({
  selector: 'app-form-troc-sans-sortie-article',
  templateUrl: './form-troc-sans-sortie-article.component.html',
  styleUrls: ['./form-troc-sans-sortie-article.component.css']
})
export class FormTrocSansSortieArticleComponent implements OnInit {

  results: Array<any> = [];
  ListeTypeProduits: Array<any> = [];
  ListeFichierImporterToSave: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemStepWizard: number;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  imageDisplay: any = {};
  currentItemImage: any = {};
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  stationSelected: any = {};
  conditionnementToSave: any = {};
  isNewItem: boolean;
  suscribStation: Subscription;
  oldValueSearch: String;
  isChangeItermPerPage: boolean = false;
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  currentClient: any = {};
  itemProduct: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, public bsModalRefTroc: BsModalRef, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    this.itemStepWizard = 1;
    this.oldValueSearch = "";
  }

  @Input() isRefresh;

  hideModal() {
    this.bsModalRefTroc.hide();
    this.bsModalRefTroc.content.isSaveSuccesTrocSansSortie = true;
  }


  selectProduct(item) {
    console.log('le item article sans troc',item);
    if (item) {
      this.itemProduct = Object.assign({}, item);
      this.itemToSave.prixAchat = this.itemProduct.prixAchat;
      this.itemToSave.prixVente = this.itemProduct.prixVente;
      this.itemProduct.libelle = item.libelleSearch;
      this.itemProduct.code = item.numeroSearch;
    }
    else
    {
      this.itemProduct.libelle = item.libelleSearch;
      this.itemProduct.code = item.numeroSearch;
    }
  };

  confirmSaveItem(obj) {

    let item = Object.assign({}, obj);

    if (!this.itemProduct || !this.itemProduct.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez sélectionner un article.")
      return;
    }

    // if (!item || !item.code) {
    //   this.notificationService.showMessageError("Information", "Veuillez préciser lle numéro de l'article.")
    //   return;
    // }

    if (!item.codeImeiArticleTroc) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le code IMEI ou l'identifiant du téléphone.")
      return;
    }

    // if(!this.currentClient)
    // {
    //   this.notificationService.showMessageError("Erreur","Veuillez sélectionner un client !");
    //   return;
    // }


    if (!item || !item.prixAchat || !item.prixVente) {
      this.notificationService.showMessageError("Information", "Veuillez définir le prix d'achat et le prix de vente.")
      return;
    }

    if (parseFloat(item.prixAchat) > parseFloat(item.prixVente)) {
      this.notificationService.showMessageError("Information", "Le prix d'achat ne doit pas être supérieur au prix de vente.")
      return;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    item.libelle = this.itemProduct.libelle + ' - ' + item.codeImeiArticleTroc;
    item.code = this.itemProduct.code;
    item.id = 0;

    if(!this.itemProduct.categorieArticleId)
    {
      //SETTER CATEGORIE ARTICLE A TELEPHONE
      item.categorieArticleId = enumCategorieArticleId.telephoneId;
    }
    else
    {
      item.categorieArticleId = this.itemProduct.categorieArticleId;
    }
    
    item.depenseDto = {
      montantDepense: item.montantDepense
    }

    // console.log(" this.itemToSave.clientSelected ",this.itemToSave.clientSelected);
    if (!this.currentClient || !this.currentClient.id) {
      if (this.itemToSearch.clientContactSearch && this.itemToSearch.clientSearch) {
        item.clientDto = {
          contact: this.itemToSearch.clientContactSearch,
          libelle: this.itemToSearch.clientSearch.toUpperCase(),
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          searchString: this.itemToSearch.clientSearch + ' ' + this.itemToSearch.clientContactSearch
        };
      }
    }
    else {
      item.idClientOperationTroc = this.currentClient.id;
    }

    swal({
      title: "Troc article",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }


  selectedClient(client) {

    if (client) {
      this.currentClient = client;
      this.itemToSearch.clientSearch = client.libelle;
      this.itemToSearch.clientContactSearch = client.contact;
      this.itemToSearch.isDisplayTable = false;
      // console.log("le client ",client);
    }
  }

  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item) {

    // item.categorieArticleId = item.typeProduitSelected.id; 
    item.boutiqueId = this.userService.getCurrentBoutiqueUser();

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('article/createTrocSansArticle', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {

            this.notificationService.showMessageSucces("Troc", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();

          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('data', changes);
    // if(changes['isRefresh'] && changes['isRefresh'].currentValue)
    // {
    //   this.getData();
    // }

  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1;
        //this.getData();

      }
    });

  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
