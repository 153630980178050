import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');


@Component({
  selector: 'app-main-bilan-ventes-par-client',
  templateUrl: './main-bilan-ventes-par-client.component.html',
  styleUrls: ['./main-bilan-ventes-par-client.component.css']
})
export class MainBilanVentesParClientComponent implements OnInit {

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  results: Array<any> = [];
  ListeProduits: Array<any> = [];
  ListeApproToSave: Array<any> = [];
  ListeClients: Array<any> = [];
  busyGet: Subscription;
  busyGetProduit: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  montantTotalPeriode: any = 0;
  quantiteTotalPeriode: any = 0;
  beneficeVenteByPeriode : any = 0;
  linkPdfFile : String;
  bsModalRefPreview: BsModalRef;
  enumStatutReglement : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  // @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
  /*private daterangepickerOptions: DaterangepickerConfig*/) {

    this.cancelSave();
    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);

    let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);

    this.enumStatutReglement = enumStatutReglementStorePro;
    this.user = this.userService.getCurrentUser();

    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut: new Date(),// dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin: new Date(), // dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };
    this.oldValueSearch = "";

    const tableauMois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    let libelleMois = "Ce mois de " + tableauMois[new Date().getMonth()];

  }

  maxDate = new Date();


  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }

  selectedChangeValue(indiceTab) {
  }

  openFormModalModal(template, itemToModified, isDetails) {
    this.cancelSave();
    //this.getDataProduits();

    if (itemToModified) {

      this.itemApproToSave = {};
      this.itemApproToSave = Object.assign({}, itemToModified);

    }
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-appro' }));
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getData(this.itemToSave.produitSelected ? this.itemToSave.produitSelected.id : null, this.itemToSave.clientSelected ? this.itemToSave.clientSelected.id : null);
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData(this.itemToSave.produitSelected ? this.itemToSave.produitSelected.id : null, this.itemToSave.clientSelected ? this.itemToSave.clientSelected.id : null);
  }

  getData(idArticle?, idClient?) {

    var dateDebut = moment(this.itemToSearch.dateDebut, "YYYYMMDD");
    var dateFin = moment(this.itemToSearch.dateFin, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") < 0) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la période sélectionnée.");
      this.results = [];
      this.montantTotalPeriode = 0;
      this.quantiteTotalPeriode = 0;
      this.beneficeVenteByPeriode = 0;
      return;
    }

    if(this.itemToSave.clientSelected && this.itemToSave.clientSelected.libelle)
    {
      idClient = this.itemToSave.clientSelected.id;
    }
    else
    {
      idClient = null;
    }
    // if (!this.itemToSave.clientSelected || !this.itemToSave.clientSelected.id) {
    //   this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un client.");
    //   return;
    // }

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");


    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        articleId: idArticle ? idArticle : null,
        clientId: idClient || null,
        isGetForStatistique: true,
        libelle : this.itemToSave.produitSelected.libelleSearch || null,
        code : this.itemToSave.produitSelected.numeroSearch || null,
        //action : enumActionApproStorePro.APPROVISIONNEMENT,
        dateSortie: null,
        dateSortieParam: {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateSortie = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateSortieParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post('viewLigneSortie/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.isChangeItermPerPage = false;
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.montantTotalPeriode = res['montantTotalPeriode'] ? this.userService.getFormatMillierInput(res['montantTotalPeriode']) : 0;
            this.quantiteTotalPeriode = res['quantiteTotalPeriode'] ? this.userService.getFormatMillierInput(res['quantiteTotalPeriode'],true) : 0;
          
            let resultItemsNonAnnule = _.filter(this.results, function(o) { return o.statut == enumStatutReglementStorePro.Valide; });

            this.beneficeVenteByPeriode = _.sumBy(resultItemsNonAnnule, function (o) { return (o.quantiteSortie * (o.prixTotalAvecRemise - o.prixAchat) ) });

            this.beneficeVenteByPeriode = this.userService.getFormatMillierInput(res['montantBeneficiePeriode']);
        
          } else {
            this.results = [];
            this.montantTotalPeriode = 0;
            this.quantiteTotalPeriode = 0;
            this.beneficeVenteByPeriode = 0;
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }



  printBilan() {

    var dateDebut = moment(this.itemToSearch.dateDebut, "YYYYMMDD");
    var dateFin = moment(this.itemToSearch.dateFin, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") < 0) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la période sélectionnée.");
      this.results = [];
      this.montantTotalPeriode = 0;
      this.quantiteTotalPeriode = 0;
      this.beneficeVenteByPeriode = 0;
      return;
    }

    // if (!this.itemToSave.clientSelected || !this.itemToSave.clientSelected.id) {
    //   this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un client.");
    //   return;
    // }

    if(this.results && this.results.length == 0)
    {
      this.notificationService.showMessageError("Erreur", "Aucune ligne à exporter.");
      return;
    }

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      symbolDevise : this.userService.getCurrentDevise(),
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        articleId: this.itemToSave.produitSelected ? this.itemToSave.produitSelected.id : null,
        clientId: this.itemToSave.clientSelected ? this.itemToSave.clientSelected.id : null,
        isGetForStatistique: true, 
        //action : enumActionApproStorePro.APPROVISIONNEMENT,
        dateSortie: null,
        dateSortieParam: {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateSortie = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateSortieParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post('ligneSortie/getReportBilanVente', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  selectProduct($event)
  {
    this.itemToSave.produitSelected = $event;
    this.selectClientOrProduct(this.itemToSave.produitSelected);
  }

  selectClient($event)
  {
    this.itemToSave.clientSelected = $event;
    this.selectClientOrProduct(this.itemToSave.clientSelected);
  } 

  selectClientOrProduct = function (item) {
    console.log(item);

    // if (!this.itemToSave.clientSelected || !this.itemToSave.clientSelected.id) {
    //   // this.notificationService.showMessageError("Veuillez sélectionner un client.");
    //   this.resultat = [];
    //   this.montantTotalPeriode = 0;
    //   this.quantiteTotalPeriode = 0;
    //   this.beneficeVenteByPeriode = 0;

    //   return;
    // }
    this.currentPage = 1;
    this.totalItems = 0;
    this.getData(this.itemToSave.produitSelected ? this.itemToSave.produitSelected.id : null, this.itemToSave.clientSelected ? this.itemToSave.clientSelected.id : null);
  };




  getDataProduits() {

    var request = {
      user: this.user.proxy,
      "data": {
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busyGetProduit = this.api.post('article/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat', res);
          if (res && res['items']) {
            this.ListeProduits = _.orderBy(res['items'], ['libelle'], ['asc']);
            // this.itemToSave.produitSelected = this.ListeProduits[0];
            // this.getData(this.itemToSave.produitSelected ?this.itemToSave.produitSelected.id : null, this.itemToSave.clientSelected ?this.itemToSave.clientSelected.id : null);

          } else {
            this.ListeProduits = [];
          }
        },
        err => {

        }
      );

  }


  getDataClients() {

    var request = {
      user: this.user.proxy,
      "data": {
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busyGetProduit = this.api.post('client/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat', res);
          if (res && res['items']) {
            this.ListeClients = _.orderBy(res['items'], ['libelle'], ['asc']);
            // this.itemToSave.clientSelected = this.ListeClients[0];
            // this.getData(this.itemToSave.produitSelected ? this.itemToSave.produitSelected.id : null, this.itemToSave.clientSelected ? this.itemToSave.clientSelected.id : null);

          } else {
            this.ListeClients = [];
            
          }
        },
        err => {

        }
      );

  }


  cancelSave() {
    this.itemToSave = {};
    this.itemApproToSave = {};
    this.ListeApproToSave = [];
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
   

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        // this.getDataProduits();
        // this.getDataClients();
      } 
    });

  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }



}
