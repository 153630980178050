import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');


@Component({
  selector: 'app-main-imei-fournisseur',
  templateUrl: './main-imei-fournisseur.component.html',
  styleUrls: ['./main-imei-fournisseur.component.css']
})
export class MainImeiFournisseurComponent implements OnInit {

  results: Array<any> = [];
  ListeProduits:  Array<any> = [];
  ListeApproToSave : Array<any> = [];
  ListeFournisseurs : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave : any = {};
  daterange: any = {};
  oldValueSearch : any;
  isChangeItermPerPage : boolean = false; 
  resetitemInfo : number;

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
    private daterangepickerOptions: DaterangepickerConfig) { 
    
    this.cancelSave(); 
    let date = new Date();
    let moisActuel = date.getMonth()+1;
    let anneeActuel = date.getFullYear();

    let jrfinMoisCourant = new Date(date.getFullYear(),date.getMonth()+1,0).getDate();
    let dateDebutMonth = ("01-"+ moisActuel+"-"+anneeActuel);
    let dateFinMonth = (jrfinMoisCourant+"-"+moisActuel+"-"+anneeActuel);

    let dateDebutIso = new Date(anneeActuel,moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel,moisActuel - 1,jrfinMoisCourant);


    this.user = this.userService.getCurrentUser();
    
    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut : dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin : dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };
    this.oldValueSearch = "";

    const tableauMois =new Array("Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"); 
    let libelleMois = "Ce mois de "+tableauMois[new Date().getMonth()];

    this.daterangepickerOptions.settings = {
      locale: {
            format: 'DD-MM-YYYY',
            cancelLabel: 'Annuler',
            applyLabel: 'Rechercher',

            "customRangeLabel": "Personnaliser",
            "daysOfWeek": [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ],
            "monthNames": [
                "Janvier",
                "Fevrier",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Aout",
                "Septembre",
                "Octobre",
                "Novembre",
                "Decembre"
            ],
        },
        alwaysShowCalendars: false,
        ranges: {
           
            "Aujourd'hui":  moment(),
            "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
            'Les 03 derniers jours': [moment().subtract(2, 'day'),  moment()],
            'Les 07 derniers jours': [moment().subtract(6, 'day'),  moment()],
            'Les 15 derniers jours': [moment().subtract(14, 'day'),  moment()],
            'Les 30 derniers jours': [moment().subtract(29, 'day'),  moment()],
            'Les 60 derniers jours': [moment().subtract(59, 'day'),  moment()],
            'Les 90 derniers jours': [moment().subtract(89, 'day'),  moment()],
            [libelleMois] : [this.itemToSearch.dateDebut , this.itemToSearch.dateFin], 
        }
     };
  }

  maxDate = new Date();

  resetDate() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  selectedDate(value: any, datepicker?: any,firstRefresh?: boolean) { 
    datepicker.start = value.start;
    datepicker.end = value.end;
    if(!firstRefresh)
    {
      this.itemToSearch.dateDebut =  moment(value.start).format('DD/MM/YYYY');
      this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');
      this.daterange.label = value.label; 
    }

    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    
    this.getData();
  }

  ngAfterViewInit()
  {
    this.resetDate(); 

    setTimeout(() => {
      this.selectedDate(this.picker,this.daterange,true)
    }, 1000);
  }

  
  getDataFournisseur() {

    var request = {
      user: this.user.proxy,
      data: {
      }
    }

    this.busyGet = this.api.post('fournisseur/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.ListeFournisseurs = res['items'];
          } else {
            this.ListeFournisseurs = [];
          }
        },
        err => {
        }
      );

  }


  deleteApproInListe(indice)
  {

    swal({
      title: 'Suppression approvisionnement',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.ListeApproToSave.splice(indice,1);
      } else {
       
      }
    })
  }



  confirmSupprimer(obj) {

    let item =  Object.assign({}, obj);

    if (!item) {
      this.notificationService.showMessageError("Information", "Veuillez sélectionner la ligne à supprimer.")
      return;
    }

    if (item.idClientAyantAchete) {
      this.notificationService.showMessageError("Information", "Impossible d'éffectuer cette action car cet article est déjà vendu.")
      return;
    }


    swal({
      title:"Supprimer IMEI Fournisseur",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(item);
      } else {
      }
    })
  }

  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id,
       
      }]
    }

    this.busyGet = this.api.post('imeiFournisseur/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }



  hideModal() {

    if(this.ListeApproToSave && this.ListeApproToSave.length > 0)
    {
      swal({
        title: 'Fermeture de fenêtre',
        text: 'Des saisies sont en cours, voulez-vous fermer la fenêtre ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
        if (result) {
          this.modalRef.hide();
        } 
      })
    }
    else
    {
      this.modalRef.hide();
    }

  }

  openFormModalModal(template, itemToModified,isDetails) {
    this.cancelSave();
    // this.getDataProduits();
    
    if (itemToModified) {

      this.itemApproToSave = {}; 

      this.itemApproToSave = Object.assign({}, itemToModified);
     
    }

    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg' }));

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = ""; 
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  getData() {
   
    let search_value = this.itemToSearch.codeImei;
    let isSearch = this.itemToSearch.codeImei ? true : false;

    if(search_value && this.oldValueSearch)
    {
      if(search_value.trim() != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }
   
    if(search_value){
       search_value = search_value.trim();

       
       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
         //this.currentPage = 1;
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }

      this.oldValueSearch = ""; 
    } 

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        codeImei : search_value ? search_value : null,
        createdAt : null,
        createdAtParam : {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    // if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
    //   request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
    //   request.data.createdAtParam =
    //       {
    //           "operator": "[]",
    //           "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
    //           "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
    //       }
    // }

    this.busyGet = this.api.post('imeiFournisseur/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.isChangeItermPerPage = false;
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );

  }

selectProduct = function(item) {
    console.log(item);
    if (!item) return;

    this.itemToSave.articleSelected = Object.assign({}, item);
};


  // getDataProduits() {

  //   var request = {
  //     user: this.user.proxy,
  //     "data": { 
  //       boutiqueId : this.userService.getCurrentBoutiqueUser() 
  //     }
  //   }
  
  //   this.busySave = this.api.post('article/getByCriteria', request)
  //     .subscribe(
  //       res => {
  //         console.log('voici le resultat',res); 
  //         if (res && res['items']) {
  //           this.ListeProduits = _.orderBy(res['items'], ['libelle'], ['asc']);
  //         } else {
  //           this.ListeProduits = [];
  //         }
  //       },
  //       err => {
         
  //       }
  //     );

  // }

  confirmSaveItem() {
    //this.lgModal.show();
    if (!this.ListeApproToSave || this.ListeApproToSave.length == 0) {
      this.notificationService.showMessageInfo("Information", "Veuillez vérifier la liste des IMEI.")
      return;
    }

    swal({
      title: 'Enregistrement IMEI Fournisseur',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem();
      } 
    })
  }


  cancelSave() {

    this.itemToSave = {
      qte : 1,
      dateAppro : new Date()
    };

    this.itemApproToSave = {
      dateAppro : new Date(),
      qte : 1
    };

    this.ListeApproToSave = [];

  }

  saveItem() {
  
    var request = {
      user : this.user.proxy,
      datas: this.ListeApproToSave
    };
  

    this.busySave = this.api.post('imeiFournisseur/create', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] ) {
            this.notificationService.showMessageSucces("IMEI Fournisseur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.modalRef.hide();
            this.cancelSave(); 
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  addProductAppro(appro)
  {
    let approToSave = Object.assign({}, this.itemToSave);
    
    if(!approToSave.articleSelected)
    {
      this.notificationService.showMessageError("Erreur","Veuillez sélectionner un article.");
      return;
    }

    if(!approToSave.codeImei)
    {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le code IMEI.");
      return;
    }

    if(!approToSave.dateAppro || approToSave.dateAppro == 'Invalid')
    {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner la date d'approvisionnement.");
      return;
    }

    //Verifier que l'article n'a jamais eté ajouté a la liste via le code imei
    let isExiste = _.find(this.ListeApproToSave, function(o) { return o.codeImei ==  approToSave.codeImei});
    if(isExiste)
    {
      this.notificationService.showMessageError("Erreur","Cet article est déjà dans la liste.");
      return;
    }

    //Formater la date d'approvisionnement
    let dateFormatOperation = moment(approToSave.dateAppro).format("DD/MM/YYYY");
    if(dateFormatOperation =="Invalid date")
    {
      dateFormatOperation = approToSave.dateAppro + " 00:00:00";
    }
    else
    {
      dateFormatOperation = dateFormatOperation + " 00:00:00";
    }

    this.ListeApproToSave.push(
      {
        codeImei : approToSave.codeImei,
        idArticle : approToSave.articleSelected.id,
        idFournisseur : approToSave.fournisseurSelected.id,
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        observations : approToSave.observations,
        libelleArticle : approToSave.articleSelected.libelle,
        libelleFournisseur : approToSave.fournisseurSelected.libelle,
        dateAppro : dateFormatOperation,
        prixAchat : approToSave.articleSelected.prixAchat,
        prixVente : approToSave.articleSelected.prixVente
      }
    )

    // this.itemApproToSave = {};
    // this.itemToSave. = null;
    console.log("this.ListeApproToSave",this.ListeApproToSave);

    // this.itemToSave.fournisseurSelected = null;
    this.itemToSave.codeImei = null;
    this.itemToSave.observations = null;

    // this.resetitemInfo = new Date().getTime();
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        this.getData();
      } 
    });

    this.getDataFournisseur();
  }



}
