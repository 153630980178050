import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-categorie-produit',
  templateUrl: './main-categorie-produit.component.html',
  styleUrls: ['./main-categorie-produit.component.css']
})
export class MainCategorieProduitComponent implements OnInit {


  results: Array<any> = [];
  ListeDepartements: Array<any> = [];
  ListePays :  Array<any> = [];
  ListeDevises :  Array<any> = [];
  ListeCartes: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  stationSelected : any = {};
  suscribStation : Subscription;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    // this.statutCotisation = enumStatutCotisation;
  }


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);
    }
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-md' }));
   // this.modalRef = this.modalService.show(template);
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData() {
    var request = {
      user: this.user.proxy,
      data: {
       boutiqueId : this.userService.getCurrentBoutiqueUser()
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('categorieArticle/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );

  }

  confirmSaveItem(obj) {

    let item = obj;
    if (!item || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé.")
      return;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser()

    swal({
      title: (!obj.id) ? "Création d'une catégorie" : "Modification d'une catégorie",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    })

  }


  confirmDeleteItem(obj,isActive) {
    //this.lgModal.show();

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj,isActive);
      }
    })

  }

  checkCurrentItem(item) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemChecked = item;
    }
  }

  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('categorieArticle/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Catégorie de produit", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave(); 
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj,isActive) {

    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id
    }]
    }

    this.busyGet = this.api.post('categories/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }


  ngOnInit() {

    window.scrollTo(0, 0);
    this.getData();
    this.suscribStation = this.userService.currentData.subscribe(currentData => {
      this.stationSelected = currentData;
       console.log('filiale',this.stationSelected); 
       if(this.stationSelected && this.stationSelected.id)
       {
         this.getData();
       }
      
   });
  }

  ngOnDestroy(){
    if(this.suscribStation) this.suscribStation.unsubscribe();
  }

}
