import { Component, OnInit } from '@angular/core';
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";
import {config} from "../../smartadmin.config";
import {LayoutService} from "../layout.service";
import {UserService} from "../../user/user.service";
import {ActivatedRoute, Route, Router} from '@angular/router';
import { Api } from '../../utils/api/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FormReconnectComponent } from '../../../+components/app-boutique/form-reconnect/form-reconnect.component';

@FadeZoomInTop()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: []
})
export class MainLayoutComponent implements OnInit {

  private config:any = {};
  user : any;
  waitRefresh : boolean;
  bsModalRef : BsModalRef;
  intervalle = 1000;
  countTer = 0;
  activite_detectee : boolean; 
  logoutModalIsLunch : boolean = false;
  lunchFonction : any;
  deconnectionTimer : number = 5; 

  constructor(public layoutService:LayoutService,private activedRoute: ActivatedRoute,private userService: UserService,public router:Router,private api:Api,private modalService: BsModalService) {
    this.config = config;
    this.user = this.userService.getCurrentUser();
    this.deconnectionTimer =(this.user.deconnectionTimer ? this.user.deconnectionTimer : this.deconnectionTimer) * 60000;
    this.initTimoutActivityScreem(); 
   
  } 

  initTimoutActivityScreem()
  {
    this.lunchFonction = setTimeout(() => {
      //this.getActiviteInScream()
    }, 1000);
  }
 


  // getActiviteInScream(counter : number)
  // {

  //    this.waitRefresh = false;
  //    counter = counter + 1;

  //    if(this.waitRefresh)
  //    {

  //    }

  //    console.log("counter layout",counter);

  //   //  setTimeout(() => {
  //   //   this.waitRefresh = true;
  //   //   this.getActiviteInScream(counter);
  //   //  }, 2000);

  //    //this.getActiviteInScream(counter);
  // }


  openPasswordForm() {
    this.logoutModalIsLunch = true;
    this.bsModalRef = this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray modal-sm  modal-login-reconnect' }));
    this.bsModalRef.content.currentUser = this.user;
    let refreshTimout = this.modalService.onHide.subscribe(r=>{
      this.countTer = 0;
      this.logoutModalIsLunch = false;
      window.clearTimeout(this.lunchFonction);
      this.initTimoutActivityScreem();
    });
  }

    // On crée la fonction qui teste toutes les x secondes l'activité du visiteur via activite_detectee
    getActiviteInScream() {
     
      if(this.activite_detectee)
      {
            this.activite_detectee = false; 
            this.countTer = 0;
      }
      else
      {
            this.countTer = this.countTer + 1000;
            let href = this.router.url;
            if(this.countTer == this.deconnectionTimer)
            {
                if(this.logoutModalIsLunch)
                {
                  this.countTer = 0;
                  window.clearTimeout(this.lunchFonction);
                  this.logoutModalIsLunch = false;
                  return;
                }
               
               // Deconnecter l'utilisateur
                this.openPasswordForm();
                this.deconnection();
               
                //document.getElementById('click-btn').click();
                window.clearTimeout(this.lunchFonction);
                this.countTer = 0;
                return;
            }
            else
            {
              if(this.logoutModalIsLunch)
              {
                this.countTer = 0;
                window.clearTimeout(this.lunchFonction);
                this.logoutModalIsLunch = false;
                return;
              }
            }
      }
      
      this.lunchFonction = setTimeout(() => {
         this.getActiviteInScream()
      }, 1000);
 
  }


  deconnection()
  {
    window.clearTimeout(this.lunchFonction);

    var request = {
      //user : "1",
      "data":
        {
          "proxy": this.user.proxy,
          "action" : "Déconnexion",
          "source":"Web",
          "referenceSource":  navigator.userAgent
        } 
    }

    this.api.post('user/logout', request)
          .subscribe(
              res => {
                  if(!res['hasError'])
                  {
                    localStorage.removeItem("yz-backoffice-app-boutique-shell");
                  }
                  else
                  {
                    localStorage.removeItem("yz-backoffice-app-boutique-shell");
                  }

                  window.clearTimeout(this.lunchFonction);
              },
              err => {
                localStorage.removeItem("yz-backoffice-app-boutique-shell");
                window.clearTimeout(this.lunchFonction);
              }
          );

    }


  // moveCursor()
  // {
  //   this.getActiviteInScream(0);
  // }

  ngOnInit() {
    this.layoutService.onSmartSkin(this.config.skins[2]);
  }

  ngOnDestroy(){
    window.clearTimeout(this.lunchFonction);
  }

}
