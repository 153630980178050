import { Component, OnInit, Output,EventEmitter, Input, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import {Api} from '../../../shared/utils/api/api';

import {NotificationService} from '../../../shared/utils/notification.service';
import {UtilitiesService} from '../../../shared/utils/utilities.service';
import {ModelService} from '../../../shared/utils/model.service';
import {UserService} from "../../../shared/user/user.service";
import * as _ from "lodash";
import { enumStatutStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');

@Component({
  selector: 'app-search-input-produit',
  templateUrl: './search-input-produit.component.html',
  styleUrls: ['./search-input-produit.component.css']
})
export class SearchInputProduitComponent implements OnInit {

  
  user: any;
  itemToSave: any = {};
  ListeItems : Array<any> = [];
  busySave : Subscription;
  busyGetlibelleSearch : Subscription;
  currentitem : any = {};
  typePatientEnum : any = {};
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  currentPage = 1;
  itemToSearch : any = {}
  
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private userService: UserService, private api: Api, private notificationService: NotificationService, private utilities: UtilitiesService) {
    this.itemToSave = {
        coutPartAssurance : null,
        coutTotalActes : null
    };
    this.user = this.userService.getCurrentUser();
    setTimeout(() => {
      this.itemToSave = {};
    }, 1000);
  }

  @Output() selectedItemProduit: EventEmitter<any> = new EventEmitter();
  @Input() resetitemInfo;
  @Input() articleByImei;
  @Input() isInputBilan;


  getData(nameSearch)
  {
        var request = {
            "user":this.user.id,
            // textToSearch : nameSearch,
            // "searchTage" : "FULL_TEXT_SEARCH",
            "data":{
              searchString : this.itemToSearch.libelleSearch,
              boutiqueId : this.userService.getCurrentBoutiqueUser(),
              statut : enumStatutStorePro.ACTIF,
            },
            index : 0,
            size : 10
        }
        this.itemToSearch.isDisplayTable = true;

        this.busyGetlibelleSearch = this.api.post('article/getByCriteria', request)
            .subscribe(
                res => {
                    console.log('return item',res); 
                    if(res && res['items'])
                    {
                        this.ListeItems = res['items'];
                    }
                    else
                    {
                      this.ListeItems = [];
                    }
                },
                err => { 
                }
            );
  }

  selectedItem(item)
  {
    this.itemToSearch.isDisplayTable = false;
    console.log("le item search",item);
    if(item && item.id)
    {
      
      this.currentitem = item;
      this.itemToSearch.libelleSearch = item.libelle;
      this.itemToSearch.numeroSearch = item.code;
      this.itemToSearch.isDisplayTable = false;
       
      this.selectedItemProduit.emit(item); 
    }
    else
    {
      this.currentitem = {};
      // this.itemToSearch.libelleSearch = this.itemToSearch.libelleSearch;
      // this.itemToSearch.numeroSearch = null;

      if(this.isInputBilan)
      {
        this.selectedItemProduit.emit({
          libelleSearch : this.itemToSearch.libelleSearch,
          numeroSearch : this.itemToSearch.numeroSearch || null
        });
      }
      else
      {
        this.itemToSearch.libelleSearch = null;
        this.itemToSearch.numeroSearch = null;
      }
    }
  
  }

  
  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('data', changes);
    if(changes['resetitemInfo'] && changes['resetitemInfo'].currentValue)
    {
      this.selectedItem({});
    }

    if(changes['articleByImei'] && changes['articleByImei'].currentValue)
    {
      this.selectedItem(changes['articleByImei'].currentValue);
    }
  }


}
