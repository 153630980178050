export enum EtatMissionId {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum StatutCandidature {
    PRESELECTIONNER = 1,
    ENTRETIEN_PASSE=2,
    SELECTIONNE=3,
    CONTRAT_SIGNE=4,
    ELIMINE = 5
}

export enum EtatTemoignage {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum EtatVente {
    Paye = 2
}

export enum EnumCodeParametreBase {
    HeureNormaleTravail = "HrNor",
    CoefficientPrixPanier="CoPrP",
    ReferenceNumber="NumRefSaisi",
}


export enum enumEtatId {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum enumEtatCode {
    BROUILLON = "BROUILLON",
    ENTRANT="ENTRANT",
    VALIDE="VALIDE",
    REJETE="REJETE"
}

export enum enumEtatUserFo {
    Actif = "Actif",
    Suspendu="Suspendu",
    Fermer="Fermer",
    REJETE="REJETE"
}

export enum enumEtatAnnonce {
    Annule = "Annulé",
}


export enum enumStatutStorePro {
    BROUILLON = "BROUILLON",
    ENTRANT="ENTRANT",
    VALIDE="VALIDE",
    ACTIF = "ACTIF",
    REJETE="REJETE",
    ANNULE="ANNULE",
    SUSPENDRE="SUSPENDU",
    SOLDE= "SOLDE",
    EN_COURS= "EN_COURS",
    RECU_AU_MAGASIN ="RECU ET AU MAGASIN",
    EN_ATTENTE_DE_LIVRAISON = "EN ATTENTE DE LIVRAISON",
    LIVRE = "LIVRER",
    REPARATION_ANNULE = "REPARATION ANNULEE",
    LIVRER_TOTALEMENT = "LIVRE TOTALEMENT",
    TERMINE = "TERMINE"
}


export enum enumActionApproStorePro {
    APPROVISIONNEMENT = "APPROVISIONNEMENT",
    REBUT="REBUT",
    RETRAIT_ARTICLE = "RETRAIT ARTICLE"
}

export enum enumTypeSortieStorePro { 
    VENTE = "VENTE"
}

export enum enumCodeMenu {
    PUBLICATION = "PUBLICATION",
    ACCUEILPROPOS ="ACC-APROPOS",
    PRESENTATION_PNV ="PRST-PNV",
    PRESENTATION_COMPRENDRE ="PRST-CMPRD",
    PRESENTATION_ORGANISATION ="PRST-ORG",
    PRESENTATION_MISSION ="PRST-MISSION-STATIC"
}

export enum enumCategorieArticleId {
    telephoneId = 6
}

export enum enumStatutId {
    Brouillon = 1,
    EnLigne = 2,
    Termine = 3,
    Archiver = 4,
    Reactiver = 1000  //Utiliser pr des controles mais en realité cela equivaut au statut brouillon (cad avant l'enregistrement)
}

export enum enumParametreGeneraux {
    Bulletin_Alerte = "DELAI_BULLETIN_ALERTE",
    Bulletin_Innondation = "DELAI_BULLETIN_INONDATION"
}

export enum enumLibelleStatutDesktop {
    TERMINER = "TERMINER",
    EN_COURS="EN COURS"
}

export enum enumStatutReglementStorePro {
    Annule = "ANNULE",
    Valide = "VALIDE"
}

export enum enumProfilCode {
    ADMINISTRATEUR = "ADMIN"
}

export enum enumActionLibelle {
    APPROVISIONNEMENT = "APPROVISIONNEMENT"
}

export enum enumTypeTechnicien {
    TECHNICIEN = "TECHNICIEN",
    REPARATEUR = "REPARATEUR"

}