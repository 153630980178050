import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumStatutStorePro, enumTypeSortieStorePro } from '../../../shared/utils/enumerator';
// import { POINT_CONVERSION_COMPRESSED } from 'constants';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
import { FormTrocAvecSortieArticleComponent } from '../form-troc-avec-sortie-article/form-troc-avec-sortie-article.component';
import { FormTrocSansSortieArticleComponent } from '../form-troc-sans-sortie-article/form-troc-sans-sortie-article.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-comptoire-vente',
  templateUrl: './main-comptoire-vente.component.html',
  styleUrls: ['./main-comptoire-vente.component.css']
})
export class MainComptoireVenteComponent implements OnInit {

  
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  results: Array<any> = [];
  ListeProduits:  Array<any> = [];
  ListeVenteToSave : Array<any> = [];
  ListeClients :  Array<any> = [];
  ListCouleurs :   Array<any> = [];
  ListeLigneCommandes :  Array<any> = [];
  ListeCommandes :  Array<any> = [];
  counterRefreshHistorique : number = 1;
  busyGet: Subscription;
  resetitemInfo : number;
  busySave: Subscription;
  itemToSave: any = {};
  currentClient : any = {};
  articleSelectedByImei : any = {};
  currentCommande : any = {};
  lastCodeImeiSearch : string;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any = {};
  modalRef: BsModalRef;
  itemApproToSave : any = {};
  daterange: any = {};
  oldValueSearch : any;
  isChangeItermPerPage : boolean = false; 
  isUpdateVente :  boolean = false; 
  resetitemInfoAfterVente : number;
  linkPdfFile : String;
  bsModalRefPreview: BsModalRef;
  codeImeiSearch : string;
  bsModalRefTroc : BsModalRef;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
    private daterangepickerOptions: DaterangepickerConfig) { 
    
    this.cancelSave(); 
    let date = new Date();
    let moisActuel = date.getMonth()+1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(),date.getMonth()+1,0).getDate();
    let dateDebutMonth = ("01-"+ moisActuel+"-"+anneeActuel);
    let dateFinMonth = (jrfinMoisCourant+"-"+moisActuel+"-"+anneeActuel);

    let dateDebutIso = new Date(anneeActuel,moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel,moisActuel - 1,jrfinMoisCourant);


    this.user = this.userService.getCurrentUser();
    
    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut : dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin : dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };
    this.oldValueSearch = "";

    const tableauMois =new Array("Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"); 
    let libelleMois = "Ce mois de "+tableauMois[new Date().getMonth()];
 }

  maxDate = new Date();

  confirmPrintRecu(item) {

    let objToSave = Object.assign({}, item);

    swal({
      title: 'Reçu',
      text: 'Voulez-vous imprimer le reçu ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.getReceuVente(item);
      } 
    })
  }

  deleteItemInListeVente(indice)
  {
    swal({
      title: 'Suppression vente',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.ListeVenteToSave.splice(indice,1);
        this.getMontantTotalVente();
      } else {
       
      }
    })
  }

  cancelVente()
  {

    swal({
      title: 'Annulation vente',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.cancelSave();
      } else {
       
      }
    })
  }

  hideModal() {

    if(this.ListeVenteToSave && this.ListeVenteToSave.length > 0)
    {
      swal({
        title: 'Fermeture de fenêtre',
        text: 'Des saisies sont en cours, voulez-vous fermer la fenêtre ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
        if (result) {
          this.modalRef.hide();
        } 
      })
    }
    else
    {
      this.modalRef.hide();
    }
  }

  openFormModalModal(template, itemToModified,isDetails) {
    this.cancelSave();
    
    if (itemToModified) {
      this.itemApproToSave = {}; 
      this.itemApproToSave = Object.assign({}, itemToModified);
    }

    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom-appro' }));
  }

  searchByCodeImei() {
   
    if(!this.codeImeiSearch)
    {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le code IMEI !");
      return;
    }

    if(this.lastCodeImeiSearch == this.codeImeiSearch)
    {
      // C'est une recherche qui est deja effectué
      // On effectue cela parce que la recherche peut se lancer deux fois

      return;
    }

    console.log(" le message ",this.codeImeiSearch);

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        codeImei : this.codeImeiSearch,
        "codeImeiParam":{
          "operator":"="
         }
      },
      index: 0,
      size: 1
    }

    this.busyGet = this.api.post('imeiFournisseur/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {

            if(res['items'].length == 1)
            {
              let currentResultImei = res['items'][0];

              if(currentResultImei.idClientAyantAchete)
              {
                this.notificationService.showMessageError("Erreur","Ce télephone est déjà acheté par un client ");
                return
              } 
              
              this.lastCodeImeiSearch = this.codeImeiSearch;
              this.articleSelectedByImei = currentResultImei.articleDto;

              //this.selectProduct(currentResultImei.articleDto);
            }

          } 
          else
          {  
             this.notificationService.showMessageError("Erreur","Aucun télephone trouvé ");
             return
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );

  }

  // pageChanged(event: any): void {
  //   this.currentPage = event.page;
  //   this.oldValueSearch = ""; 
  //   this.getData();
  // }

  // changeItermPerPage() {
  //   this.currentPage = 1;
  //   this.isChangeItermPerPage = true;
  //   this.oldValueSearch = "";
  //   this.getData();
  // }

  // getData() {
   
  //   let search_value = this.itemToSearch.designation;
  //   let isSearch = this.itemToSearch.designation ? true : false;

  //   if(search_value && this.oldValueSearch)
  //   {
  //     if(search_value.trim() != this.oldValueSearch)
  //     {
  //       this.currentPage = 1;
  //     }
  //   }
   
  //   if(search_value){
  //      search_value = search_value.trim();

       
  //      if(search_value.length>0 && search_value.length<3)
  //      {
  //         if(!this.isChangeItermPerPage)
  //         {
  //           return; 
  //         }
  //      }
  //      else
  //      {
  //        if(search_value == this.oldValueSearch)
  //        {
  //          return;
  //        }
  //        this.oldValueSearch = search_value; 
  //        //this.currentPage = 1;
  //      }
  //   }
  //   else
  //   {
  //     if(this.oldValueSearch == "" && isSearch)
  //     {
  //       return;
  //     }

  //     this.oldValueSearch = ""; 
  //   } 

  //   var request = {
  //     user: this.user.proxy,
  //     data: {
  //       boutiqueId : this.userService.getCurrentBoutiqueUser(),
  //       articleLibelle : search_value ? search_value : null,
  //       action : enumActionApproStorePro.RETRAIT_ARTICLE,
  //       createdAt : null,
  //       createdAtParam : {}
  //     },
  //     index: (this.currentPage - 1),
  //     size: this.itemsPerPage ? this.itemsPerPage : null
  //   }

  //   if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
  //     request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
  //     request.data.createdAtParam =
  //         {
  //             "operator": "[]",
  //             "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
  //             "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
  //         }
  //   }

  //   this.busyGet = this.api.post('approvisionnement/getByCriteria', request)
  //     .subscribe(
  //       res => {
  //         console.log(res);
  //         if (res && res['items']) {
  //           this.isChangeItermPerPage = false;
  //           this.results = res['items'];
  //           this.totalItems = res['count'] ? res['count'] : 0;
  //         } else {
  //           this.results = [];
  //         }
  //       },
  //       err => {
  //         console.log("Error occured", err);
  //         this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
  //         // this.not
  //       }
  //     );

  // }

selectProduct = function(item) {

    console.log(item);
    if (!item || !item.id) {
      this.itemApproToSave = {};
      return;
    };

    this.itemApproToSave = Object.assign({}, item);

    if(!this.itemApproToSave.quantiteDisponible)
    {
      this.itemApproToSave.quantiteDisponible = 0;
      this.notificationService.showMessageError("Erreur","Il n'y a pas de quantité disponible pour cet article !");
      return;
    }

    this.itemApproToSave.prixTotalAvecRemise = this.itemApproToSave.prixVente;
    this.itemApproToSave.dateSortie = new Date();

    this.itemApproToSave.prixAchatFormat = this.userService.getFormatMillierInput(this.itemApproToSave.prixAchat,true);
    this.itemApproToSave.prixVenteFormat = this.userService.getFormatMillierInput(this.itemApproToSave.prixVente,true);
    this.itemApproToSave.quantiteSortie = 1;
    this.addProductToSale(this.itemApproToSave);
  };

selectedClient(client)
{
  
  if(client)
  {
    this.currentClient = client;
    this.itemToSearch.clientSearch = client.libelle;
    this.itemToSearch.clientContactSearch = client.contact;
    this.itemToSearch.isDisplayTable = false;
  }
}

  getDataProduits() {

    var request = {
      user: this.user.proxy,
      "data": { 
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        statut : enumStatutStorePro.ACTIF
      }
    }
  
    this.busyGet = this.api.post('article/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat',res); 
          if (res && res['items']) {
            //Recuperer ceux qui ont des quantités en stocks
            
            res['items'] = _.filter(res['items'], function(o) { return o.quantiteDisponible>0; });
            this.ListeProduits = _.orderBy(res['items'], ['libelle'], ['asc']);
          } else {
            this.ListeProduits = [];
          }
        },
        err => {
         
        }
      );

  }

  confirmSaveItem() {

    let itemAEnregistrer = Object.assign({}, this.itemToSave);

    if (!this.ListeVenteToSave || this.ListeVenteToSave.length == 0) {
      this.notificationService.showMessageError("Information", "Veuillez vérifier la liste des produits à vendre.")
      return;
    }

    //Associé les couleurs
    this.ListeVenteToSave.forEach(element => {
        if(element.couleurSelected && element.couleurSelected != null)
        {
          element.couleurTelephone = element.couleurSelected.libelle;
        }
        else
        {
          element.couleurTelephone = null;
        }
    });


     //Associé les couleurs
     this.ListeVenteToSave.forEach(element => {
      if(element.capaciteSelected && element.capaciteSelected != null)
      {
        element.capaciteTelephone = element.capaciteSelected.libelle;
      }
      else
      {
        element.capaciteTelephone = null;
      }
  });


    // if (!this.itemToSave.clientSelected ) {
    //   this.notificationService.showMessageError("Information", "Veuillez sélectionner un client.")
    //   return;
    // }

    if (!this.itemToSave.montantTotal ) {
      this.notificationService.showMessageError("Information", "Le Net à payer n'est pas defini defini.")
      return;
    }

    //Verifier si le reste à payer de la vente est supérieur à zero, dans ce cas il doit renseigner le libelle et le contact du client
    console.log(" this.itemToSave.resteAPayer ",this.itemToSave.resteAPayer);
    if(this.itemToSave.resteAPayer != 0 )
    {
      if(!this.itemToSearch.clientContactSearch && !this.itemToSearch.clientSearch)
      {
        this.notificationService.showMessageError("Information", "Veuillez renseigner les noms du client.")
        return;
      }
    }

     //Formater la date d'approvisionnement
     let dateFormatOperation = moment(itemAEnregistrer.dateSortie).format("DD/MM/YYYY");
     if(dateFormatOperation =="Invalid date")
     {
       dateFormatOperation = itemAEnregistrer.dateSortie + " 00:00:00";
     }
     else
     {
       dateFormatOperation = dateFormatOperation + " 00:00:00";
     }

     itemAEnregistrer.dateSortie = dateFormatOperation;
     itemAEnregistrer.boutiqueId = this.userService.getCurrentBoutiqueUser();
     itemAEnregistrer.typeSortie = enumTypeSortieStorePro.VENTE;
     itemAEnregistrer.montantPaye = parseFloat(itemAEnregistrer.montantPaye) || 0;
    if(itemAEnregistrer.montantTotal >= itemAEnregistrer.resteAPayer)
     {
      itemAEnregistrer.estVenteACredit = true;
      itemAEnregistrer.statut = enumStatutStorePro.EN_COURS;
     
     }
     else
     {
      itemAEnregistrer.estVenteACredit = false;
      itemAEnregistrer.statut = enumStatutStorePro.SOLDE; 
     
     }

     // console.log(" this.itemToSave.clientSelected ",this.itemToSave.clientSelected);
     if(!this.currentClient || !this.currentClient.id)
     {
        //Verifier si le nom et le contact son renseigné
        // if(!this.itemToSearch.clientSearch || !this.itemToSearch.clientContactSearch)
        // {
        //   this.notificationService.showMessageError("Erreur","Veuillez renseigner le nom du client et son contact.");
        //   return;
        // }

        if(this.itemToSearch.clientContactSearch && this.itemToSearch.clientSearch)
        {
          itemAEnregistrer.clientDto = {
            contact : this.itemToSearch.clientContactSearch,
            libelle : this.itemToSearch.clientSearch.toUpperCase(),
            boutiqueId : this.userService.getCurrentBoutiqueUser(),
            searchString : this.itemToSearch.clientSearch+' '+this.itemToSearch.clientContactSearch
          };
        }

     }
     else
     {
       itemAEnregistrer.clientId = this.currentClient.id;
     }

     if(itemAEnregistrer.isGarantieAppleInternational)
     {
      itemAEnregistrer.garantieMessage = "Garantie : 1 semaine en magasin, 1an Apple International";
     }

     console.log(" itemAEnregistrer ",itemAEnregistrer);
    //  return;

    swal({
      title: itemAEnregistrer.idOldSortieUpdateSortie ? "Modification d'une Vente":"Enregistrement d'une vente",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        itemAEnregistrer.id = null;
        this.saveItem(itemAEnregistrer);
      }
    })

  }


  
  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }

  getReceuVente(item) {

    var request = {
      user: this.user.proxy,
      symbolDevise : this.userService.getCurrentDevise(),
      data: {
        id: item.id
      }
    }


    this.busyGet = this.api.post('sortie/getReportFacture', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  selectionCurrentLigneToUpdate(itemHisto)
  {
    console.log(" itemHisto ",itemHisto);
    let itemToUpdate = Object.assign({}, itemHisto);

    this.ListeVenteToSave = itemToUpdate.datasLigne;

    this.itemToSave = itemToUpdate;
    // this.montantTotalFormat = itemToUpdate.montantTotal;

    this.itemToSave.montantPaye = itemToUpdate.montantPaye;
    this.itemToSave.resteAPayerFormat = itemToUpdate.resteAPayer;
    this.itemToSave.montantTotalFormat = itemToUpdate.montantTotal;

    this.itemToSave.numeroFacture = itemToUpdate.numeroFacture;
    this.itemToSave.idOldSortieUpdateSortie = itemToUpdate.id; 

    this.currentClient = {
      id : itemToUpdate.clientId,
      libelle : itemToUpdate.clientLibelle,
      contact : this.itemToSave.contactClient || null,
    }

    this.isUpdateVente = true;
    //Formater la date de la vente
    let tabFormatDate = itemToUpdate.dateSortie.split(" ")[0].split("/");
    console.log("tabFormatDate",tabFormatDate);
    this.itemToSave.dateSortie = (new Date(tabFormatDate[2],tabFormatDate[1]-1,tabFormatDate[0]));
    // console.log(" valeur recu ",this.currentClient); 
  }

  cancelSave() {
    this.itemToSave = {
      clientSelected : null,
      produitSelected : "",
      dateSortie : new Date()
    };
    this.isUpdateVente = false; 
    this.itemToSearch.clientContactSearch = null;
    this.itemToSearch.clientSearch = null;
    this.itemApproToSave = {};
    this.ListeVenteToSave = [];
    this.currentCommande = null;
  }

  saveItem(item) {
  
    item.datasLigne = this.ListeVenteToSave;
    var request = {
      user : this.user.proxy,
      datas: [item]
    };

    console.log("le request ",request);

    this.busyGet = this.api.post('sortie/'+(item.idOldSortieUpdateSortie ? 'updateVente':'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] ) {
            this.notificationService.showMessageSucces("Vente", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            //this.currentPage = 1;
            this.cancelSave(); 
            this.counterRefreshHistorique++;
            this.resetitemInfoAfterVente = new Date().getTime();
            this.confirmPrintRecu(res['items'][0])  
            //this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  addProductToSale(appro)
  {
    let approToSave = Object.assign({}, this.itemApproToSave);
    if(!approToSave.id)
    {
      this.notificationService.showMessageError("Erreur","Veuillez sélectionner un article.");
      return;
    }

    if(!approToSave.quantiteSortie)
    {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner la quantité à vendre.");
      return;
    }

    if(!approToSave.quantiteDisponible)
    {
      this.notificationService.showMessageError("Erreur","Veuillez vérifier la quantité disponible de l'article.");
      return;
    }

    if(!this.itemApproToSave.prixTotalAvecRemise || this.itemApproToSave.prixTotalAvecRemise < 0)
    {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner un montant valide.");
      return;
    }

    if(approToSave.quantiteSortie > approToSave.quantiteDisponible)
    {
      this.notificationService.showMessageError("Erreur","Veuillez vérifier la quantité en stock.");
      return;
    }

    //Verifier que l'article n'a jamais eté ajouté a la liste et faire la verififcation egalement avec le mail
    if(!this.codeImeiSearch)
    {
      let currentArticleInLigneVentes = _.find(this.ListeVenteToSave, function(o) { return o.articleId ==  approToSave.id});
      if(currentArticleInLigneVentes)
      {
        // this.notificationService.showMessageError("Erreur","Cet article est déjà dans la liste.");
        // return;
  
        console.log("currentArticleInLigneVentes ",currentArticleInLigneVentes);
        //On verifie si on peut l'ajouter à la quantité
        if(currentArticleInLigneVentes.quantiteDispoAvantOperation < (currentArticleInLigneVentes.quantiteSortie + 1))
        {
              this.notificationService.showMessageError("Erreur","Veuillez vérifier la quantité en stock.");
              return;
        }
        else
        {
          currentArticleInLigneVentes.quantiteSortie =  currentArticleInLigneVentes.quantiteSortie + 1;
          this.getMontantTotalVente();
          return;
        }
      }
    }
    else
    {
      // Dans ce cas, il verifie si l'article et le code imei ne sont pas deja dans la liste
      // Si cela existe alors on ramene un message d'eereur sinon on continue

      let currentArticleExisteInListe = _.find(this.ListeVenteToSave, (o)=> { return (o.articleId ==  approToSave.id && o.imeiTelephone == this.codeImeiSearch )});

      if(currentArticleExisteInListe)
      {
        this.notificationService.showMessageError("Erreur","Cet article est déjà dans la liste.");
        return;
      }
     
    }
   

    this.ListeVenteToSave.push(
      {
        action : enumActionApproStorePro.RETRAIT_ARTICLE,
        articleId : approToSave.id,
        libelleArticle : approToSave.libelle,
        codeArticle : approToSave.code,
        quantiteSortie : approToSave.quantiteSortie,
        numero : approToSave.numero,
        quantite : approToSave.quantite,
        prixAchat : approToSave.prixAchat,
        prixUnitaire : approToSave.prixVente,
        prixVente :  approToSave.prixVente,
        quantiteDispoAvantOperation : approToSave.quantiteDisponible,
        prixTotalAvecRemise : approToSave.prixTotalAvecRemise,
        statut : enumStatutStorePro.VALIDE,
        //dateSortie : dateFormatOperation,
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        imeiTelephone : this.codeImeiSearch
      }
    )

    //Calculer le montant total
    this.getMontantTotalVente();
    this.itemApproToSave = {};
    this.itemToSave.produitSelected = null;
    this.resetitemInfo = new Date().getTime();
    this.codeImeiSearch = "";
  }


  getDataListeCommande() {

    var request = {
      user: this.user.proxy,
      "data": { 
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        statut : enumStatutStorePro.VALIDE
      }
    }
  
    this.busyGet = this.api.post('commande/getCommandeAVendreByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat',res); 
          if (res && res['items']) {
            //Recuperer ceux qui ont des quantités en stocks
            
            // res['items'] = _.filter(res['items'], function(o) { return o.quantiteDisponible>0; });
            this.ListeCommandes = _.orderBy(res['items'], ['id'], ['asc']);
          } else {
            this.ListeCommandes = [];
          }
        },
        err => {
         
        }
      );
  }

  selectedCommande(commande) {

    this.currentClient = null;
    
    this.currentCommande = {...commande};


    this.currentClient = {
      id: commande.clientId,
      libelle: commande.clientLibelle,
      contact: commande.contactClient,
      boutiqueId: commande.boutiqueId
    };


    this.getDataLigneCommande(commande);
  }

  getDataLigneCommande(commande) {

    var request = {
      user: this.user.proxy,
      "data": { 
        commandeId : commande.id
      }
    }
  
    this.busyGet = this.api.post('ligneCommande/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat',res); 
          if (res && res['items']) {
            //Recuperer ceux qui ont des quantités en stocks
            
            // res['items'] = _.filter(res['items'], function(o) { return o.quantiteDisponible>0; });
            this.ListeLigneCommandes = _.orderBy(res['items'], ['id'], ['asc']);

            this.ListeVenteToSave = [];
            // Reconstituer la liste des ventes
            this.ListeLigneCommandes.forEach(ligneCommande => {

              // On push ceux qui ne sont pas livree totalement
              if(ligneCommande.statut != enumStatutStorePro.LIVRER_TOTALEMENT) {
                this.ListeVenteToSave.push(
                  {
                    articleId : ligneCommande.articleId,
                    quantiteCommandee : ligneCommande.quantiteCommandee,
                    prixUnitaireDiscute : ligneCommande.prixUnitaireDiscute,
                    boutiqueId : this.userService.getCurrentBoutiqueUser(),
                    libelleArticle : ligneCommande.articleLibelle,
                    codeArticle : ligneCommande.articleCode,
                    quantiteSortie : ligneCommande.quantiteCommandee,
                    statut : enumStatutStorePro.VALIDE,
                    quantite : ligneCommande.quantiteCommandee,
                    prixAchat : ligneCommande.prixUnitaireAchat,
                    prixUnitaire : ligneCommande.prixUnitaireDeVente,
                    prixVente :  ligneCommande.prixUnitaireDeVente,
                    quantiteDispoAvantOperation : '-',
                    prixTotalAvecRemise : ligneCommande.prixUnitaireDiscute,
                    // imeiTelephone : this.codeImeiSearch,
                    ligneCommandeId : ligneCommande.id
                  }
                )
              }
             
            });

            this.getMontantTotalVente();

            // Preselectionner le nom du client
            this.currentClient = {
              id : commande.clientId,
              libelle : commande.clientLibelle,
              contact : commande.contactClient,
              boutiqueId : commande.boutiqueId
            };

          } else {
            this.ListeLigneCommandes = [];
          }
        },
        err => {
         
        }
      );
  }

  getMontantTotalVente()
  {
    //Verifier s'il y a des lignes negatives
    let currentItemMontantNegatif = _.find(this.ListeVenteToSave, function(o) { return (!o.prixTotalAvecRemise || o.prixTotalAvecRemise <= 0); });
    if(currentItemMontantNegatif && currentItemMontantNegatif.articleId)
    {
      this.notificationService.showMessageError("Erreur","Impossible de saisir un montant négatif.");
      currentItemMontantNegatif.prixTotalAvecRemise = Math.abs(currentItemMontantNegatif.prixTotalAvecRemise);
      return;
    }

    this.itemToSave.montantTotal = _.sumBy(this.ListeVenteToSave, function(o) { return (o.prixTotalAvecRemise || 0) * (o.quantiteSortie || 0) ; });
    this.itemToSave.montantTotalFormat = this.userService.getFormatMillierInput(this.itemToSave.montantTotal);
    this.getResteAPayer();

  }

  cancelSearch() {
    this.itemToSearch = {};
    // this.changeItermPerPage();
  }

  getResteAPayer()
  {
    if(this.itemToSave.montantPaye)
    {
      this.itemToSave.resteAPayer = this.itemToSave.montantTotal - this.itemToSave.montantPaye;
    }
    else
    {
      this.itemToSave.resteAPayer = this.itemToSave.montantTotal;
    }

    this.itemToSave.resteAPayerFormat = this.userService.getFormatMillierInput(parseFloat(this.itemToSave.resteAPayer).toFixed(2));
   
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);


    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        this.cancelSave();
        this.getDataProduits();
        this.getDataListeCommande(); 
      } 
    });

  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
