import { Injectable } from '@angular/core';
import { RequestOptions, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import {HttpClient, HttpParams, HttpHeaders} from "@angular/common/http";
import {config} from '../../smartadmin.config';
import {log} from 'util';
import {UserService} from "../../user/user.service";


@Injectable()
export class Api {
    url: any; 
    user :any;
    urlBase : any;
    stationSelected : any = {};
    constructor(public http: HttpClient,private userService: UserService) {
         this.user = this.userService.getCurrentUser();  
         this.url ="http://51.68.124.123:8080/dev.cherif_coporation.store_pro.store_pro-api-v1-0.1/";        
        //  this.url ="http://localhost:8080/";           
    }

    get(endpoint: string, params?: any, options?: HttpParams) {

        return this.http.get(this.url + '/' + endpoint);
    }

    getOption(endpoint: string, params?: any, options?: any) {

        return this.http.get(this.url + '/' + endpoint,options);
    }

    postOption(endpoint: string, body: any, options: any) {
        return this.http.post(this.url + '/' + endpoint, body,options);
    }

    connexion(endpoint: string, body: any, options?: any) {
        return this.http.post(this.url + '/' + endpoint, body,options);
    }

    post(endpoint: string, body: any, options?: any) {
        return this.http.post(this.url + '/' + endpoint, body,options);
    }
    
    put(endpoint: string, body: any, options?: RequestOptions) {
        return this.http.put(this.url + '/' + endpoint, body);
    }

    delete(endpoint: string, options?: RequestOptions) {
        return this.http.delete(this.url + '/' + endpoint);
    }

    patch(endpoint: string, body: any, options?: RequestOptions) {
        return this.http.put(this.url + '/' + endpoint, body);
    }
}
