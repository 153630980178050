import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";
import { UserService } from '../../user/user.service';
import { Api } from '../../utils/api/api';
import * as _ from "lodash";

@Component({
  selector: 'sa-route-breadcrumbs',
  template: `
        <ol class="breadcrumb">
           <li *ngFor="let item of items">{{item}}</li>
        </ol>

        <div class="pull-right" style='margin-top: 6px;'>
        <select class="form-control" id="specificiteSearchSelected" (change)="selectedFiliale(filialeSelected)" [disabled]='isReadOnly' [class.isReadOnly]='isReadOnly' [(ngModel)]="filialeSelected" name="statutSearchSelected" style="border-radius:30px !important">
          <option value="">-- Choisissez --</option>
          <option *ngFor="let dispo of ListeFiliales" [ngValue]="dispo">
            {{ dispo.libelle }}
          </option>
        </select>

     

      </div>

        <!--div class='col-md-7 pull-right' style='text-align:center'>
          <span style='float: left;
          font-size: 25px;
          font-weight: bold;
          color: #ee414d;'> Devise : {{currentDevise.libelleSymbol}} ({{currentDevise.symbol}})  </span>
        </div -->

        <style>
          .isReadOnly
          {
            background:#a4a8a8 !important;
          }
        </style>
 
  `,
  styles: []
})
export class RouteBreadcrumbsComponent implements OnInit, OnDestroy {

  public items: Array<string> = [];
  ListeFiliales : Array<any> = [];
  private sub;
  currentDevise : any = {};
  filialeSelected : any = {};
  currentValue :  any = {};
  user : any = {};
  isReadOnly : boolean;

  constructor(
    private router: Router,private userService: UserService,private api:Api
  ) { 
    this.currentDevise.symbol = this.userService.getCurrentDevise();
    this.currentDevise.libelleSymbol = this.userService.getCurrentLibelleDevise();

    this.user = this.userService.getCurrentUser();
    this.getDataFiliale();

  }

  ngOnInit() {
    this.extract(this.router.routerState.root)
    this.sub = this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe(v => {
        this.items = [];
        this.extract(this.router.routerState.root)
      });

  }

  selectedFiliale() {
    console.log("emet l evenement");
    this.currentValue = this.filialeSelected;
    this.userService.updateStationInformation(this.currentValue);
 }

  getDataFiliale()
  {
        var request = {
            "user":this.user.id,
            "data":{
              // id : this.user.boutiqueId || null
            } 
        }

       this.api.post('boutique/getByCriteria', request)
            .subscribe(
                res => {
                    console.log('liste des filiales',res); 
                    if(res && res['items'])
                    {
                        this.ListeFiliales = res['items'].reverse();

                        // if(this.user && !this.user.isSuperAdmin)
                        // {
                        //   this.filialeSelected =  _.find(this.ListeFiliales, { 'id':this.user.boutiqueId });
                        //   this.userService.updateStationInformation(this.filialeSelected);
                        //   this.isReadOnly = true;
                        // }
                        // else
                        // {
                          if(this.ListeFiliales && this.ListeFiliales.length > 0)
                          {
                            if(this.user.boutiqueId && (!this.user.datasBoutique || this.user.datasBoutique.length == 0 )) //C'est que c'est pas un administrateur
                            {
                              this.filialeSelected =  _.find(this.ListeFiliales, { 'id':this.user.boutiqueId });
                              this.isReadOnly = true;
                            }

                            if(this.user.datasBoutique && this.user.datasBoutique.length > 0) {
                              this.ListeFiliales = [];

                              this.user.datasBoutique.forEach(element => {
                                this.ListeFiliales.push({
                                  'id' : element.boutiqueId,
                                  'libelle' : element.boutiqueLibelle
                                  }
                                );
                              });  

                              if(this.ListeFiliales.length == 1) {
                                this.isReadOnly = true;
                                this.filialeSelected = this.ListeFiliales[0];
                              }
                            }

                            this.filialeSelected =  this.ListeFiliales[0];
                            this.userService.updateStationInformation(this.filialeSelected);
                          }
                        // }
                       
                    }
                    else
                    {
                      this.ListeFiliales = [];
                    }
                },
                err => {
                }
            );
  } 


  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }


  extract(route){
    let pageTitle = route.data.value['pageTitle'];
    if(pageTitle && this.items.indexOf(pageTitle) == -1){
      this.items.push(route.data.value['pageTitle'])
    }
    if(route.children){
      route.children.forEach(it=>{
        this.extract(it)
      })
    }
  }


}
