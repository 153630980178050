import {Component, OnInit, OnDestroy} from '@angular/core';
import {config} from '../smartadmin.config';
import {LayoutService} from './layout.service'
import { Subscription } from 'rxjs/Subscription';
import {UserService} from "../user/user.service";
import {ActivatedRoute, Route, Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'sa-layout-switcher',
  templateUrl: './layout-switcher.component.html'
})
export class LayoutSwitcherComponent implements OnInit, OnDestroy {
  isActivated:boolean;
  smartSkin:string;
  store: any;
  user: any;
  private sub: Subscription;

  constructor(public layoutService:LayoutService,private userService: UserService,public router:Router) {}

  ngOnInit() {
    this.sub = this.layoutService.subscribe((store)=>{
      this.store = store;
    });
    this.store = this.layoutService.store;

    this.user = this.userService.getCurrentUser();

    console.log("this user header",this.user);
    
    if(!this.user)
    {
      this.router.navigate(['/auth/login']);
    }

  }

  ngOnDestroy(){
    this.sub.unsubscribe()
  }


  onToggle() {
    this.isActivated = !this.isActivated
  }


  onSmartSkin(skin) {
    this.layoutService.onSmartSkin(skin)
  }


  onFixedHeader() {
    this.layoutService.onFixedHeader()
  }


  onFixedNavigation() {
    this.layoutService.onFixedNavigation()
   
  }


  onFixedRibbon() {
    this.layoutService.onFixedRibbon()
  }


  onFixedPageFooter() {
    this.layoutService.onFixedPageFooter()
  }


  onInsideContainer() {
    this.layoutService.onInsideContainer()
  }


  onRtl() {
    this.layoutService.onRtl()
  }


  onMenuOnTop() {
    this.layoutService.onMenuOnTop();
    //localStorage.setItem("sm-menu-on-top",'false');
  }


  onColorblindFriendly() {
    this.layoutService.onColorblindFriendly()
  }


  factoryReset() {
    this.layoutService.factoryReset()
  }
}
