import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-fournisseur-credit',
  templateUrl: './main-fournisseur-credit.component.html',
  styleUrls: ['./main-fournisseur-credit.component.css']
})
export class MainFournisseurCreditComponent implements OnInit {
  
  indiceTab : number = 0;
  constructor() { }

  selectedChangeValue(event : any)
  {
      this.indiceTab = event;
  } 
  
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
