import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-main-credit-versement',
  templateUrl: './main-credit-versement.component.html',
  styleUrls: ['./main-credit-versement.component.css']
})
export class MainCreditVersementComponent implements OnInit {

  constructor() { }

  @Input() afficherCadreClient;
  @Input() afficherFormReglement;
  @Input() isRefresh;
  
  ngOnInit() {
  }

 
  
}
