import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-compte-icloud',
  templateUrl: './main-compte-icloud.component.html',
  styleUrls: ['./main-compte-icloud.component.css']
})
export class MainCompteIcloudComponent implements OnInit {

  
  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  linkPdfFile : string;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  oldValueSearch : String;
  isChangeItermPerPage : boolean = false;
  enumStatut : any = {};
  bsModalRefPreview: BsModalRef;

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {};
    this.oldValueSearch = "";  
  }

  maxDate = new Date();

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
   
    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);
     }
    
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom' }));
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }


  getData() {

    let search_value = this.itemToSearch.designation;
   

    var request = {
      user: this.user.proxy,
      data: {
        identiteClient : search_value ? search_value : null,
        boutiqueId : this.userService.getCurrentBoutiqueUser()
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('compteIcloud/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false; 
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
        }
      );

  }


  confirmSaveItem(obj) {

    let item =  Object.assign({}, obj);

    if (!item || !item.dateReception) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé de la boutique.")
      return;
    }

    if (!item || !item.technicien) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le nom du technicien.")
      return;
    }

    if (!item.identiteClient) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le nom du client.")
      return;
    }

    if (!item.contactClient) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le contact du client.")
      return;
    }

    if (!item.compteApple) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le compte Apple.")
      return;
    }

    if (!item.motPasseClient) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le mot de passe du client.")
      return;
    }

    //Formater la date de reception
    let dateFormatOperation = moment(item.dateReception).format("DD/MM/YYYY");
    if (dateFormatOperation == "Invalid date") {
      dateFormatOperation = item.dateReception + " 00:00:00";
    }
    else {
      dateFormatOperation = dateFormatOperation + " 00:00:00";
    }

    //Formater la date de naissance
    let dateFormatNaissance = moment(item.dateNaissanceClient).format("DD/MM/YYYY");
    if (dateFormatNaissance == "Invalid date") {
      dateFormatNaissance = item.dateNaissanceClient + " 00:00:00";
    }
    else {
      dateFormatNaissance = dateFormatNaissance + " 00:00:00";
    }

    item.dateNaissanceClient = dateFormatNaissance;
    item.dateReception = dateFormatOperation;
    item.technicien = item.technicien.toUpperCase()
    item.identiteClient = item.identiteClient.toUpperCase(),
    item.boutiqueId = this.userService.getCurrentBoutiqueUser()
    
    swal({
      title: (!obj.id) ? "Enregistrement d'un compte" : "Modification d'un compte",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  confirmSupprimerCompte(itemCompte)
  {

    swal({
      title: "Suppression de compte",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(itemCompte);
      } else {
      }
    });

  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id,
      }]
    }

    this.busyGet = this.api.post('compteIcloud/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {

            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.getData();

          }
          else {

            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }

          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  cancelSave() {
    this.itemToSave = {};
  }


  confirmPrintRecu(item) {

    let objToSave = Object.assign({}, item);

    swal({
      title: 'Reçu',
      text: 'Voulez-vous imprimer le reçu ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.getReceuVente(item);
      } else {
      }
    })
  }

  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }


  getReceuVente(item) {

    var request = {
      user: this.user.proxy,
      symbolDevise : this.userService.getCurrentDevise(),
      data: {
        id: item.id
      }
    }

    this.busyGet = this.api.post('compteIcloud/getReportRecuCompteIcloud', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  
  saveItem(item,isSuspendre?) {

  
    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('compteIcloud/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Compte iCloud", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
          
            this.hideModal();
        
          
            this.cancelSave(); 
            this.getData();
            this.confirmPrintRecu(res['items'][0]);
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    // this.getData();

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        this.getData();
      } 
    });
  }


  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
