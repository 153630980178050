import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-station',
  templateUrl: './main-station.component.html',
  styleUrls: ['./main-station.component.css']
})
export class MainStationComponent implements OnInit {

  
  results: Array<any> = [];
  ListeDepartements: Array<any> = [];
  ListePays :  Array<any> = [];
  ListeDevises :  Array<any> = [];
  ListeCartes: Array<any> = [];
  ListeVilles : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch : any;
  modalRef: BsModalRef;
  stationSelected : any = {};
  isChangeItermPerPage : boolean = false;


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    this.oldValueSearch = "";
  }


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);

      //Selectionner le pays et la devise
      // this.itemToSave.paysSelected =  _.find(this.ListePays, { 'id':itemToModified.country }); 
      // this.itemToSave.deviseSelected =  _.find(this.ListeDevises, { 'id':itemToModified.currency });
      this.itemToSave.villeSelected =  _.find(this.ListeVilles, { 'libelle':itemToModified.ville}); 
     
    }
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom' }));
   // this.modalRef = this.modalService.show(template);
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  getData() {

    let search_value = this.itemToSearch.libelle;
    let isSearch = this.itemToSearch.libelle ? true : false;

    if(search_value && this.oldValueSearch)
    {
      if(search_value != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }
    
    if(search_value){
       search_value = search_value.trim();

       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }

      this.oldValueSearch = ""; 
    } 

    var request = {
      user: this.user.proxy,
      data: { 
        searchFullText : search_value ? search_value : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('stations/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }


  confirmSaveItem(obj) {

    let item = obj;
    if (!item || !item.nomPrenomsGerant || !item.libelle || !item.villeSelected || !item.commune  || !item.quartier || !item.contactGerant ) {
      this.notificationService.showMessageInfo("Information", "Veuillez renseigner les champs obligatoires.");
      return;
    }


    swal({
      title: (!obj.id) ? 'Création station' : 'Modification station',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    })

  }

  confirmDeleteItem(obj,isActive) {
    //this.lgModal.show();

    swal({
      title: !isActive ? 'Fermer cette station' : 'Ouvrir cette station',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj,isActive);
      } else {
        console.log("bnjr");
      }
    })

  }

  checkCurrentItem(item) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemChecked = item;
    }
  }

  cancelSave() {
    this.itemToSave = {};
    // this.itemToSave.paysSelected =  _.find(this.ListePays, { 'code':'CIV'}); 
    // this.itemToSave.deviseSelected =  _.find(this.ListeDevises, { 'iso':'XOF'}); 

  }

  saveItem(item) {

    if (!item || !item.nomPrenomsGerant || !item.libelle || !item.villeSelected || !item.commune  || !item.quartier || !item.contactGerant ) {
      this.notificationService.showMessageInfo("Information", "Veuillez renseigner les champs obligatoires.");
      return;
    }

    item.ville = item.villeSelected.libelle;

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('stations/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Station",this.utilities.mainSelfFormatMsgServeur(res['status']['message']) );
            this.currentPage = 1;
            this.hideModal();
            //Actualiser le select de station 
            this.userService.updateRefreshStationInformation(res['items'][0]);
            this.cancelSave(); 
            this.getData();
            
            
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj,isActive) {

    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id,
        isActive: isActive,
        //requester: this.user.id, 
      }]
    }

    this.busyGet = this.api.post('stations/fermerOuvrir', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            let message ="";
            message = !isActive ? "Fermeture d'une station" : "Ouverture d'une station"

            this.notificationService.showMessageSucces(message, this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  villeChange(ville)
  {
    if(ville && ville.code)
    {
      if(ville.code !=  "ABJ" && !_.includes(this.itemToSave.commune, ville.libelle) )
      {
         this.itemToSave.commune = this.itemToSave.villeSelected.libelle;
      }
    }
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  getListeVilles()
  {
    this.ListeVilles = [
      {
        libelle : "ABIDJAN",
        code : "ABJ"
      },
      {
        libelle : "BOUAKE",
        code : "BOU"
      },
      {
        libelle : "DALOA",
        code : "DAL"
      },
      {
        libelle : "KORHOGO",
        code : "KOR"
      },
      {
        libelle : "GAGNOA",
        code : "GAG"
      },
      {
        libelle : "MAN",
        code : "MAN"
      },
      {
        libelle : "DIVO",
        code : "DIV"
      },
      {
        libelle : "SAN-PEDRO",
        code : "sp"
      },
      {
        libelle : "YAMOUSSOUKRO",
        code : "YAKRO"
      }

    ];

    this.ListeVilles = _.orderBy(this.ListeVilles, ['libelle'], ['asc']);

  }

  ngOnInit() {
    this.getData();
    this.getListeVilles();
    window.scrollTo(0, 0);

  //   this.userService.currentData.subscribe(currentData => {
  //     this.stationSelected = currentData;
  //      console.log('filiale',this.stationSelected); 
  //      if(this.stationSelected && this.stationSelected.id)
  //      {

  //      }
      
  //  });

  }

}
