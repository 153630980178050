import { Component, OnInit, ViewChild } from '@angular/core';

import { Observable } from "rxjs/Observable";
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ModalDirective } from "ngx-bootstrap";
import { Chart } from 'angular-highcharts';
import { Options } from 'angular-2-daterangepicker';
import * as moment from 'moment';

const swal = require('sweetalert2');

import * as _ from "lodash";
import { UserService } from '../../../shared/user';
import { Api } from '../../../shared/utils/api/api';
import { NotificationService } from '../../../shared/utils/notification.service';
import { enumStatutStorePro } from '../../../shared/utils/enumerator';

@Component({
  selector: 'app-main-dashbord',
  templateUrl: './main-dashbord.component.html',
  styleUrls: ['./main-dashbord.component.css']
})
export class MainDashbordComponent implements OnInit {

  adminUser: any;
  user: any;
  listeEmployers: Array<any> = [];
  listeStructures: Array<any> = [];
  ListeReglements: Array<any> = [];
  results: Array<any> = [];
  ListeDesVentes: Array<any> = [];
  ListeArticles: Array<any> = [];
  listeSalaires : Array<any> = [];

  pieRepartitionParArticleVendus: any;
  busyGet: Subscription;
  busyGetVente : Subscription;
  totalItems: number;
  totalItemsArticle: number = 0;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  daterangepickerOptions: any;
  totalItem: any = {};
  pieRepartitionParDeptClient: any = {};
  montantTotalReglementPeriode: number = 0;
  itemToSave: any;
  isOpen: boolean;
  busyGetA: Subscription;
  busyGetArticle: Subscription;
  busyGetSearchByPeriode: Subscription;
  itemStatistique: any = {};
  itemToSearch: any;
  diagrameVentePeriodique: any = {};
  montantTotalDepensesPeriode: any = 0;
  beneficeVenteByPeriodeFormat: any = 0;
  montantTotalPeriode: number = 0;
  beneficeVenteByPeriode: number = 0;
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  constructor(private userService: UserService, private api: Api, private notificationService: NotificationService) {

      this.itemToSave = {};
      this.user = {};
      // this.itemToSearch = {
      //     dateDebut: new Date(), //moment(new Date()).format("DD-MM-YYYY"),
      //     dateFin: new Date(), //moment(new Date()).format("DD-MM-YYYY") 
      // }

      let date = new Date();
      let moisActuel = date.getMonth() + 1;
      let anneeActuel = date.getFullYear();
      let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
      let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);
      let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
      let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);

      this.itemToSearch = {
          dateDebut: dateDebutIso, //this.isHistorique ? dateDebutIso : new Date(),
          dateFin: dateFinIso, //this.isHistorique ? dateFinIso : new Date()
      };

  }

  selectProduct(produit)
  {
    console.log(" produit ",produit);
    // if(produit)
    // {
    //   // Effectuer le filtre
    //   this.itemToSearch.produitSelected = produit;
    //   this.getDataVente(produit.id,null)
    // }

    // Effectuer le filtre
    this.itemToSearch.produitSelected = produit;

    this.getDataVente(produit.id,null)

  }

  getDataNombreUser() {
      var request = {
          user: this.user.id,
          data: {
              //statutId : enumStatutId.EnLigne,
              boutiqueId: this.userService.getCurrentBoutiqueUser()
          },
          index: 0,
          size: 1
      }

      this.busyGetA = this.api.post('user/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('nombre d', res);
                  if (res && res['items']) {
                      this.totalItem.nombreUtilisateur = (res['items'] && res['items'].length > 0 ? this.userService.getFormatMillierInput(res['count'], true) : null);
                  }
              },
              err => {
              }
          );
  }

  getDataNombreProduit() {
      var request = {
          user: this.user.id,
          data: {
              //statutId : enumStatutId.EnLigne
              boutiqueId: this.userService.getCurrentBoutiqueUser()
          },
          index: 0,
          size: 1
      }

      this.busyGetA = this.api.post('article/getByCriteria', request)
          .subscribe(
              res => {
                  if (res && res['items']) {
                      this.totalItem.nombreArticle = (res['items'] && res['items'].length > 0 ? this.userService.getFormatMillierInput(res['count'], true) : null);
                  }
              },
              err => {
              }
          );
  }

  getDataNbreClient() {
      var request = {
          user: this.user.id,
          data: {
              //statutId : enumStatutId.EnLigne
              boutiqueId: this.userService.getCurrentBoutiqueUser()
          },
          index: 0,
          size: 1
      }

      this.busyGetA = this.api.post('client/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('actualites', res);
                  if (res && res['items']) {
                      this.totalItem.nombreClient = (res['items'] && res['items'].length > 0 ? this.userService.getFormatMillierInput(res['count'], true) : null);
                      // let listeGroupBy = _.groupBy(res['items'], 'departementLibelle');
                      // this.drawGraphClientParDept(listeGroupBy);

                  }
              },
              err => {
              }
          );
  }

  cancelSearch() {
      this.itemToSearch = {};
  }

  //--------------------------------------------
  //Bilan global des ventes                    //
  //--------------------------------------------

  getDataVente(idArticle?, idClient?) {

      var dateDebut = moment(this.itemToSearch.dateDebut, "YYYYMMDD");
      var dateFin = moment(this.itemToSearch.dateFin, "YYYYMMDD");
      //Verifier si la periode selectionnée est valable
      if (dateFin.diff(dateDebut, "days") < 0) {
          this.notificationService.showMessageError("Erreur", "Veuillez vérifier la période sélectionnée.");
          this.results = [];
          this.montantTotalPeriode = 0;
          this.beneficeVenteByPeriode = 0;
          return;
      }

      this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
      this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

      var request = {
          user: this.user.proxy,
          recuperationInformationForStatistique: true,
          data: {
              boutiqueId: this.userService.getCurrentBoutiqueUser(),
              statut: enumStatutStorePro.VALIDE,
              articleId : idArticle || null,
              recuperationInformationForStatistique: true,
              isGetForStatistique : true,  
              articleLibelle : this.itemToSearch.produitSelected ? this.itemToSearch.produitSelected.libelleSearch : null,
              articleCode : this.itemToSearch.produitSelected ? this.itemToSearch.produitSelected.numeroSearch : null, 
            //   searchString : this.itemToSearch.produitSelected ? ( this.itemToSearch.produitSelected.libelleSearch.toLowerCase() || this.itemToSearch.produitSelected.numeroSearch.toLowerCase() ) : null,
              dateSortie: null,
              dateSortieParam: {} 
          },
          index : 0,
          size : 1
      }

      if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
          request.data.dateSortie = this.itemToSearch.dateDebutSearch + ' 00:00:00';
          request.data.dateSortieParam =
              {
                  "operator": "[]",
                  "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
                  "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
              }
      }

      this.busyGetVente = this.api.post('ligneSortie/getByCriteria', request)
          .subscribe(
              res => {   
                  console.log(" voici le get de data vente ", res);

                  this.totalItem.nombreArticleVendu = res['quantiteTotalPeriode'] || 0;
 
                  //Recuperer la qté entrée et la qté en stock
                  this.totalItem.qteTotaleArticleApprovisionne = res['quantiteApprovisionneParPeriode'] || 0;
                  this.totalItem.qteTotaleArticleDisponible = res['quantiteDisponibleEnStockParPeriode'] || 0;


                  if (res && res['items']) {
                      this.results = res['items'];
                     
                    
                      // this.montantTotalPeriode = _.sumBy(resultItemsNonAnnule, function (o) { return (o.prixTotalAvecRemise * o.quantiteSortie) });
                      this.beneficeVenteByPeriode = res['montantBeneficiePeriode']; //_.sumBy(resultItemsNonAnnule, function (o) { return (o.quantiteSortie * (o.prixTotalAvecRemise - o.prixAchat)) });
                    
                      if(this.beneficeVenteByPeriode < 0)
                      {
                          this.beneficeVenteByPeriodeFormat = this.userService.getFormatMillierInput(-this.beneficeVenteByPeriode);
                      }
                      else
                      {
                          this.beneficeVenteByPeriodeFormat = this.userService.getFormatMillierInput(this.beneficeVenteByPeriode);
                      }
                      
                  } else {
                      this.results = [];
                      this.montantTotalPeriode = 0;
                      this.beneficeVenteByPeriodeFormat = "";
                  }
              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
              }
          );
  }

  searchItem() {

      this.results = [];
      this.ListeReglements = [];
      this.beneficeVenteByPeriode = 0;

      this.getDataVente(this.itemToSearch.produitSelected ? this.itemToSearch.produitSelected.id : null);
      console.log(" le search du bilan en cours ");
  }


  //--------------------------------------------
  //Fin bilan global des ventes                //
  //--------------------------------------------

  searchByPeriode() {
      this.searchItem();
  }


  //------------------------------------------------
  // SEARCH ARTICLE
  //------------------------------------------------

  pageChanged(event: any): void {
      this.currentPage = event.page;
      this.getDataArticle();
  }

  getDataArticle() {

      let search_value = this.itemToSearch.designation;

      var request = {
          user: this.user.proxy,
          recuperationInformationForStatistique : true,
          data: {
              searchString: search_value ? search_value : null,
              boutiqueId: this.userService.getCurrentBoutiqueUser()
          },
          index: (this.currentPage - 1),
          size: this.itemsPerPage ? this.itemsPerPage : null
      }

      this.busyGetArticle = this.api.post('article/getByCriteriaOrdonneParQuantiteDisponible', request)
          .subscribe(
              res => {
                  console.log('liste des articles ',res);
                  if (res && res['items']) {
                      this.ListeArticles = res['items'];
                      this.totalItemsArticle = res['count'] ? res['count'] : 0;

                      this.itemStatistique.coutAchatGlobal = res['montantTotalCoutAchat'] ? res['montantTotalCoutAchat'] : 0;
                      this.itemStatistique.coutVenteGlobal = res['montantTotalCoutVente'] ? res['montantTotalCoutVente'] : 0;
                      this.itemStatistique.qteTotalDisponible = res['quantiteDisponibleEnStockParPeriode'] ? res['quantiteDisponibleEnStockParPeriode'] : 0;

                      
                  } else {
                      this.ListeArticles = [];

                      this.itemStatistique.coutAchatGlobal = 0;
                      this.itemStatistique.coutVenteGlobal = 0;
                      this.itemStatistique.qteTotalDisponible = 0;
                  }
              },
              err => {
              }
          );

  }

  //--------------------------------------------------------
  // FIN SEARCH ARTICLE
  //-------------------------------------------------------

  getExactlyNumberRow(page, index) {
      let num = index + 1;
      if (page > 1) {
          num = ((page - 1) * 10) + (index + 1);
      }
      return num;
  }


  ngOnInit() {

      window.scrollTo(0, 0);
      this.user = this.userService.getCurrentUser();

      this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
          this.storeSelected = currentData;
          if (this.storeSelected && this.storeSelected.id) {
              this.getDataNbreClient();
              this.getDataNombreProduit();
              this.getDataNombreUser();
              this.searchItem();
              this.totalItemsArticle = 0;
              this.currentPage = 1;
              
              this.getDataArticle();
          }
      });
  }

  ngOnDestroy() {

      if (this.subscribeStoreSelected) {
          this.subscribeStoreSelected.unsubscribe();
      }

  }


}
