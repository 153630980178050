import { Component, OnInit, ViewChild, Input,Output, SimpleChanges,EventEmitter } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-historique-troc-avec-sortie-article',
  templateUrl: './main-historique-troc-avec-sortie-article.component.html',
  styleUrls: ['./main-historique-troc-avec-sortie-article.component.css']
})
export class MainHistoriqueTrocAvecSortieArticleComponent implements OnInit {

 
  results: Array<any> = [];
  ListeProduits: Array<any> = [];
  ListeApproToSave: Array<any> = [];
  LigneArticleVendus: Array<any> = [];
  ListeReglements :  Array<any> = [];
  itemDetails: any = {};
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  enumStatutVente : any = {};
  linkPdfFile : String;
  bsModalRefPreview: BsModalRef;
  enumStatutReglement : any = {};
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  sortieIdAfterSearchByCodeImei : number;
  
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
    private daterangepickerOptions: DaterangepickerConfig) {

    this.cancelSave();
    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);
    let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);
    this.user = this.userService.getCurrentUser();
    this.enumStatutVente = enumStatutStorePro;
    this.enumStatutReglement = enumStatutReglementStorePro;

    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut: dateDebutIso, //this.isHistorique ? dateDebutIso : new Date(),
      dateFin: dateFinIso, //this.isHistorique ? dateFinIso : new Date()
    };
    this.oldValueSearch = "";

    const tableauMois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    let libelleMois = "Ce mois de " + tableauMois[new Date().getMonth()];

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Rechercher',

        "customRangeLabel": "Personnaliser",
        "daysOfWeek": [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ],
        "monthNames": [
          "Janvier",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
      },
      alwaysShowCalendars: false,
      ranges: {

        "Aujourd'hui": moment(),
        "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Les 03 derniers jours': [moment().subtract(2, 'day'), moment()],
        'Les 07 derniers jours': [moment().subtract(6, 'day'), moment()],
        'Les 15 derniers jours': [moment().subtract(14, 'day'), moment()],
        'Les 30 derniers jours': [moment().subtract(29, 'day'), moment()],
        'Les 60 derniers jours': [moment().subtract(59, 'day'), moment()],
        'Les 90 derniers jours': [moment().subtract(89, 'day'), moment()],
        [libelleMois]: [this.itemToSearch.dateDebut, this.itemToSearch.dateFin],
      }
    };
  }

  @Input() isHistorique;
  @Input() isReadyOnly;
  @Input() isRefresh;
  @Input() idClient;
  @Output() selectedHistoToUpdate : EventEmitter<any> = new EventEmitter();

  maxDate = new Date();

  selectedItemHisto(item) {
    console.log(" Valeur emise ",item);
    this.selectedHistoToUpdate.emit(item);
  }

  resetDate() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  selectedDate(value: any, datepicker?: any, firstRefresh?: boolean) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    if (!firstRefresh) {
      this.itemToSearch.dateDebut = moment(value.start).format('DD/MM/YYYY');
      this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');
      this.daterange.label = value.label;
    }

    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";

    this.getData();
  }

  ngAfterViewInit() {
    this.resetDate();

    setTimeout(() => {
      this.selectedDate(this.picker, this.daterange, true)
    }, 1000);

    //this.getData();  
  }


  confirmPrintRecu(item) {

    let objToSave = Object.assign({}, item);

    swal({
      title: 'Reçu',
      text: 'Voulez-vous imprimer le reçu ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.getReceuVente(item);
      } else {
      }
    })
  }

  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }

  //Defini une fonction qui verifie si c'est à la date du jour que la facture a été enregistré
  checkIfCanCancelVente(date)
  {
    // console.log("la date de création",date);
    var isPossible = false;
    let dateDuJour = new Date();

    let tabDate = date.split(" ")[0].split("/");
    let dateCreate = new Date(tabDate[2],tabDate[1]-1,tabDate[0]);

    //Verifier la difference en jour
    var dateDebut = moment(dateCreate, "YYYYMMDD");
    var dateFin = moment(dateDuJour, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") == 0) {
      isPossible = true;
    }

    return isPossible;

  }


  getReceuVente(item) {

    var request = {
      user: this.user.proxy,
      symbolDevise : this.userService.getCurrentDevise(),
      data: {
        id: item.id
      }
    }


    this.busyGet = this.api.post('sortie/getReportFacture', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  selectedChangeValue(indiceTab) {

  }


  deleteApproInListe(indice) {

    swal({
      title: 'Suppression approvisionnement',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.ListeApproToSave.splice(indice, 1);
      } else {

      }
    })


  }

  hideModal() {

    if (this.ListeApproToSave && this.ListeApproToSave.length > 0) {
      swal({
        title: 'Fermeture de fenêtre',
        text: 'Des saisies sont en cours, voulez-vous fermer la fenêtre ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
        if (result) {
          this.modalRef.hide();
        }
      })
    }
    else {
      this.modalRef.hide();
    }

  }

  openFormModalModal(template, itemToModified, isDetails) {
    this.cancelSave();
    //this.getDataProduits();

    if (itemToModified) {

      this.itemDetails = {};
      this.itemDetails = Object.assign({}, itemToModified);
      this.itemToSave = Object.assign({}, itemToModified);
      this.itemToSave.dateSortie =  new Date();
      this.itemToSave.montantPaye = 0;
      this.itemToSave.montantTotalVerse = this.itemToSave.montantTotal - (this.itemToSave.resteAPayer || 0)
      this.LigneArticleVendus = [];
      this.ListeReglements = [];
      //Recuperer les lignes 
      if(isDetails)
      {
        this.LigneArticleVendus = this.itemDetails.datasLigne;
      }
      // this.getDataLigneSortie(this.itemDetails.id);
      this.getDataReglement(this.itemDetails.id);
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-appro' }));

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  getData() {

    let search_value = this.itemToSearch.designation;
    
    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientLibelle: search_value ? search_value : null,
        codeImeiArticleTroc : this.itemToSearch.codeImeiToSearch || null,
        clientId : this.idClient || null,
        id: this.sortieIdAfterSearchByCodeImei || null,
        dateSortie: null,
        dateSortieParam: {} 
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    // if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
    //   request.data.dateSortie = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
    //   request.data.dateSortieParam =
    //     {
    //       "operator": "[]",
    //       "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
    //       "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
    //     }
    // }

    this.busyGet = this.api.post('viewSortieArticleTroc/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.isChangeItermPerPage = false;
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );

  }

  getDataLigneSortieSearchByCodeImei() {

    if(!this.itemToSearch.codeImeiToSearch)
    {
      this.sortieIdAfterSearchByCodeImei = null;
      this.getData();
      return;
    }
   
    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        imeiTelephone: this.itemToSearch.codeImeiToSearch || null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    // if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
    //   request.data.dateSortie = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
    //   request.data.dateSortieParam =
    //     {
    //       "operator": "[]",
    //       "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
    //       "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
    //     }
    // }

    this.busyGet = this.api.post('ligneSortie/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.sortieIdAfterSearchByCodeImei = res['items'][0].sortieId;
            this.getData();
          } else {
            this.sortieIdAfterSearchByCodeImei = null;
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  selectProduct = function (item) {
    console.log(item);
    if (!item) return;

    this.itemApproToSave = Object.assign({}, item);
    if (!this.itemApproToSave.quantiteDisponible) {
      this.itemApproToSave.quantiteDisponible = 0;
    }
  };


  getDataReglement(idSortie) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        sortieId : idSortie
      }
    }

    this.busySave = this.api.post('reglement/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici les reglements', res);
          if (res && res['items']) {
            this.ListeReglements = _.orderBy(res['items'], ['id'], ['desc']);
          } else {
            this.ListeReglements = [];
          }
        },
        err => {
        }
      );
  }


  getDataLigneSortie(idSortie) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        sortieId : idSortie
      }
    }

    this.busySave = this.api.post('ligneSortie/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici les lignes sortie', res);
          if (res && res['items']) {
            this.LigneArticleVendus = _.orderBy(res['items'], ['id'], ['desc']);
          } else {
            this.LigneArticleVendus = [];
          }
        },
        err => {
        }
      );
  }


  getDataProduits() {

    var request = {
      user: this.user.proxy,
      "data": {
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busySave = this.api.post('article/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat', res);
          if (res && res['items']) {
            this.ListeProduits = _.orderBy(res['items'], ['libelle'], ['asc']);
          } else {
            this.ListeProduits = [];
          }
        },
        err => {
        }
      );

  }

  confirmSaveReglement(item) {
   
    let objToSave =  Object.assign({}, item);

    if(!objToSave.montantPaye)
    {
      this.notificationService.showMessageError("Erreur","Veuiller renseigner le montant payé.");
      return;
    }

    if((objToSave.montantTotalVerse + objToSave.montantPaye) > objToSave.montantTotal)
    {
      this.notificationService.showMessageError("Erreur","Veuiller vérifier le reste à payer.");
      return;
    }

    let objReglement = {
      clientId  : objToSave.clientId,
      sortieId : objToSave.id,
      modeReglement : "Espèce",
      boutiqueId : this.userService.getCurrentBoutiqueUser(),
      montantPayer : objToSave.montantPaye
    }


    swal({
      title: 'Règlement facture',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemReglement(objReglement);
      } else {
        console.log("bnjr");
      }
    })
  }

  getResteAPayer()
  {
    if(this.itemToSave.montantPaye)
    {
      if(this.itemToSave.resteAPayer > this.itemToSave.montantPaye)
      {
        this.itemToSave.resteAPayer = this.itemDetails.resteAPayer - this.itemToSave.montantPaye;
      }
      else
      {
        this.notificationService.showMessageError("Erreur","Veuillez vérifier le reste à payer.");
        this.itemToSave.resteAPayer = this.itemDetails.resteAPayer;
        return;
      }
    }
    else
    {
      this.itemToSave.resteAPayer = this.itemDetails.resteAPayer;
    }
  }


  cancelSave() {
    this.itemToSave = {};
    this.itemDetails = {};
  }


  confirmAnnulerVente(item) {
   
    let objToSave =  Object.assign({}, item);
    objToSave.statut = enumStatutStorePro.ANNULE;

    swal({
      title: 'Annulation vente',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemAnnulerVente(objToSave);
      } else {
        console.log("bnjr");
      }
    })
  }

  saveItemReglement(obj) {

    var request = {
      user: this.user.proxy,
      datas: [obj]
    };

    this.busySave = this.api.post('reglement/saveReglementFacture', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Règlement", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.modalRef.hide();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  
  saveItemAnnulerVente(obj) {
    
    var request = {
      user: this.user.proxy,
      datas: [obj]
    };

    this.busySave = this.api.post('sortie/cancelVente', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Annulation vente", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
       this.getData();
      } 
    });

  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


  ngOnChanges(changes: SimpleChanges) {
    console.log('data', changes);
    if(changes['isHistorique'] && !changes['isHistorique'].currentValue)
    {
      this.itemToSearch = {
        dateDebut:  new Date(),
        dateFin:  new Date()
      };
    }

    if(changes['isRefresh'] && changes['isRefresh'].currentValue)
    {
      this.getData();
    }
    
    if(changes['idClient'] && changes['idClient'].currentValue)
    {
      this.getData();
    }
    

  }


}
