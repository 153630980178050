import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-reparation',
  templateUrl: './main-reparation.component.html',
  styleUrls: ['./main-reparation.component.css']
})
export class MainReparationComponent implements OnInit {


  results: Array<any> = [];
  ListeTypeProduits: Array<any> = [];
  ListeReglements : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  itemToSearchTechnicien: any = {};
  currentTechnicien: any = {};
  currentReparateur: any = {};
  itemToSearchReparateur: any = {};
  itemDetails : any = {};
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemStepWizard: number;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  imageDisplay: any = {};
  daterange: any = {};
  currentItemImage: any = {};
  itemToSearch: any;
  modalRef: BsModalRef;
  stationSelected: any = {};
  conditionnementToSave: any = {};
  itemProduct: any = {};
  isNewItem: boolean;
  suscribStation: Subscription;
  oldValueSearch: String;
  isChangeItermPerPage: boolean = false;
  enumStatut: any = {};
  currentClient: any = {};
  enumStatutReglement : any = {};
  linkPdfFile : String;
  bsModalRefPreview : BsModalRef;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {
      dateDebut: moment().subtract(29, 'day'),
      dateFin: moment()
    };
    this.enumStatutReglement = enumStatutReglementStorePro;
    this.itemStepWizard = 1;
    this.oldValueSearch = "";

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Rechercher',

        "customRangeLabel": "Personnaliser",
        "daysOfWeek": [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ],
        "monthNames": [
          "Janvier",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
      },
      alwaysShowCalendars: false,
      ranges: {

        "Aujourd'hui": moment(),
        "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Les 03 derniers jours': [moment().subtract(2, 'day'), moment()],
        'Les 07 derniers jours': [moment().subtract(6, 'day'), moment()],
        'Les 15 derniers jours': [moment().subtract(14, 'day'), moment()],
        'Les 30 derniers jours': [moment().subtract(29, 'day'), moment()],
        'Les 60 derniers jours': [moment().subtract(59, 'day'), moment()],
        'Les 90 derniers jours': [moment().subtract(89, 'day'), moment()]
      }
    };

  }
  @Input() isRefresh;
  maxDate = new Date();

  resetDate() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified, isDetails?: boolean) {

    this.cancelSave(); 

    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);
      this.itemToSave.article = {
        libelle : this.itemToSave.designation,
        modele :  this.itemToSave.modele,
        code : this.itemToSave.modele
      };

      this.itemProduct = this.itemToSave.article;

      console.log(" itemToModified ",this.itemToSave);
      if(isDetails)
      {
        this.itemDetails = Object.assign({}, itemToModified);
        //Recuperer les règlements
        this.getDataReglement(this.itemDetails.id);
      }
      
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom' }));
  
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }


  selectedDate(value: any, datepicker?: any, firstRefresh?: boolean) {

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.itemToSearch.dateDebut = value.start; //moment(value.start).format('DD/MM/YYYY');
    this.itemToSearch.dateFin = value.end; //moment(value.end).format('DD/MM/YYYY');
    this.daterange.label = value.label;
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";

    this.getData();
  }


  selectedClient(client) {
    console.log(" client ",client);
    if (client) {
      this.currentClient = client;
      this.itemToSearch.clientSearch = client.libelle;
      this.itemToSearch.clientContactSearch = client.contact;
      this.itemToSearch.isDisplayTable = false;
    }
  }

  selectedTechnicien(technicien) {
    if (technicien) {
      this.currentTechnicien = technicien;
      this.itemToSearchTechnicien.clientSearch = technicien.libelle;
      this.itemToSearchTechnicien.clientContactSearch = technicien.contact;
    }
  }

  selectedReparateur(reparateur) {
    if (reparateur) {
      this.currentReparateur = reparateur;
      this.itemToSearchReparateur.clientSearch = reparateur.libelle;
      this.itemToSearchReparateur.clientContactSearch = reparateur.contact;
    }
  }

  selectProduct(item) {
    console.log('le item article selectionné a la reparation', item);
    if (item) {
      this.itemProduct = Object.assign({}, item);
      this.itemProduct.libelle = item.libelleSearch || item.libelle;
      this.itemProduct.code = item.numeroSearch || item.code;
    }
    else {
      this.itemProduct.libelle = item.libelleSearch;
      this.itemProduct.code = item.numeroSearch;
    }
  };


  getData() {

    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;

    if (search_value && this.oldValueSearch) {
      if (search_value != this.oldValueSearch) {
        this.currentPage = 1;
      }
    }

    if (search_value) {
      search_value = search_value.trim();

      if (search_value.length > 0 && search_value.length < 3) {
        if (!this.isChangeItermPerPage) {
          return;
        }
      }
      else {
        if (search_value == this.oldValueSearch) {
          return;
        }
        this.oldValueSearch = search_value;
        //this.currentPage = 1;
      }
    }
    else {
      if (this.oldValueSearch == "" && isSearch) {
        return;
      }

      this.oldValueSearch = "";
    }

    var request = {
      user: this.user.proxy,
      data: {
        clientLibelle: search_value ? search_value : null,
        clientContact : this.itemToSearch.contactClient || null,
        codeImeiAppareil : this.itemToSearch.imeiArticle || null,
        dateDepot: null,
        dateDepotParam: {},
        boutiqueId: this.userService.getCurrentBoutiqueUser()
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    // if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
    //   request.data.dateDepot = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00';
    //   request.data.dateDepotParam =
    //       {
    //           "operator": "[]",
    //           "start":  moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
    //           "end":  moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
    //       }
    // }

    this.busyGet = this.api.post('reparation/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
        }
      );
  }


  confirmPrintRecu(item)
  {
    swal({
      title: "Impression du reçu" ,
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.getReportRecuReparation(item.id);
      } else {
      }
    })
  }


  getReportRecuReparation(idReparation) {

   
    var request = {
      user: this.user.proxy,
      data: {
        id: idReparation
      }
    }

    this.busyGet = this.api.post('reparation/getReportRecuReparation', request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
        }
      );
  }


  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }
  

  getDataReglement(idReparation) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        idReparation : idReparation
      }
    }

    this.busySave = this.api.post('reglementRepartition/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici les reglements', res);
          if (res && res['items']) {
            this.ListeReglements = _.orderBy(res['items'], ['id'], ['desc']);
          } else {
            this.ListeReglements = [];
          }
        },
        err => {
        }
      );
  }


  confirmSaveItem(obj) {

    console.log("item ", obj);
    let item = Object.assign({}, obj);

    if (!item || !item.dateDepot) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la date de dépôt.")
      return;
    }

    if (!item || !item.dateRecuperation) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la date de retrait.")
      return;
    }

    if (!item.montantReparation) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le montant total de la reparation.")
      return;
    }

    if (!this.currentClient && !this.itemToSearch.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les informations du client.")
      return;
    }

    if (item.montantPaye && parseFloat(item.montantPaye) > parseFloat(item.montantReparation)) {
      this.notificationService.showMessageError("Information", "Veuillez vérifier le montant de payé.")
      return;
    }

    if (!this.currentTechnicien && !this.itemToSearchTechnicien.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les informations du technicien.")
      return;
    }

    // if (!this.currentReparateur && !this.itemToSearchReparateur.libelle) {
    //   this.notificationService.showMessageError("Information", "Veuillez renseigner les informations du reparateur.")
    //   return;
    // }


    if (!this.itemProduct.libelle || !this.itemProduct.code) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la designation et le nom du produit.")
      return;
    }


    if (!item.panne) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la panne.")
      return;
    }

    if(item.montantPaye)
    {
      item.resteAPayer = item.montantReparation - item.montantPaye;
    }
    else
    {
      item.resteAPayer = item.montantReparation;
    }

    if(item.resteAPayer > 0)
    {
      item.statutReglement = enumStatutStorePro.EN_COURS;
    }
    else
    {
      item.statutReglement = enumStatutStorePro.SOLDE;
    }

    let dateFormat = moment(item.dateDepot).format("DD/MM/YYYY");
    if (dateFormat == "Invalid date") {
      item.dateDepot = item.dateDepot + " 00:00:00";
    }
    else {
      item.dateDepot = dateFormat + " 00:00:00";
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    item.statut = enumStatutStorePro.VALIDE;
    item.etapeReparation = !item.id ? enumStatutStorePro.RECU_AU_MAGASIN : item.etapeReparation;
    item.designation = this.itemProduct.libelle;
    item.modele = this.itemProduct.code;
    item.codeImeiAppareil = this.itemToSave.codeImeiAppareil || null;

    if (!this.currentClient || !this.currentClient.id) {
      if (this.itemToSearch.clientContactSearch && this.itemToSearch.clientSearch) {
        item.clientDto = {
          contact: this.itemToSearch.clientContactSearch,
          libelle: this.itemToSearch.clientSearch.toUpperCase(),
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          searchString: this.itemToSearch.clientSearch + ' ' + this.itemToSearch.clientContactSearch
        };
        item.idClient = null;
      }
    }
    else {
      item.idClient = this.currentClient.id;
    }

    if (!this.currentReparateur || !this.currentReparateur.id) {
      if (this.itemToSearchReparateur.clientContactSearch && this.itemToSearchReparateur.clientSearch) {
        item.reparateurDto = {
          contact: this.itemToSearchReparateur.clientContactSearch,
          libelle: this.itemToSearchReparateur.clientSearch.toUpperCase(),
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          searchString: this.itemToSearchReparateur.clientSearch + ' ' + this.itemToSearchReparateur.clientContactSearch
        };
        item.idReparateur = null;
      }
     
    }
    else {
      item.idReparateur = this.currentReparateur.id;
    }

    if (!this.currentTechnicien || !this.currentTechnicien.id) {
      if (this.itemToSearchTechnicien.clientContactSearch && this.itemToSearchTechnicien.clientSearch) {
        item.technicienDto = {
          contact: this.itemToSearchTechnicien.clientContactSearch,
          libelle: this.itemToSearchTechnicien.clientSearch.toUpperCase(),
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          searchString: this.itemToSearchTechnicien.clientSearch + ' ' + this.itemToSearchTechnicien.clientContactSearch
        };
        item.idTechnicien = null;
      }

      
    }
    else {
      item.idTechnicien = this.currentTechnicien.id;
    }

    if (item.dateRecuperation) {
      let dateFormat = moment(item.dateRecuperation).format("DD/MM/YYYY");
      if (dateFormat == "Invalid date") {
        item.dateRecuperation = item.dateRecuperation + " 00:00:00";
      }
      else {
        item.dateRecuperation = dateFormat + " 00:00:00";
      }
    }

    swal({
      title: (!obj.id) ? "Enregistrement d'une reparation" : "Modification d'une reparation",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item,false,true);
      } else {
      }
    })
  }

  
  confirmSaveItemChangerStatut(obj) {

    console.log("item ", obj);
    let item = Object.assign({}, obj);

    if (!item || !item.etapeReparation) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner l'étape de la réparation'.")
      return;
    }

    if (!this.currentReparateur || !this.currentReparateur.id) {
      if (this.itemToSearchReparateur.clientContactSearch && this.itemToSearchReparateur.clientSearch) {
        item.reparateurDto = {
          contact: this.itemToSearchReparateur.clientContactSearch,
          libelle: this.itemToSearchReparateur.clientSearch.toUpperCase(),
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          searchString: this.itemToSearchReparateur.clientSearch + ' ' + this.itemToSearchReparateur.clientContactSearch
        };
        item.idReparateur = null;
      }
     
    }
    else {
      item.idReparateur = this.currentReparateur.id;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    
    swal({
      title: "Changement de statut",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  confirmSaveItemVersement(obj) {

    console.log("item ", obj);
    let item = Object.assign({}, obj);

    if (!item || !item.dateDepot) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la date de dépôt.")
      return;
    }

    if (!item.montantReparation) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le montant total de la reparation.")
      return;
    }

    if (!item.montantPayeCeJour) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le montant payé ce jour.")
      return;
    }

    if(item.montantPayeCeJour)
    {
      item.resteAPayer = (item.resteAPayer  || 0) - parseFloat(item.montantPayeCeJour);
      item.montantPaye = (item.montantPaye || 0) + parseFloat(item.montantPayeCeJour);
    }

    item.montantPayeVersementReparation = item.montantPayeCeJour;
    item.isReglementReparation = true;

    if(item.resteAPayer > 0)
    {
      item.statutReglement = enumStatutStorePro.EN_COURS;
    }
    else
    {
      item.statutReglement = enumStatutStorePro.SOLDE;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    
    swal({
      title: (!obj.id) ? "Enregistrement d'un versement" : "Modification d'un versement",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }


  //Defini une fonction qui verifie si c'est à la date du jour que la facture a été enregistré
  checkIfCanCancelVente(date) {
    //console.log("la date de création",date);
    var isPossible = false;
    let dateDuJour = new Date();

    let tabDate = date.split(" ")[0].split("/");
    let dateCreate = new Date(tabDate[2], tabDate[1] - 1, tabDate[0]);

    //Verifier la difference en jour
    var dateDebut = moment(dateCreate, "YYYYMMDD");
    var dateFin = moment(dateDuJour, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") == 0) {
      isPossible = true;
    }

    return isPossible;

  }

  confirmCancelItem(obj, isActive) {

    swal({
      title: 'Annulation une reparation',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)", 
    }).then((result) => {
      if (result) {
        let item = Object.assign({}, obj);

        item.statut = enumStatutStorePro.ANNULE;
        item.etapeReparation = enumStatutStorePro.REPARATION_ANNULE;
        item.statutReglement = enumStatutStorePro.ANNULE;
        item.isCancelAnnulation = true;
        this.saveItem(item, true);

      }
    })
  }


  confirmCancelItemReglement(obj, isActive) {

    swal({
      title: 'Annulation un reglement',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)", 
    }).then((result) => {
      if (result) {
        let item = Object.assign({}, obj);

        item.statut = enumStatutStorePro.ANNULE;
        item.isForAnnulationReglement = true;
        this.saveItemReglement(item, true);

      }
    })
  }

  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item, isCancel?: boolean,isPrintRecu ? : boolean) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busyGet = this.api.post('reparation/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Réparation", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;

            if (!isCancel) {
              this.hideModal();
            }

            if(isPrintRecu)
            {
              this.confirmPrintRecu(res['items'][0].id);
            }

            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  saveItemReglement(item, isCancel?: boolean) {

    var request = {
      user: this.user.proxy, 
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('reglementRepartition/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Règlement", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;

              this.hideModal(); 
         

            this.cancelSave();
            this.getDataReglement(res['items'][0].idReparation);
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj, isActive) {

    var request = {
      user: this.user.proxy,
      datas: [{
        id: obj.id,
      }]
    }

    this.busySave = this.api.post('depense/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1;
        this.getData();
      }
    });
  }


  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


  ngAfterViewInit() {
    this.resetDate();
  }



}
