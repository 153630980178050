import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from "../../user/user.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormReinitPasswordComponent } from '../../../+components/form-reinit-password/form-reinit-password.component';
import { Api } from '../../utils/api/api';
const swal = require('sweetalert2');
import * as _ from "lodash";
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  user: any;
  bsModalRef: BsModalRef;
  itemToSave: any = {};
  currentLogo: any;
  imageDisplay: any;
  ListeStations: Array<any> = [];
  itemToSearch: any = {};
  currentValue: any = {};
  newStationCreate: any = {};
  isRealOnlySelectedStation: boolean = false;
  busyLogout: Subscription;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private router: Router, private userService: UserService, private modalService: BsModalService, private api: Api) {
    this.user = this.userService.getCurrentUser();
  }

  updateInformationTenant(item: any) {
    console.log('la station', item);
    this.currentValue = item;
    this.userService.updateStationInformation(this.currentValue);
  }


  ngOnInit() {
    this.user = this.userService.getCurrentUser();
  }

  openFormModalReinitMotDePasse(template, itemToModified) {

    if (itemToModified) {
      this.itemToSave = Object.assign({}, this.user);
    }
    this.bsModalRef = this.modalService.show(template);
  }


  truncateValue(item: any, nbre) {
    if (!item) return;

    let regex = /(&nbsp;|<([^>]+)>)/ig;

    //RETIRER LES CARACTERES HTML
    item = item.replace(regex, "");

    if (item.length > nbre) {
      item = item.substring(0, nbre).trim() + "" + " ...";
    }
    return item;
  }


  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }

  updateProfilInformation() {
    this.router.navigate(['/update-profil-user']);
  }

  openChangePassword() {


    const initialState = {
      title: "Changer votre mot de passe.",
      config: this.config
    };
    this.bsModalRef = this.modalService.show(FormReinitPasswordComponent, Object.assign({}, this.config, { class: 'gray modal-sm modal-custom-reinit' }));
    this.bsModalRef.content.title = 'Changer votre mot de passe';
    this.bsModalRef.content.currentUser = this.user;
    /*this.bsModalRef.content.login_default = this.user.Login;
    this.bsModalRef.content.Identite = this.user.Nom +" "+this.user.Prenoms;
    this.bsModalRef.content.currentIdUser = this.user.Id;
    this.bsModalRef.content.currentUser = this.user; */


  }


  logout() {
    swal({
      title: 'Déconnexion',
      text: 'Voulez-vous vous déconnecter de l\'application ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deconnection();
        localStorage.removeItem("yz-backoffice-app-boutique-store-pro");
        this.router.navigate(['/auth/login']);
        // console.log("la deconnexion"); 
        // return;

        
      } else {
        // console.log("bnjr");
      }
    })

  }


  deconnection() {
    var request = {
      user :this.user.id,
      "data":
        {
          "id": this.user.id,
          "action": "Déconnexion",
          "source": "Web",
          "referenceSource": navigator.userAgent
        }
    }

    this.api.post('user/deconnexion', request)
      .subscribe(
        res => {
          if (!res['hasError']) {
            localStorage.removeItem("yz-backoffice-app-boutique-store-pro");
            this.router.navigate(['/auth/login']);
          }
          else {
            localStorage.removeItem("yz-backoffice-app-boutique-store-pro");
            this.router.navigate(['/auth/login']);
            //this.notificationService.showMessageError("Connexion", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        },
        err => {
          localStorage.removeItem("yz-backoffice-app-boutique-store-pro");
          this.router.navigate(['/auth/login']);
        }
      );

  }


}
