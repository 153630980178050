import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');


@Component({
  selector: 'app-main-bilan-ventes-global',
  templateUrl: './main-bilan-ventes-global.component.html',
  styleUrls: ['./main-bilan-ventes-global.component.css']
})
export class MainBilanVentesGlobalComponent implements OnInit {

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  results: Array<any> = [];
  ListeProduits: Array<any> = [];
  ListeClients: Array<any> = [];
  ListeReglements: Array<any> = [];
  busyGetVente: Subscription;
  busyGetReglement: Subscription;
  busyGetDepense: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  montantTotalPeriode: any = 0;
  quantiteTotalPeriode: any = 0;
  beneficeVenteByPeriode: any = 0;
  montantTotalReglementPeriode: any = 0;

  totalItemsReglement: number = 0;
  itemsPerPageReglement: number = 5;
  currentPageReglement: number = 1;
  montantTotalEncaissePeriode: any = 0;
  montantTotalDepensesPeriode: any = 0;
  linkPdfFile: String;
  bsModalRefPreview: BsModalRef;
  enumStatutReglement: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  // @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
  /*private daterangepickerOptions: DaterangepickerConfig*/) {

    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);

    let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);

    this.enumStatutReglement = enumStatutReglementStorePro;
    this.user = this.userService.getCurrentUser();

    this.itemsPerPage = 5;
    this.itemToSearch = {
      dateDebut: new Date(), // dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin: new Date() // dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };
    this.oldValueSearch = "";

    const tableauMois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    let libelleMois = "Ce mois de " + tableauMois[new Date().getMonth()];

  }

  maxDate = new Date();


  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }

  selectedChangeValue(indiceTab) {

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getDataVente();
  }

  pageChangedReglement(event: any): void {
    this.currentPageReglement = event.page;
    this.getDataReglement();
  }

  getDataVente(idArticle?, idClient?) {

    var dateDebut = moment(this.itemToSearch.dateDebut, "YYYYMMDD");
    var dateFin = moment(this.itemToSearch.dateFin, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") < 0) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la période sélectionnée.");
      this.results = [];
      this.montantTotalPeriode = 0;
      this.quantiteTotalPeriode = 0;
      this.beneficeVenteByPeriode = 0;
      return;
    }

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        // isGetForStatistique: true,
        statut: enumStatutStorePro.VALIDE,
        dateSortie: null,
        dateSortieParam: {}
      },
      // index: (this.currentPage - 1),
      // size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateSortie = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateSortieParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGetVente = this.api.post('ligneSortie/getByCriteria', request)
      .subscribe(
        res => {
          console.log('retour ligne service ',res);
          if (res && res['items']) {
            this.isChangeItermPerPage = false;
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;

            let resultItemsNonAnnule = _.filter(this.results, function (o) { return o.statut == enumStatutReglementStorePro.Valide; });
            this.quantiteTotalPeriode = _.sumBy(resultItemsNonAnnule, function (o) { return (o.quantiteSortie ) });

            console.log("  this.montantTotalPeriode ppppp", this.montantTotalPeriode);
            
            this.montantTotalPeriode = _.sumBy(resultItemsNonAnnule, function (o) { return (o.prixTotalAvecRemise * o.quantiteSortie ) });
            this.beneficeVenteByPeriode = _.sumBy(resultItemsNonAnnule, function (o) { return (o.quantiteSortie * (o.prixTotalAvecRemise - o.prixAchat) ) });

          } else {
            this.results = [];
            this.montantTotalPeriode = 0;
            this.quantiteTotalPeriode = 0;
            this.beneficeVenteByPeriode = 0;
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }


  getDataReglement() {

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        dateReglement: null,
        dateReglementParam: {}
      },
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateReglement = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateReglementParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGetReglement = this.api.post('reglement/getByCriteria', request)
      .subscribe(
        res => {
          console.log("versement ", res);
          if (res && res['items']) {

            this.ListeReglements = res['items'];
            this.totalItemsReglement = res['count'] ? res['count'] : 0;

          } else {

            this.ListeReglements = [];
            this.montantTotalReglementPeriode = 0;
            this.itemToSearch.montantReglement = 0;

          }

          let resultItemsNonAnnule = _.filter(this.ListeReglements, function (o) { return o.statut == enumStatutReglementStorePro.Valide; });

          let montantReg = _.sumBy(resultItemsNonAnnule, function (o) { return o.montantTotalPayerSortie; });
          let montantTotalPaye = _.sumBy(resultItemsNonAnnule, function (o) { return o.montantPayer; });

          this.itemToSearch.montantReglement = montantReg;
          this.montantTotalReglementPeriode = montantTotalPaye;

          this.getMontantTotalEnCaisse(this.montantTotalReglementPeriode,this.itemToSearch.montantDepense);
          //this.itemToSearch.montantTotalEnCaisse = (montantTotalPaye || 0) - (this.itemToSearch.montantDepense || 0 );

          console.log(" this.itemToSearch.montantTotalEnCaisse ", this.itemToSearch.montantTotalEnCaisse);
          //this.montantTotalReglementPeriode = _.sumBy(this.ListeReglements, function(o) { return o.montantPayer; });

          // console.log(" montantReg ",montantReg);
          // console.log(" montantTotalPaye ",montantTotalPaye);

          // this.itemToSearch.montantReglement = _.sumBy(this.ListeReglements, function(o) { return o.montantTotalPayerSortie; });
          // this.montantTotalReglementPeriode = _.sumBy(this.ListeReglements, function(o) { return o.montantPayer; });

          //this.getMontantTotalEnCaisse(this.montantTotalReglementPeriode,this.itemToSearch.montantDepense);

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  getMontantTotalEnCaisse(montantReglement, montantDepense) {
    
    this.itemToSearch.montantTotalEnCaisse = (montantReglement || 0) - (montantDepense || 0);

    console.log(" this.itemToSearch.montantTotalEnCaisse ",this.itemToSearch.montantTotalEnCaisse);

    if (this.itemToSearch.montantTotalEnCaisse) {
      this.itemToSearch.montantTotalEnCaisse = this.userService.getFormatMillierInput(this.itemToSearch.montantTotalEnCaisse);
    }

  }

  getMontantTotalDepenses() {

    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        isGetForStatistique: true,
        statut: enumStatutStorePro.VALIDE,
        dateDepense: null,
        dateDepenseParam: {}
      }
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateDepense = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateDepenseParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGetDepense = this.api.post('depense/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.itemToSearch.montantDepense = res['montantTotalPeriode'];
            this.montantTotalDepensesPeriode = res['montantTotalPeriode'] ? this.userService.getFormatMillierInput(res['montantTotalPeriode']) : 0;
          } else {
            this.montantTotalDepensesPeriode = 0;
          }

          this.getMontantTotalEnCaisse(this.montantTotalReglementPeriode,this.itemToSearch.montantDepense);

        },
        err => {
        }
      );

  }

  printBilan() {

    var dateDebut = moment(this.itemToSearch.dateDebut, "YYYYMMDD");
    var dateFin = moment(this.itemToSearch.dateFin, "YYYYMMDD");
    //Verifier si la periode selectionnée est valable
    if (dateFin.diff(dateDebut, "days") < 0) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la période sélectionnée.");
      this.results = [];
      this.montantTotalPeriode = 0;
      this.quantiteTotalPeriode = 0;
      this.beneficeVenteByPeriode = 0;
      return;
    }

    // if(this.results && this.results.length == 0 && this.ListeReglements.length == 0)
    // {
    //   this.notificationService.showMessageError("Erreur", "Aucune ligne à exporter.");
    //   return;
    // }


    this.itemToSearch.dateDebutSearch = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY");
    this.itemToSearch.dateFinSearch = moment(this.itemToSearch.dateFin).format("DD/MM/YYYY");

    var request = {
      user: this.user.proxy,
      symbolDevise: this.userService.getCurrentDevise(),
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        isGetForStatistique: true,
        dateSortie: null,
        dateSortieParam: {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateSortie = this.itemToSearch.dateDebutSearch + ' 00:00:00';
      request.data.dateSortieParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebutSearch, // moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFinSearch //moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGetVente = this.api.post('ligneSortie/getReportBilanGlobal', request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }



  searchItem() {
    this.currentPage = 1;
    this.currentPageReglement = 1;
    this.totalItems = 0;
    this.totalItemsReglement = 0;
    this.itemToSearch.montantTotalEnCaisse = 0;
    this.itemToSearch.montantDepense = 0;
    this.itemToSearch.montantReglement = 0;
    this.results = [];
    this.ListeReglements = [];
    this.montantTotalPeriode = 0;
    this.montantTotalReglementPeriode = 0;
    this.beneficeVenteByPeriode = 0;

    this.getDataVente();
    this.getDataReglement();
    this.getMontantTotalDepenses();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 5) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);


    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1;
        this.getDataVente();
        this.getDataReglement();
        this.getMontantTotalDepenses();
      }
    });
  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }



}
