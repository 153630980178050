import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../shared/utils/notification.service';
import { Api } from '../../../shared/utils/api/api';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { BsModalRef } from 'ngx-bootstrap';
import { UserService } from '../../../shared/user';
import { Router } from '@angular/router';
const swal = require('sweetalert2');

@Component({
  selector: 'app-form-reconnect',
  templateUrl: './form-reconnect.component.html',
  styleUrls: ['./form-reconnect.component.css']
})
export class FormReconnectComponent implements OnInit {

  busyConnecte : Subscription;
  user : any = {};
  itemToSave : any = {};
  currentUser : any = {};

  constructor(public router: Router,private notificationService : NotificationService,private api:Api,private utilities : UtilitiesService,public bsModalRef: BsModalRef,private userService: UserService) { 
    //this.user = this.userService.getCurrentUser();

    setTimeout(() => {
      this.user  = Object.assign({},this.currentUser);
    }, 700);

  }
 

  login(item)
  {
    console.log("le item",item);
    item.showLoader = true;
    item.login = this.user.login;
    if(!item  || !item.password)
    {
      item.showLoader = false;
      this.notificationService.showMessageError("Erreur","Veuillez renseigner les champs obligatoires.");
      return;
    }
    
    var request = {
      //user : "1",
      "data":
        {
          "login": item.login,
          "password": item.password,
          "action" : "Connexion",
          "source":"Web",
          "referenceSource":  navigator.userAgent 
        } 
     }

     this.busyConnecte = this.api.post('user/login', request)
          .subscribe(
              res => {
                  console.log('get user',res); 
                  item.showLoader = false;
                  if(!res['hasError'] && res['items'])
                  {
                    var currentUser = res['items'][0];
                    //localStorage.setItem("yz-backoffice-app-boutique-shell",JSON.stringify(currentUser));

                    if(currentUser && currentUser.fonctionnalites && currentUser.fonctionnalites.length > 0)
                    {
                      currentUser.fonctionnalites.push({code : "DASH",libelle : 'DASHBORD'});
                    }

                    localStorage.setItem("yz-backoffice-app-boutique-shell",JSON.stringify(res['items'][0]));
                    this.bsModalRef.hide();
                  }
                  else
                  {
                    this.notificationService.showMessageError("Connexion", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                  }
              },
              err => {
                  console.log("Error occured G",err);
                  item.showLoader = false;
              }
          );

    }

    closeSession()
    {
    
        swal({
          title: 'Fermerture de session',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#f1444e",
          cancelButtonColor: "rgb(154, 154, 153)",
        }).then((result) => {
          if (result) {
            document.location.reload(true);
          } else {
            //console.log("bnjr");
          }
        })

    }

    ngOnInit() {
    }

}
