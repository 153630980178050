import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "../../user/user.service";

@Injectable()
export class LoggedInGuardService implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable <boolean>|Promise<boolean>|boolean {
    const loggedIn = this.userService.isLoggedIn(); 
    // let user = this.userService.getCurrentUser();
    console.log("login",loggedIn);
    if (!loggedIn) {
      this.router.navigate(['/auth/login']); 
      return false;
    }

    // if(user && user.fonctionnalites)
    // {
    //   console.log("user",user);
      
    //   if(route.data && route.data.permission)
    //   {
    //     let code1 = null;
    //     let code2 = null;

    //     if(route.data.permission)
    //     {
    //       code1 = route.data.permission[0]
    //     }
    //     if(route.data.permission.length>1)
    //     {
    //       code2 = route.data.permission[1]
    //     }


    //     const isPermit = user.fonctionnalites.some(role=>{
    //       return (role.code == code1 || (code2 && (role.code == code2)) ) 
    //     })

    //     if (route.data.permission && route.data.permission.length>0 && isPermit ) {
    //       return true;
    //     } else {
    //       this.router.navigate(['/home']);
    //       return false;
    //     }

    //   }
    //   else
    //   {
    //     this.router.navigate(['/home']);
    //     return false;
    //   }
    
    // }



    return loggedIn; 
  }
}
