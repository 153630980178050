import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-fournisseur-credit-pour-tout-le-monde',
  templateUrl: './main-fournisseur-credit-pour-tout-le-monde.component.html',
  styleUrls: ['./main-fournisseur-credit-pour-tout-le-monde.component.css']
})
export class MainFournisseurCreditPourToutLeMondeComponent implements OnInit {


  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  itemStatistique : any = {};
  
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  oldValueSearch : String;
  isChangeItermPerPage : boolean = false;
  enumStatut : any = {};

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {};
    this.oldValueSearch = "";  
  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
   
    if (itemToModified) {
      console.log("le item to modified ",itemToModified);
      this.itemToSave = Object.assign({}, itemToModified);
     }
    
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom' }));
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }


  getData() {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId : this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busyGet = this.api.post('fournisseurVersement/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false; 
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;

             //Recuperer la liste des paiements non annulé
             let resultItemsValide = _.filter(this.results, function(o) { return (o.statut == enumStatutReglementStorePro.Valide)});

             //Recuperer les montant par lodash
             this.itemStatistique.totalAPayer = _.sumBy(resultItemsValide, function(o) { return (o.totalAPayer ); });
             this.itemStatistique.totalPaye = _.sumBy(resultItemsValide, function(o) { return (o.totalPaye); });
             this.itemStatistique.resteAPayer = this.itemStatistique.totalAPayer - this.itemStatistique.totalPaye;

          } else {
            this.results = [];
            this.itemStatistique.totalAPayer = 0;
            this.itemStatistique.totalPaye = 0;

            this.itemStatistique.resteAPayer = 0;
          }
        },
        err => {
        }
      );

  }


  confirmSaveItem(obj) {
    console.log("le object ",obj);
    let item =  Object.assign({}, obj);

    if (!item || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé du client.")
      return;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    
    if(!item.statut)
    {
      item.statut = this.enumStatut.ACTIF
    }

    swal({
      title: (!obj.id) ? "Enregistrement d'un fournisseur" : "Modification d'un fournisseur",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }


  confirmSaveItemEncaissement(obj)
  {
    let item =  Object.assign({}, obj);

    if (!item || !item.dateVersement) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la date de versement.")
      return;
    }

    if (!item.observations) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner l'observation.")
      return;
    }

    if (!item || !item.totalAPayer) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le montant total à payer.")
      return;
    }

    if(!item.totalPaye && item.totalAPayer < item.totalPaye)
    {
      this.notificationService.showMessageError("Information", "Veuillez vérifier le montant versé et le total à payer.")
      return;
    }
  

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
    item.id = 0;
    item.fournisseurId = obj.id;
    
    item.resteAPayer  = (item.totalAPayer || 0) - (item.totalPaye || 0);
    item.statut = 'VALIDE'

      //Formater la date d'approvisionnement
      let dateFormatOperation = moment(obj.dateReglement).format("DD/MM/YYYY");
      if (dateFormatOperation == "Invalid date") {
        dateFormatOperation = obj.dateReglement + " 00:00:00";
      }
      else {
        dateFormatOperation = dateFormatOperation + " 00:00:00";
      }
      
      item.dateReglement = dateFormatOperation;
      
    swal({
      title: "Enregistrement d'un encaissement",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemEncaissement(item);
      } else {
      }
    })
  }



  confirmSaveItemVersement(obj)
  {
    let item =  Object.assign({}, obj);

    if (!item || !item.dateVersement) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner la date de versement.")
      return;
    }

    if (!item || !item.totalAPayer) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le montant total à payer.")
      return;
    }

    if(!item.totalPaye && item.totalAPayer < item.totalPaye)
    {
      this.notificationService.showMessageError("Information", "Veuillez vérifier le montant versé et le total à payer.")
      return;
    }
  

    if (!item.observations) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner l'observation.")
      return;
    }

    item.boutiqueId = this.userService.getCurrentBoutiqueUser();
   
    item.id = 0;
    item.fournisseurId = obj.id;
    
    item.resteAPayer  = (item.totalAPayer || 0) - (item.totalPaye || 0);
    item.totalPaye = obj.totalPayeCeJour;
    item.totalAPayer = 0;
    item.statut = 'VALIDE';

     //Formater la date d'approvisionnement
     let dateFormatOperation = moment(obj.dateReglement).format("DD/MM/YYYY");
     if (dateFormatOperation == "Invalid date") {
       dateFormatOperation = obj.dateReglement + " 00:00:00";
     }
     else {
       dateFormatOperation = dateFormatOperation + " 00:00:00";
     }

     item.dateReglement = dateFormatOperation;

    swal({
      title: "Enregistrement d'un versement",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemEncaissement(item);
      } else {
      }
    })
  }


  confirmSuspendreClient(obj) {

    let item =  Object.assign({}, obj);

    if (!item || !item.libelle) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé du client.")
      return;
    }

    if(item.statut == enumStatutStorePro.SUSPENDRE)
    {
      item.statut = enumStatutStorePro.ACTIF;

    }
    else{
      item.statut = enumStatutStorePro.SUSPENDRE;
    }
    
    swal({
      title:"Statut fournisseur",
      text: 'Désirez-vous modifier le statut du fournisseur ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item,true);
      } else {
      }
    })
  }



  cancelSave() {
    this.itemToSave = {
      dateVersement : new Date()
    };
  }

  saveItem(item,isSuspendre?) {

  
    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('fournisseur/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Fournisseur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            if(!isSuspendre)
            {
              this.hideModal();
            }
          
            this.cancelSave(); 
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  saveItemEncaissement(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('fournisseurVersement/create', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Encaissement fournisseur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
           
              this.hideModal();
            this.cancelSave(); 
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }


  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        this.getData();
      } 
    });

  }


  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
