import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');


@Component({
  selector: 'app-main-historique-brouillard',
  templateUrl: './main-historique-brouillard.component.html',
  styleUrls: ['./main-historique-brouillard.component.css']
})
export class MainHistoriqueBrouillardComponent implements OnInit {

 
  results: Array<any> = [];
  ListeTypeProduits :  Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemStepWizard : number;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  imageDisplay : any = {};
  daterange : any = {};
  currentItemImage : any = {};
  itemToSearch: any;
  modalRef: BsModalRef;
  stationSelected : any = {};
  conditionnementToSave :  any = {};
  isNewItem: boolean;
  suscribStation : Subscription;
  oldValueSearch : String;
  isChangeItermPerPage : boolean = false;
  enumStatut : any = {};
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {
      dateDebut : moment().subtract(29, 'day'),
      dateFin :  moment()
    };
    this.itemStepWizard = 1;
    this.oldValueSearch = "";  

    this.daterangepickerOptions.settings = {
      locale: {
            format: 'DD-MM-YYYY',
            cancelLabel: 'Annuler',
            applyLabel: 'Rechercher',

            "customRangeLabel": "Personnaliser",
            "daysOfWeek": [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ],
            "monthNames": [
                "Janvier",
                "Fevrier",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Aout",
                "Septembre",
                "Octobre",
                "Novembre",
                "Decembre"
            ],
        },
        alwaysShowCalendars: false,
        ranges: {
           
            "Aujourd'hui":  moment(),
            "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
            'Les 03 derniers jours': [moment().subtract(2, 'day'),  moment()],
            'Les 07 derniers jours': [moment().subtract(6, 'day'),  moment()],
            'Les 15 derniers jours': [moment().subtract(14, 'day'),  moment()],
            'Les 30 derniers jours': [moment().subtract(29, 'day'),  moment()],
            'Les 60 derniers jours': [moment().subtract(59, 'day'),  moment()],
            'Les 90 derniers jours': [moment().subtract(89, 'day'),  moment()]
        }
     };

  }
  @Input() isRefresh;
  maxDate = new Date();
  
  resetDate() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }


  selectedDate(value: any, datepicker?: any,firstRefresh?: boolean) { 

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.itemToSearch.dateDebut =  value.start; //moment(value.start).format('DD/MM/YYYY');
    this.itemToSearch.dateFin = value.end; //moment(value.end).format('DD/MM/YYYY');
    this.daterange.label = value.label; 
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    
    this.getData();
  }
 

  getData() {

    // let search_value = this.itemToSearch.designation;
    // let isSearch = this.itemToSearch.designation ? true : false;

    // if(search_value && this.oldValueSearch)
    // {
    //   if(search_value != this.oldValueSearch)
    //   {
    //     this.currentPage = 1;
    //   }
    // }
   
    // if(search_value){
    //    search_value = search_value.trim();
       
    //    if(search_value.length>0 && search_value.length<3)
    //    {
    //       if(!this.isChangeItermPerPage)
    //       {
    //         return; 
    //       }
    //    }
    //    else
    //    {
    //      if(search_value == this.oldValueSearch)
    //      {
    //        return;
    //      }
    //      this.oldValueSearch = search_value; 
    //      //this.currentPage = 1;
    //    }
    // }
    // else
    // {
    //   if(this.oldValueSearch == "" && isSearch)
    //   {
    //     return;
    //   }

    //   this.oldValueSearch = ""; 
    // } 

    var request = {
      user: this.user.proxy,
      data: {
        action : this.itemToSearch.designation ? this.itemToSearch.designation.trim() : null,
        createdAt : null,
        createdAtParam : {},
        boutiqueId : this.userService.getCurrentBoutiqueUser()
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null 
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00';
      request.data.createdAtParam =
          {
              "operator": "[]",
              "start":  moment(this.itemToSearch.dateDebut).format("DD/MM/YYYY") + ' 00:00:00', //"22/02/2019",
              "end":  moment(this.itemToSearch.dateFin).format("DD/MM/YYYY") + ' 23:59:59' //"22/02/2019"
          }
    }
    
    this.busyGet = this.api.post('brouillardActions/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false; 
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
        }
      );
  }

  cancelSave() {
    this.itemToSave = {};
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
        this.getData();
      } 
    });


  }

  ngAfterViewInit()
  {
    this.resetDate(); 
  }

  // ngOnDestroy() {

  //   if (this.subscribeStoreSelected) {
  //     this.subscribeStoreSelected.unsubscribe();
  //   }

  // }


}
