import { Injectable } from '@angular/core';

@Injectable()
export class ModelService {

  //TypeUser : Object;

  constructor() { }

  communeDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


departementDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


employerDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'IdEmployeHistorique':(obj && obj.IdEmployeHistorique) ? obj.IdEmployeHistorique :null,
       'Matricule':(obj && obj.Matricule) ? obj.Matricule :null,
       'NumeroCnps':(obj && obj.NumeroCnps) ? obj.NumeroCnps :null,
       'Nom':(obj && obj.Nom) ? obj.Nom :null,
       'Prenoms':(obj && obj.Prenoms) ? obj.Prenoms :null,
       'DateNaissance':(obj && obj.DateNaissance) ? obj.DateNaissance :null,
       'DateEmbauche':(obj && obj.DateEmbauche) ? obj.DateEmbauche :null,
       'Sexe':(obj && obj.Sexe) ? obj.Sexe :null,
       'DernierDiplome':(obj && obj.DernierDiplome) ? obj.DernierDiplome :null,
       'Telephone':(obj && obj.Telephone) ? obj.Telephone :null,
       'IdReseauMobile':(obj && obj.IdReseauMobile) ? obj.IdReseauMobile :null,
       'IdDepartement':(obj && obj.IdDepartement) ? obj.IdDepartement :null,
       'IdService':(obj && obj.IdService) ? obj.IdService :null,
       'IdSociete':(obj && obj.IdSociete) ? obj.IdSociete :null,
       'IdCommune':(obj && obj.IdCommune) ? obj.IdCommune :null,
       'IdSecteur':(obj && obj.IdSecteur) ? obj.IdSecteur :null,
       'NumeroCompte':(obj && obj.NumeroCompte) ? obj.NumeroCompte :null,
       'LibelleSociete':(obj && obj.LibelleSociete) ? obj.LibelleSociete :null,
       'LibelleDepartement':(obj && obj.LibelleDepartement) ? obj.LibelleDepartement :null,
       'LibelleCommune':(obj && obj.LibelleCommune) ? obj.LibelleCommune :null,
       'LibelleSecteur':(obj && obj.LibelleSecteur) ? obj.LibelleSecteur :null,
       'LibelleReseau':(obj && obj.LibelleReseau) ? obj.LibelleReseau :null,
       'LibelleService':(obj && obj.LibelleService) ? obj.LibelleService :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


fonctionnaliteDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Code':(obj && obj.Code) ? obj.Code :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'CodeParent':(obj && obj.CodeParent) ? obj.CodeParent :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
     };
return data;
   }


parametrebaseDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Code':(obj && obj.Code) ? obj.Code :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'Valeur':(obj && obj.Valeur) ? obj.Valeur :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


profilDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


profilfonctionnaliteDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'IdProfil':(obj && obj.IdProfil) ? obj.IdProfil :null,
       'IdFonctionnalite':(obj && obj.IdFonctionnalite) ? obj.IdFonctionnalite :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


   referencesaisieDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Reference':(obj && obj.Reference) ? obj.Reference :null,
       'NumeroFacture':(obj && obj.NumeroFacture) ? obj.NumeroFacture :null,
       'IdSociete':(obj && obj.IdSociete) ? obj.IdSociete :null,
       'LibelleSociete':(obj && obj.LibelleSociete) ? obj.LibelleSociete :null,
       'IdService':(obj && obj.IdService) ? obj.IdService :null,
       'LibelleService':(obj && obj.LibelleService) ? obj.LibelleService :null,
       'IdSection':(obj && obj.IdSection) ? obj.IdSection :null,
       'LibelleSection':(obj && obj.LibelleSection) ? obj.LibelleSection :null,
       'NombreEmployeConcerne':(obj && obj.NombreEmployeConcerne) ? obj.NombreEmployeConcerne :null,
       'IdQualificationTaux':(obj && obj.IdQualificationTaux) ? obj.IdQualificationTaux :null,
       'LibelleQualificationTaux':(obj && obj.LibelleQualificationTaux) ? obj.LibelleQualificationTaux :null,
       'LibelleQualificationEmploye':(obj && obj.LibelleQualificationEmploye) ? obj.LibelleQualificationEmploye :null,
       'Taux':(obj && obj.Taux) ? obj.Taux :null,
       'IdParametreBasePrixPanier':(obj && obj.IdParametreBasePrixPanier) ? obj.IdParametreBasePrixPanier :null,
       'PrixPanier':(obj && obj.PrixPanier) ? obj.PrixPanier :null,
       'IdParametreBaseHeureNormal':(obj && obj.IdParametreBaseHeureNormal) ? obj.IdParametreBaseHeureNormal :null,
       'HeureNormale':(obj && obj.HeureNormale) ? obj.HeureNormale :null,
       'DateDebut':(obj && obj.DateDebut) ? obj.DateDebut :null,
       'DateFin':(obj && obj.DateFin) ? obj.DateFin :null,
       'NumeroSemaine':(obj && obj.NumeroSemaine) ? obj.NumeroSemaine :null,
       'Annee':(obj && obj.Annee) ? obj.Annee :null,
       'DateSaisie':(obj && obj.DateSaisie) ? obj.DateSaisie :null,
       'CategorieTaux':(obj && obj.CategorieTaux) ? obj.CategorieTaux :null,
       'BaseTaux':(obj && obj.BaseTaux) ? obj.BaseTaux :null,
       'CongeTaux':(obj && obj.CongeTaux) ? obj.CongeTaux :null,
       'GratifTaux':(obj && obj.GratifTaux) ? obj.GratifTaux :null,
       'PrecTaux':(obj && obj.PrecTaux) ? obj.PrecTaux :null,
       'CnsTaux':(obj && obj.CnsTaux) ? obj.CnsTaux :null,
       'CrsTaux':(obj && obj.CrsTaux) ? obj.CrsTaux :null,
       'ItsTaux':(obj && obj.ItsTaux) ? obj.ItsTaux :null,
       'PrSalTaux':(obj && obj.PrSalTaux) ? obj.PrSalTaux :null,
       'PrMedTaux':(obj && obj.PrMedTaux) ? obj.PrMedTaux :null,
       'PrPanier1Taux':(obj && obj.PrPanier1Taux) ? obj.PrPanier1Taux :null,
       'TransTaux':(obj && obj.TransTaux) ? obj.TransTaux :null,
       'Tphs15PourcentTaux':(obj && obj.Tphs15PourcentTaux) ? obj.Tphs15PourcentTaux :null,
       'Tphs50PourcentTaux':(obj && obj.Tphs50PourcentTaux) ? obj.Tphs50PourcentTaux :null,
       'Tphs75PourcentTaux':(obj && obj.Tphs75PourcentTaux) ? obj.Tphs75PourcentTaux :null,
       'Tphs100PourcentTaux':(obj && obj.Tphs100PourcentTaux) ? obj.Tphs100PourcentTaux :null,
       'TphnTaux':(obj && obj.TphnTaux) ? obj.TphnTaux :null,
       'Tfhs15PourcentTaux':(obj && obj.Tfhs15PourcentTaux) ? obj.Tfhs15PourcentTaux :null,
       'Tfhs50PourcentTaux':(obj && obj.Tfhs50PourcentTaux) ? obj.Tfhs50PourcentTaux :null,
       'Tfhs75PourcentTaux':(obj && obj.Tfhs75PourcentTaux) ? obj.Tfhs75PourcentTaux :null,
       'Tfhs100PourcentTaux':(obj && obj.Tfhs100PourcentTaux) ? obj.Tfhs100PourcentTaux :null,
       'PrPannierSecondTaux':(obj && obj.PrPannierSecondTaux) ? obj.PrPannierSecondTaux :null,
       'CodeFactTaux':(obj && obj.CodeFactTaux) ? obj.CodeFactTaux :null,
       'OperationPaieTaux':(obj && obj.OperationPaieTaux) ? obj.OperationPaieTaux :null,
       'OperationFactureTaux':(obj && obj.OperationFactureTaux) ? obj.OperationFactureTaux :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }

reseaumobileDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }



   saisieDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'ReferenceSaisie':(obj && obj.ReferenceSaisie) ? obj.ReferenceSaisie :null,
       'IdReferenceSaisie':(obj && obj.IdReferenceSaisie) ? obj.IdReferenceSaisie :null,
       'IdEmployeCourant':(obj && obj.IdEmployeCourant) ? obj.IdEmployeCourant :null,
       'IdEmployeHistorique':(obj && obj.IdEmployeHistorique) ? obj.IdEmployeHistorique :null,
       'MatriculeEmploye':(obj && obj.MatriculeEmploye) ? obj.MatriculeEmploye :null,
       'NomEmploye':(obj && obj.NomEmploye) ? obj.NomEmploye :null,
       'PrenomsEmploye':(obj && obj.PrenomsEmploye) ? obj.PrenomsEmploye :null,
       'IdReseauEmploye':(obj && obj.IdReseauEmploye) ? obj.IdReseauEmploye :null,
       'LibelleReseauEmploye':(obj && obj.LibelleReseauEmploye) ? obj.LibelleReseauEmploye :null,
       'TelephoneEmploye':(obj && obj.TelephoneEmploye) ? obj.TelephoneEmploye :null,
       'IdCommuneEmploye':(obj && obj.IdCommuneEmploye) ? obj.IdCommuneEmploye :null,
       'LibelleCommuneEmploye':(obj && obj.LibelleCommuneEmploye) ? obj.LibelleCommuneEmploye :null,
       'IdSecteurEmploye':(obj && obj.IdSecteurEmploye) ? obj.IdSecteurEmploye :null,
       'LibelleSecteurEmploye':(obj && obj.LibelleSecteurEmploye) ? obj.LibelleSecteurEmploye :null,
       'LundiJr':(obj && obj.LundiJr) ? obj.LundiJr :null,
       'LundiNuit':(obj && obj.LundiNuit) ? obj.LundiNuit :null,
       'MardiJr':(obj && obj.MardiJr) ? obj.MardiJr :null,
       'MardiNuit':(obj && obj.MardiNuit) ? obj.MardiNuit :null,
       'MercrediJr':(obj && obj.MercrediJr) ? obj.MercrediJr :null,
       'MercdrediNuit':(obj && obj.MercdrediNuit) ? obj.MercdrediNuit :null,
       'JeudiJr':(obj && obj.JeudiJr) ? obj.JeudiJr :null,
       'JeudiNuit':(obj && obj.JeudiNuit) ? obj.JeudiNuit :null,
       'VendrediJr':(obj && obj.VendrediJr) ? obj.VendrediJr :null,
       'VendrediNuit':(obj && obj.VendrediNuit) ? obj.VendrediNuit :null,
       'SamediJr':(obj && obj.SamediJr) ? obj.SamediJr :null,
       'SmaediNuit':(obj && obj.SmaediNuit) ? obj.SmaediNuit :null,
       'DimancheJr':(obj && obj.DimancheJr) ? obj.DimancheJr :null,
       'DimancheNuit':(obj && obj.DimancheNuit) ? obj.DimancheNuit :null,
       'IdParametreBasePrixPanier':(obj && obj.IdParametreBasePrixPanier) ? obj.IdParametreBasePrixPanier :null,
       'PrixPanier':(obj && obj.PrixPanier) ? obj.PrixPanier :null,
       'RappelPaiePremier':(obj && obj.RappelPaiePremier) ? obj.RappelPaiePremier :null,
       'HeureSupplementaire15Pourcent':(obj && obj.HeureSupplementaire15Pourcent) ? obj.HeureSupplementaire15Pourcent :null,
       'HeureSupplementaire50Pourcent':(obj && obj.HeureSupplementaire50Pourcent) ? obj.HeureSupplementaire50Pourcent :null,
       'HeureSupplementaire75Pourcent':(obj && obj.HeureSupplementaire75Pourcent) ? obj.HeureSupplementaire75Pourcent :null,
       'HeureSupplementaire100Pourcent':(obj && obj.HeureSupplementaire100Pourcent) ? obj.HeureSupplementaire100Pourcent :null,
       'Taux':(obj && obj.Taux) ? obj.Taux :null,
       'TotalHeure':(obj && obj.TotalHeure) ? obj.TotalHeure :null,
       'IdParametreBaseHeureNormal':(obj && obj.IdParametreBaseHeureNormal) ? obj.IdParametreBaseHeureNormal :null,
       'HeureNormale':(obj && obj.HeureNormale) ? obj.HeureNormale :null,
       'TotalHeureSupplementaire':(obj && obj.TotalHeureSupplementaire) ? obj.TotalHeureSupplementaire :null,
       'TotalHeureNuit':(obj && obj.TotalHeureNuit) ? obj.TotalHeureNuit :null,
       'PrimePanier':(obj && obj.PrimePanier) ? obj.PrimePanier :null,
       'RappelPaieSecond':(obj && obj.RappelPaieSecond) ? obj.RappelPaieSecond :null,
       'RetraitPret':(obj && obj.RetraitPret) ? obj.RetraitPret :null,
       'NetAPayer':(obj && obj.NetAPayer) ? obj.NetAPayer :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }



secteurDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'IdCommune':(obj && obj.IdCommune) ? obj.IdCommune :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


sectionDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }

   serviceDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'IdSociete':(obj && obj.IdSociete) ? obj.IdSociete :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'LibelleSociete':(obj && obj.LibelleSociete) ? obj.LibelleSociete :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }

societeDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'RaisonSociale':(obj && obj.RaisonSociale) ? obj.RaisonSociale :null,
       'Email':(obj && obj.Email) ? obj.Email :null,
       'Contact':(obj && obj.Contact) ? obj.Contact :null,
       'Adresse':(obj && obj.Adresse) ? obj.Adresse :null,
       'SituationGeographique':(obj && obj.SituationGeographique) ? obj.SituationGeographique :null,
       'Logo':(obj && obj.Logo) ? obj.Logo :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


tauxDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'CodeSection':(obj && obj.CodeSection) ? obj.CodeSection :null,
       'Qualification':(obj && obj.Qualification) ? obj.Qualification :null,
       'Categorie':(obj && obj.Categorie) ? obj.Categorie :null,
       'IdSociete':(obj && obj.IdSociete) ? obj.IdSociete :null,
       'LibelleSociete':(obj && obj.LibelleSociete) ? obj.LibelleSociete :null,
       'Base':(obj && obj.Base) ? obj.Base :null,
       'Conges':(obj && obj.Conges) ? obj.Conges :null,
       'Gratifif':(obj && obj.Gratifif) ? obj.Gratifif :null,
       'Pres':(obj && obj.Pres) ? obj.Pres :null,
       'Cns':(obj && obj.Cns) ? obj.Cns :null,
       'Crr':(obj && obj.Crr) ? obj.Crr :null,
       'Its':(obj && obj.Its) ? obj.Its :null,
       'PrSal':(obj && obj.PrSal) ? obj.PrSal :null,
       'PrMed':(obj && obj.PrMed) ? obj.PrMed :null,
       'PrPanier':(obj && obj.PrPanier) ? obj.PrPanier :null,
       'Trans':(obj && obj.Trans) ? obj.Trans :null,
       'Tphs15Pourcent':(obj && obj.Tphs15Pourcent) ? obj.Tphs15Pourcent :null,
       'Tphs50Pourcent':(obj && obj.Tphs50Pourcent) ? obj.Tphs50Pourcent :null,
       'Taux':(obj && obj.Taux) ? obj.Taux :null,
       'Tphs75Pourcent':(obj && obj.Tphs75Pourcent) ? obj.Tphs75Pourcent :null,
       'Tphs100Pourcent':(obj && obj.Tphs100Pourcent) ? obj.Tphs100Pourcent :null,
       'Tfhn':(obj && obj.Tfhn) ? obj.Tfhn :null,
       'Tfhs15Pourcent':(obj && obj.Tfhs15Pourcent) ? obj.Tfhs15Pourcent :null,
       'Tfhs50Pourcent':(obj && obj.Tfhs50Pourcent) ? obj.Tfhs50Pourcent :null,
       'Tfhs75Pourcent':(obj && obj.Tfhs75Pourcent) ? obj.Tfhs75Pourcent :null,
       'Tfhs100Pourcent':(obj && obj.Tfhs100Pourcent) ? obj.Tfhs100Pourcent :null,
       'PrPanierSecond':(obj && obj.PrPanierSecond) ? obj.PrPanierSecond :null,
       'CodeFact':(obj && obj.CodeFact) ? obj.CodeFact :null,
       'OperationPaie':(obj && obj.OperationPaie) ? obj.OperationPaie :null,
       'OperationFacture':(obj && obj.OperationFacture) ? obj.OperationFacture :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }



   utilisateurDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Nom':(obj && obj.Nom) ? obj.Nom :null,
       'Prenoms':(obj && obj.Prenoms) ? obj.Prenoms :null,
       'Login':(obj && obj.Login) ? obj.Login :null,
       'Password':(obj && obj.Password) ? obj.Password :null,
       'IdProfil':(obj && obj.IdProfil) ? obj.IdProfil :null,
       'LibelleProfil':(obj && obj.LibelleProfil) ? obj.LibelleProfil :null,
       'ValiditeDebut':(obj && obj.ValiditeDebut) ? obj.ValiditeDebut :null,
       'ValiditeFin':(obj && obj.ValiditeFin) ? obj.ValiditeFin :null,
       'EstIllimite':(obj && obj.EstIllimite) ? obj.EstIllimite :null,
       'IsAdministrateur':(obj && obj.IsAdministrateur) ? obj.IsAdministrateur :null,
       'IsLock':(obj && obj.IsLock) ? obj.IsLock :null,
       'DateDerniereConnexion':(obj && obj.DateDerniereConnexion) ? obj.DateDerniereConnexion :null,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }

viewsecteurDto(obj){
    let data ={
       'Id':(obj && obj.Id) ? obj.Id :null,
       'Libelle':(obj && obj.Libelle) ? obj.Libelle :null,
       'IsDeleted':(obj && obj.IsDeleted) ? obj.IsDeleted : 0,
       'LibelleCommune':(obj && obj.LibelleCommune) ? obj.LibelleCommune :null,
       'IdCommune':(obj && obj.IdCommune) ? obj.IdCommune :null,
       'CreatedBy':(obj && obj.CreatedBy) ? obj.CreatedBy : 0,
       'ModifiedBy':(obj && obj.ModifiedBy) ? obj.ModifiedBy : 0,
       'DeletedBy':(obj && obj.DeletedBy) ? obj.DeletedBy : 0
     };
return data;
   }


}
