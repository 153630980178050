import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { enumActionApproStorePro, enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-form-reglement',
  templateUrl: './form-reglement.component.html',
  styleUrls: ['./form-reglement.component.css']
})
export class FormReglementComponent implements OnInit {

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any = {};
  user: any;
  enumStatutVente: any = {};
  currentClient: any = {};
  ListeReglementsClient: Array<any> = [];
  ListeClients : Array<any> = [];
  totalItemsReglement: number;
  itemsPerPageReglement: number = 4;
  currentPageReglement: number = 1;
  bsModalRefPreview: BsModalRef;
  modalRef: BsModalRef;
  linkPdfFile : String;
  enumStatutReglement : any = {};
  itemDetails : any = {};
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
  ) {

    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    
    this.enumStatutReglement = enumStatutReglementStorePro;
  }

  @Output() refreshMainPage: EventEmitter<any> = new EventEmitter();
  @Input() client;
  @Input() afficherFormReglement;
  @Input() afficherCadreClient;
  @Input() isRefresh;
  @Input() afficherHistoriqueVente;

  maxDate = new Date();

  sendRefreshMainPage() {
    this.refreshMainPage.emit(true);
  }

  showPreviewPrintModal() {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
  }



  openFormModalModal(template, itemToModified, isDetails) {
    this.cancelSave();
  
    if (itemToModified) {

      this.itemDetails = {};
      this.itemDetails = Object.assign({}, itemToModified);
      this.itemToSave = Object.assign({}, itemToModified);
    }

    if(isDetails) {
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-appro' }));
    }else {
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));
    }
   
  }


  confirmAnnulerVente(item) {
   
    let objToSave = {
      statut : null,
      motifStatus : null,
      id : item.id
    };

    objToSave.statut = enumStatutStorePro.ANNULE;

    if(!item.motifStatus) {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le motif de l'annulation !");
      return;
    }

    objToSave.motifStatus = item.motifStatus;
    
    swal({
      title: 'Annulation règlement',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemAnnulerReglement(objToSave);
      }
    })
  }

  saveItemAnnulerReglement(obj) {
    
    var request = {
      user: this.user.proxy,
      datas: [obj]
    };

    this.busySave = this.api.post('reglement/update', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Annulation reglement", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.getDataReglementByClient(this.client.id);
            this.modalRef.hide();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  printEtatCreditParClient(idClient) {

    if(!idClient)
    {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un client.");
      return;
    }

    var request = {
      user: this.user.proxy,
      symbolDevise : this.userService.getCurrentDevise(),
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientId: idClient
      }
      // index: (this.currentPageReglement - 1),
      // size: this.itemsPerPageReglement ? this.itemsPerPageReglement : null
    }

    this.busySave = this.api.post('reglement/getReportEtatCreditClient', request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
        }
      );
  }


  printRecuVersementParClient(idReglement) {

    if(!idReglement)
    {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un client.");
      return;
    }

    var request = {
      user: this.user.proxy,
      symbolDevise : this.userService.getCurrentDevise(),
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        id: idReglement
      }
    }

    this.busyGet = this.api.post('reglement/getReportRecuVersementtClient', request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['filePathDoc']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['filePathDoc'] ? res['filePathDoc'] : null;
            this.showPreviewPrintModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
        }
      );
  }


  selectedClient(client)
  {
    if(client)
    {
      console.log("le client selectionné ",client);
      this.client = client;
      this.currentClient = this.client;
      this.currentClient.resteAPayer = this.client.montantTotalOperation - this.client.montantTotalVerse; //this.currentClient.montantCreditAPayer; 
      //this.currentClient.montantCreditAPayer - this.currentClient.montantTotalVerse;
      // if (this.currentClient.resteAPayer < 0) {
      //   this.currentClient.resteAPayer = 0;
      // }
      this.itemToSave = Object.assign({}, this.currentClient);

      if(this.itemToSave.montantPaye)
      {
        this.itemToSave.montantPaye = this.userService.getFormatMillierInput(this.itemToSave.montantPaye);
      }

      if(this.itemToSave.montantTotalVerse)
      {
        this.itemToSave.montantTotalVerseFormat = this.userService.getFormatMillierInput(parseFloat(this.currentClient.montantTotalVerse.toFixed(2)));
      }

    
      this.itemToSave.montantCreditAPayerFormat = (this.itemToSave.montantTotalOperation || 0) - (this.currentClient.montantTotalVerse.toFixed(2) || 0); //this.userService.getFormatMillierInput(parseFloat(this.currentClient.montantCreditAPayer));
      console.log(" this.itemToSave ",this.itemToSave.montantCreditAPayerFormat);

      if(this.itemToSave.resteAPayer)
      {
        this.itemToSave.resteAPayerFormat = this.userService.getFormatMillierInput(this.currentClient.resteAPayer);
      }
      else 
      {
        this.itemToSave.resteAPayerFormat = 0;
      }


      //Recuperer ses reglements
      this.getDataReglementByClient(this.client.id)
    }
  }

  getDataReglementByClient(idClient) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientId: idClient,
        // isGetForStatistique: true,
        isVersement : true
      },
      index: (this.currentPageReglement - 1),
      size: this.itemsPerPageReglement ? this.itemsPerPageReglement : null
    }

    this.busySave = this.api.post('reglement/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici les lignes reglements', res);
          if (res && res['items']) {
            this.ListeReglementsClient = res['items'];
            this.totalItemsReglement = res['count'] ? res['count'] : 0;
          } else {
            this.ListeReglementsClient = [];
            this.totalItemsReglement = 0;
          }
        },
        err => {
        }
      );
  }

  pageChangedReglement(event: any): void {
    this.currentPageReglement = event.page;
    this.getDataReglementByClient(this.itemToSave.id);
  }


  getDataClient(idClient ?) {

    var request = {
      user: this.user.proxy,
      "data": {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        id: (this.client && this.client.id ) ? this.client.id : null 
      }
    }

    this.busySave = this.api.post('client/getByCriteria', request)
      .subscribe(
        res => {
          // console.log('voici le resultat', res);
          if (res && res['items']) {
           
            if(!this.afficherCadreClient || (this.client && this.client.id ))
            {
              //this.ListeClients = res['items'];

              this.currentClient = res['items'].length > 0 ? res['items'][0] : {};

              console.log(" this.currentClient ",this.currentClient);
              this.currentClient.resteAPayer = this.currentClient.montantCreditAPayer; // - this.currentClient.montantTotalVerse;
            
              this.itemToSave = Object.assign({}, this.currentClient);

              if(this.itemToSave.montantPaye)
              {
                this.itemToSave.montantPaye = this.userService.getFormatMillierInput(this.itemToSave.montantPaye);
              }

              if(this.itemToSave.montantTotalVerse)
              {
                this.itemToSave.montantTotalVerseFormat = this.userService.getFormatMillierInput(this.itemToSave.montantTotalVerse);
              }

              if(this.itemToSave.montantCreditAPayer)
              {
                this.itemToSave.montantCreditAPayerFormat = this.userService.getFormatMillierInput(this.itemToSave.montantCreditAPayer);
              }

              if(this.currentClient.resteAPayer)
              {
                this.itemToSave.resteAPayerFormat = this.userService.getFormatMillierInput(this.currentClient.resteAPayer);
              }

            }
            else
            {
              this.ListeClients = res['items'];
            }
          
          } else {
            this.currentClient = {};
            this.itemToSave = {};
            this.ListeClients = [];
          }
        },
        err => {
        }
      );

  }

  confirmSaveReglement(item) {

    let objToSave = Object.assign({}, item);

    if (!objToSave.montantPaye) {
      this.notificationService.showMessageError("Erreur", "Veuiller renseigner le montant payé.");
      return;
    }

    if (!objToSave.dateReglement) {
      this.notificationService.showMessageError("Erreur", "Veuiller renseigner la date de règlement.");
      return;
    }

    //Formater la date d'approvisionnement
    let dateFormatOperation = moment(objToSave.dateReglement).format("DD/MM/YYYY");
    if (dateFormatOperation == "Invalid date") {
      dateFormatOperation = objToSave.dateReglement + " 00:00:00";
    }
    else {
      dateFormatOperation = dateFormatOperation + " 00:00:00";
    }

    let objReglement = {
      clientId: this.client.id,
      modeReglement: "Espèce",
      boutiqueId: this.userService.getCurrentBoutiqueUser(),
      montantPayer: objToSave.montantPaye,
      // montatntTotalVerseACetteDate : objToSave.montantTotalVerse,
      // montantTotalAPayer : objToSave.montantCreditAPayer,
      // resteAPayer : objToSave.resteAPayer, 
      isVersement : true,
      dateReglement: dateFormatOperation
    }

    swal({
      title: 'Versement',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemReglement(objReglement);
      } 
    })
  }


  confirmPrintRecu(item) {

    let objToSave = Object.assign({}, item);

    swal({
      title: 'Reçu versement',
      text: 'Voulez-vous imprimer le reçu ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.printRecuVersementParClient(item.id);
      } 
    })
  }

  getResteAPayer() {
    console.log("recuperation du montant")
    // if (this.itemToSave.montantPaye) {
    //   // if (this.currentClient.resteAPayer <= 0) {
    //   //   this.itemToSave.resteAPayer = 0;
    //   // }
    //   // else {
    //   //   this.itemToSave.resteAPayer = (parseFloat(this.currentClient.montantCreditAPayer.toFixed(2)) || 0) - (((parseFloat(this.itemToSave.montantPaye)) || 0) + (parseFloat(this.itemToSave.montantTotalVerse.toFixed(2)) || 0) );
    //   // }
    //   this.itemToSave.resteAPayer = (parseFloat(this.itemToSave.montantCreditAPayer.toFixed(2) || 0) - ((parseFloat(this.itemToSave.montantPaye) ) || 0))    //(((parseFloat(this.itemToSave.montantPaye)) || 0) + (parseFloat(this.itemToSave.montantTotalVerse.toFixed(2)) || 0) );
    
    // }
    // else {
    //   this.itemToSave.resteAPayer = this.currentClient.resteAPayer;
    // }

    // if(this.itemToSave.resteAPayer)
    // {
    //   this.itemToSave.resteAPayerFormat = this.userService.getFormatMillierInput(parseFloat(this.itemToSave.resteAPayer).toFixed(2)); 
    // }
  }


  cancelSave() {
    this.itemToSave = {
      dateReglement: new Date()
    };
  }


  saveItemReglement(obj) {

    var request = {
      user: this.user.proxy,
      datas: [obj]
    };

    this.busySave = this.api.post('reglement/saveReglementFacture', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Versement", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            //this.cancelSave();
            this.sendRefreshMainPage();
            this.getDataReglementByClient(this.client.id);
            this.getDataClient(this.client.id);
            this.confirmPrintRecu(res['items'][0]); 
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {

        console.log(" Ecoute ",this.storeSelected);
        // this.getDataClient();
        console.log("this.client form reglement",this.client)

        if(this.client && this.client.id)
        {
          this.itemToSave.montantTotalOperation = this.client.montantTotalOperation;
          this.itemToSave.montantCreditAPayerFormat = this.client.montantCreditAPayer;
          this.itemToSave.montantTotalVerseFormat = this.client.montantTotalVerse;
          //this.itemToSave.resteAPayerFormat =   this.client.montantCreditAPayer;

          this.itemToSave.resteAPayerFormat = this.client.montantTotalOperation - this.client.montantTotalVerse;
         
          this.getDataReglementByClient(this.client.id);
        }
      } 
    });

  }


  ngOnDestroy() {
    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('data', changes);
    if (changes['client'] && changes['client'].currentValue) {
      // this.getDataClient();
      this.getDataReglementByClient(this.client.id)
    }

    if (changes['afficherCadreClient'] && changes['afficherCadreClient'].currentValue) {
      // this.getDataClient();
    }

    if(changes['isRefresh'] && changes['isRefresh'].currentValue)
    {
      // this.getDataClient();

      if(this.client && this.client.id)
      {
        this.getDataReglementByClient(this.client.id);
      }
    }

  }


}
