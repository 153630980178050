import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap";
import {Subscription} from 'rxjs/Subscription';
import {Api} from '../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {NotificationService} from '../../shared/utils/notification.service';
import {UtilitiesService} from '../../shared/utils/utilities.service';
import {ModelService} from '../../shared/utils/model.service';
import {UserService} from "../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-utilisateur',
  templateUrl: './main-utilisateur.component.html',
  styleUrls: ['./main-utilisateur.component.css']
})
export class MainUtilisateurComponent implements OnInit {

  
  results : Array<any> = [];
  ListeRoles : Array<any> = [];
  ListeStations : Array<any> = [];
  ListeShopAdministres : Array<any> = [];
  busyGet : Subscription;
  busySave : Subscription;
  itemToSave : any;
  totalItems = 0;
  currentPage = 1;
  currentWebApi = 'societe';
  user : any;
  itemsPerPage : any;
  currentItemChecked : any;
  statutCotisation : any;
  itemToSearch : any;
  modalRef: BsModalRef;
  currentItemImage : any;
  imageDisplay : any;
  stationSelected : any = {};
  suscribStation : Subscription;
  oldValueSearch : String;
  isChangeItermPerPage : boolean = false;


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

 
  constructor(private api:Api,private modalService: BsModalService,private notificationService : NotificationService,private utilities : UtilitiesService,private modelService : ModelService,private userService: UserService) {
        this.cancelSave();
        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 10;
        this.currentItemChecked = {};
        this.itemToSearch = {};
       // this.statutCotisation = enumStatutCotisation;
   }


   hideModal()
   {
       this.modalRef.hide();
   }

    openFormModalModal(template,itemToModified) {

        this.cancelSave();
        
        if(itemToModified)
        {
            itemToModified.password = null;
            itemToModified.roleSelected =  _.find(this.ListeRoles, { 'id':itemToModified.profil });
            //this.getDataStationsAdministree(itemToModified.id,false);
            this.itemToSave = Object.assign({}, itemToModified);
        }
        this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom-preview' }));
    }

    
  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page ;
    this.getData();
  }

  changeItermPerPage()
  {
    this.currentPage = 1;
    this.getData();
  }

  getData(isSearchbyFullText ? : boolean)
  {
    let search_value = this.itemToSearch.searchFullText;
    let isSearch = (isSearchbyFullText ?  true : false ); 

    if(search_value){
       search_value = search_value.trim();

       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }

      this.oldValueSearch = ""; 
    } 

      var request = {
          user : this.user.proxy, 
          data: {
            searchFullText : search_value ? search_value : null
          },
          index:(this.currentPage-1),
          size: this.itemsPerPage ? this.itemsPerPage : null
      }

      this.busyGet = this.api.post('user/getByCriteria', request)
          .subscribe(
              res => {
                  console.log(res); 
                  if(res && res['items'])
                  {
                      this.results = res['items'];
                      this.totalItems = res['count'] ? res['count'] : 0;
                  } else
                  {
                    this.results = [];
                  }
              },
              err => {
                  console.log("Error occured",err);
                  this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );

  }

  getDataRole()
  {
      var request = {
          user : this.user.proxy,
          data: {
            //requester: this.user.parentId
          }
      }

      this.api.post('profils/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('les roles',res); 
                  if(res && res['items'])
                  {
                      this.ListeRoles = res['items'];
                      //this.totalItems = res['count'] ? res['count'] : 0;
                  }
              },
              err => {
                  console.log("Error occured",err);
              //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );
  }


//   getDataStationsAdministree(idUser,isAllStation)
//   {
//       var request = {
//           user : idUser,
//           data: {
//             // requester: idUser,
//             // isActive: true
//         }
//       }

//       this.api.post('stations/getByCriteria', request)
//           .subscribe(
//               res => {
//                   console.log('les stations',res); 
//                   if(res && res['items'])
//                   {
                     
//                       if(!isAllStation)
//                       {
//                           this.ListeShopAdministres = res['items'];
//                       }
//                       else
//                       {
//                         this.ListeStations = res['items'];
//                       }
//                       //this.totalItems = res['count'] ? res['count'] : 0;
//                   }
//               },
//               err => {
//                   console.log("Error occured",err);
//               //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
//               // this.not
//               }
//           );
//   }

  removeShopAdministre(indice)
  {
    this.ListeShopAdministres.splice(indice, 1);
  }
     
     
  confirmSaveUserItem(obj) {

    let item = obj;
    if(!item || !item.nom || !item.prenoms || !item.login || !item.roleSelected)
    {
        this.notificationService.showMessageInfo("Information","Veuillez renseigner les champs obligatoires."); 
        return;
    }

    if(!item.contact && !item.email)
    {
        this.notificationService.showMessageInfo("Information","Veuillez renseigner le mail ou le contact.");
        return;
    }


    swal({
        title: 'Compte utilisateur',
        text: 'Désirez-vous poursuivre cette action ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)", 
    }).then((result) => {
        if (result) {
            
            this.saveItem(obj);
        } else {
            console.log("bnjr");
        }
    })
}


  confirmDeleteItem(obj) {

      swal({
          title: 'Suppression',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#f1444e",
          cancelButtonColor: "rgb(154, 154, 153)", 
      }).then((result) => {
          if (result) {
              this.deleteItem(obj);
          } else {
              console.log("bnjr");
          }
      })
  }

       
  confirmReinitPasswordItem(obj) {
    //this.lgModal.show();

    swal({
        title: 'Réinitialiser mot de passe',
        text: 'Désirez-vous poursuivre cette action ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
        if (result) {
            this.resetPassWordUser(obj);
        } else {
            console.log("bnjr");
        }
    })

}


       
  confirmActiveOrDesactiveItem(obj,isDesactiveActive) {
    //this.lgModal.show();

    swal({
        title: 'Utilisateur',
        text: !isDesactiveActive ? 'Voulez-vous activer ce compte utilisateur ?' : 'Voulez-vous désactiver ce compte utilisateur',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
        if (result) {
            this.desactiveOrActiveUser(obj);
        } else {
            console.log("bnjr");
        }
    })

}


  checkCurrentItem(item)
  {
    if(item)
    {
        console.log("ellement coché",item);
        this.currentItemChecked = item;
    }
  }

  cancelSave()
  {
      this.itemToSave = {};
  }
      

  uploadFile(event : any,item,typeUpload)
  {
      let reader = new FileReader();

      let file = event.target.files[0];
      item.fileName = file.name.split('.')[0];
      let Tabextension = file.name.split('.');
      let extension = Tabextension[Tabextension.length-1];

        //verifier si l'extension est accepter
        const listeextensionImages = this.userService.getExtensionsImage();

        if( !_.find(listeextensionImages, { 'extension':extension.toLowerCase() }) )
        {
            this.notificationService.showMessageError("Upload","Veuillez vérifier l'extension du fichier.");
            event.target.value = null;
            return;
        }
        
      reader.onload = (readerEvent) => {

          let data = (readerEvent.target as any).result;
          this.imageDisplay = data;
          console.log(data);
        //  item.photoProfil = data;
          item.fileBase64 = data.split(',')[1];

          this.currentItemImage = {
              fichierBase64 : item.fileBase64,
              nomFichier : item.fileName,
              extension : extension
          }

          console.log("le item",this.currentItemImage);
      };

      reader.readAsDataURL(event.target.files[0]);
  }


    saveItem(item)
    {
        console.log(item);
        if(!item || !item.nom || !item.prenoms || !item.login || !item.roleSelected)
        {
            this.notificationService.showMessageInfo("Information","Veuillez renseigner les champs obligatoires."); 
            return;
        }
    
        if(!item.contact && !item.email)
        {
            this.notificationService.showMessageInfo("Information","Veuillez renseigner le mail ou le contact.");
            return;
        }

        item.profil = item.roleSelected.id;

        var request = {
            user : this.user.proxy,
            datas : [
                item
            ]
        }
        
        console.log("create return",request);

        this.busySave = this.api.post('user/'+(item.proxy ? 'update':'create'), request)
            .subscribe(
                res => {
                console.log("resul",res);
                    if(!res['hasError'])
                    {
                        this.notificationService.showMessageSucces("Utilisateur",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        this.currentPage = 1;
                        this.hideModal();
                        this.cancelSave();
                        this.getData();
                    }
                    else
                    {
                        if(res['status'] && res['status']['message'])
                        {
                            this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                        }
                    }
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
                }
        );
    }


    resetPassWordUser(user)
    {
        let item = user;
        console.log(item);
        if(!item)
        {
            this.notificationService.showMessageInfo("Information","Veuillez sélectionner un utilisateur."); 
            return;
        }

        var request = {
            user : this.user.proxy,
            datas : [
                item
            ]
        }
        
        console.log("create return",request);

        this.busyGet = this.api.post('user/resetPassword', request)
            .subscribe(
                res => {
                console.log("resul",res);
                    if(!res['hasError'])
                    {
                        this.notificationService.showMessageSucces("Utilisateur",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        this.currentPage = 1;
                        this.getData();
                    }
                    else
                    {
                        if(res['status'] && res['status']['message'])
                        {
                            this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                        }
                    }
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
                }
        );
    }


    deleteItem(obj)
    {

        var request = {
            user : this.user.proxy,
            datas : [
                obj
            ]
        }

        this.busySave = this.api.post('user/delete', request)
            .subscribe(
                res => {
                    console.log(res); 
                    if(!res['hasError'])
                    {
                        this.notificationService.showMessageSucces("Suppression",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                    
                        this.currentPage = 1;
                        this.getData();
                    }
                    else
                    {
                        if(res['status'] && res['status']['message'])
                        {
                            this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                        }
                    }
                
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                // this.not
                }
        );
    }



    desactiveOrActiveUser(obj)
    {

        var request = {
            user : this.user.proxy,
            datas : [
                obj
            ]
        }

        this.busySave = this.api.post('user/activerDesactiver', request)
            .subscribe(
                res => {
                    console.log(res); 
                    if(!res['hasError'])
                    {
                        this.notificationService.showMessageSucces("Utilisateur",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        this.currentPage = 1;
                        this.getData();
                    }
                    else
                    {
                        if(res['status'] && res['status']['message'])
                        {
                            this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                        }
                    }
                
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                // this.not
                }
        );
    }


    cancelSearch()
    {
      this.itemToSearch = {};
      this.changeItermPerPage();
    }

    getExactlyNumberRow(page,index)
    {
        let num = index +1;
        if(page>1)
        {
            num = ((page - 1) * 10) + (index+1);
        }
        return num;
    }

    addStationAdministree(station)
    {
        if(station && station.id)
        {
            //Verifier si la station n'existe pas
            if(_.find(this.ListeShopAdministres, { 'id':station.id }))
            {
                this.notificationService.showMessageInfo("Erreur","Cette station existe déjà dans la liste."); 
                return;
            }
            this.ListeShopAdministres.push(station);
        }
    }
    
    ngOnInit() {
      
        this.getDataRole();
        //this.getDataStationsAdministree(this.user.id,true);
        this.getData();

        // this.suscribStation = this.userService.currentData.subscribe(currentData => {
        //     this.stationSelected = currentData;
        //      console.log('filiale',this.stationSelected); 
        //      if(this.stationSelected && this.stationSelected.id)
        //      {
                
        //      }
            
        //  });
         
        window.scrollTo(0, 0);
    }
}
