import {Injectable} from '@angular/core';

declare var $: any;

@Injectable()
export class NotificationService {

  constructor() {
  }

 showMessageInfo(title,message)
  {
      this.smallBox({
          title: title,
          content: message,
          color: "rgb(241, 68, 78)", //"#159",
          iconSmall: "fa fa-info fa-2x fadeInRight animated",
          timeout: 4000
      })
  }

    showMessageError(title,message)
    {
        this.smallBox({
            title: title,
            content: message,
            color: "#ec5252",
            iconSmall: "fa fa-times-circle fa-2x fadeInRight animated",
            timeout: 4000
        })
    }

    showMessageSucces(title,message)
    {
        this.smallBox({
            title: title,
            content: message,
            color: "#659265",
            iconSmall: "fa fa-check fa-2x fadeInRight animated",
            timeout: 4000
        })
    }

    defaultMessage()
    {
      let text_default = "Connexion momentanément interrompue";
      return text_default;
    }

    defaultMessageError()
    {
      let text_default = "Une erreur c'est produite lors de l'enregistrement";
      return text_default;
    }

  smallBox(data, cb?) {
    $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb)
  }

}
