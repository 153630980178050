import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import { enumActionApproStorePro, enumStatutStorePro, enumTypeSortieStorePro } from '../../../shared/utils/enumerator';
import { ActivatedRoute } from '@angular/router';
// import { POINT_CONVERSION_COMPRESSED } from 'constants';
// import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
// import { FormTrocAvecSortieArticleComponent } from '../form-troc-avec-sortie-article/form-troc-avec-sortie-article.component';
// import { FormTrocSansSortieArticleComponent } from '../form-troc-sans-sortie-article/form-troc-sans-sortie-article.component';
const swal = require('sweetalert2');

@Component({
  selector: 'app-form-commande',
  templateUrl: './form-commande.component.html',
  styleUrls: ['./form-commande.component.css']
})
export class FormCommandeComponent implements OnInit {

  subscribeStoreSelected: Subscription;
  storeSelected: any = {};

  results: Array<any> = [];

  ListeProduits: Array<any> = [];
  ListeVenteToSave: Array<any> = [];
  ListeClients: Array<any> = [];
  ListCouleurs: Array<any> = [];
  ListeLigneCommandes: Array<any> = [];
  counterRefreshHistorique: number = 1;
  busyGet: Subscription;
  resetitemInfo: number;
  busySave: Subscription;
  itemToSave: any = {};
  currentClient: any = {};
  articleSelectedByImei: any = {};
  lastCodeImeiSearch: string;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any = {};
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  isUpdateVente: boolean = false;
  resetitemInfoAfterVente: number;
  linkPdfFile: String;
  bsModalRefPreview: BsModalRef;
  codeImeiSearch: string;
  bsModalRefTroc: BsModalRef;
  oldCommande: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private route: ActivatedRoute, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
    private daterangepickerOptions: DaterangepickerConfig) {

    this.cancelSave();
    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);

    let dateDebutIso = new Date(anneeActuel, moisActuel - 1, 1);
    let dateFinIso = new Date(anneeActuel, moisActuel - 1, jrfinMoisCourant);


    this.user = this.userService.getCurrentUser();

    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut: dateDebutIso, //dateDebutMonth, //moment(new Date()).format("DD/MM/YYYY"),
      dateFin: dateFinIso //dateFinMonth, //moment(new Date()).format("DD/MM/YYYY")
    };
    this.oldValueSearch = "";

    // const tableauMois =new Array("Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"); 
    // let libelleMois = "Ce mois de "+tableauMois[new Date().getMonth()];

    this.route.params.subscribe(params => {
      console.log(params);
      if (params['update-commande']) {
        //Recuperer la commande dans le localstorage
        let commande = JSON.parse(localStorage.getItem("commande"));
        this.oldCommande = { ...commande };

        console.log(" this.oldCommande ", this.oldCommande);

        this.currentClient = {
          id: commande.clientId,
          libelle: commande.clientLibelle,
          contact: commande.contactClient,
          boutiqueId: commande.boutiqueId
        };

        this.selectedCommande(commande);
      }
    });

  }

  maxDate = new Date();

  //Recuperer la liste des couleurs
  // getDataCouleurs() {

  //   var request = {
  //     user: this.user.proxy,
  //     "data": { 
  //     }
  //   }

  //   this.api.post('couleurTelephone/getByCriteria', request)
  //     .subscribe(
  //       res => {
  //         if (res && res['items']) {
  //           this.ListCouleurs = res['items'];
  //         } else {
  //           this.ListCouleurs = [];
  //         }
  //       },
  //       err => {

  //       }
  //     );
  // }

  //Recuperer la liste des couleurs
  // getDataCapactie() {

  //   var request = {
  //     user: this.user.proxy,
  //     "data": { 
  //     }
  //   }

  //   this.api.post('capaciteTelephone/getByCriteria', request)
  //     .subscribe(
  //       res => {
  //         if (res && res['items']) {
  //           this.ListeCapacites = res['items'];
  //         } else {
  //           this.ListeCapacites = [];
  //         }
  //       },
  //       err => {

  //       }
  //     );
  // }


  // confirmPrintRecu(item) {

  //   let objToSave = Object.assign({}, item);

  //   swal({
  //     title: 'Reçu',
  //     text: 'Voulez-vous imprimer le reçu ?',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Oui',
  //     cancelButtonText: 'Non',
  //     confirmButtonColor: "#f1444e",
  //     cancelButtonColor: "rgb(154, 154, 153)",
  //   }).then((result) => {
  //     if (result) {
  //       this.getReceuVente(item);
  //     } 
  //   })
  // }

  deleteItemInListeVente(indice) {

    swal({
      title: 'Suppression vente',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.ListeVenteToSave.splice(indice, 1);
        // this.getMontantTotalVente();
      } else {

      }
    })
  }

  cancelVente() {

    swal({
      title: 'Annulation vente',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.cancelSave();
      } else {

      }
    })
  }

  hideModal() {

    if (this.ListeVenteToSave && this.ListeVenteToSave.length > 0) {
      swal({
        title: 'Fermeture de fenêtre',
        text: 'Des saisies sont en cours, voulez-vous fermer la fenêtre ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
        if (result) {
          this.modalRef.hide();
        }
      })
    }
    else {
      this.modalRef.hide();
    }
  }


  selectProduct = function (item) {

    console.log(item);
    if (!item || !item.id) {
      this.itemApproToSave = {};
      return;
    };

    this.itemApproToSave = Object.assign({}, item);

    if (!this.itemApproToSave.quantiteDisponible) {
      this.itemApproToSave.quantiteDisponible = 0;
      this.notificationService.showMessageError("Erreur", "Il n'y a pas de quantité disponible pour cet article !");
      return;
    }

    this.itemApproToSave.prixTotalAvecRemise = this.itemApproToSave.prixVente;
    this.itemApproToSave.dateSortie = new Date();

    this.itemApproToSave.prixAchatFormat = this.userService.getFormatMillierInput(this.itemApproToSave.prixAchat, true);
    this.itemApproToSave.prixVenteFormat = this.userService.getFormatMillierInput(this.itemApproToSave.prixVente, true);
    this.itemApproToSave.quantiteSortie = 1;
    this.addProductToSale(this.itemApproToSave);
  };

  selectedClient(client) {

    if (client) {
      this.currentClient = client;
      this.itemToSearch.clientSearch = client.libelle;
      this.itemToSearch.clientContactSearch = client.contact;
      this.itemToSearch.isDisplayTable = false;
    }
  }

  getDataProduits() {

    var request = {
      user: this.user.proxy,
      "data": {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        statut : enumStatutStorePro.ACTIF
      }
    }

    this.busyGet = this.api.post('article/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat', res);
          if (res && res['items']) {
            //Recuperer ceux qui ont des quantités en stocks

            // res['items'] = _.filter(res['items'], function(o) { return o.quantiteDisponible>0; });
            // this.ListeProduits = res['items'];
            this.ListeProduits = _.orderBy(res['items'], ['libelle'], ['asc']);
          } else {
            this.ListeProduits = [];
          }
        },
        err => {

        }
      );

  }


  confirmSaveItem() {

    let itemAEnregistrer = Object.assign({}, this.itemToSave);

    if (!this.ListeVenteToSave || this.ListeVenteToSave.length == 0) {
      this.notificationService.showMessageError("Information", "Veuillez vérifier la liste des produits à vendre.")
      return;
    }

    // console.log(" this.itemToSave.clientSelected ",this.itemToSave.clientSelected);
    if (!this.currentClient || !this.currentClient.id) {
      //Verifier si le nom et le contact son renseigné
      if (!this.itemToSearch.clientSearch || !this.itemToSearch.clientContactSearch) {
        this.notificationService.showMessageError("Erreur", "Veuillez renseigner le nom du client et son contact.");
        return;
      }

      if (this.itemToSearch.clientContactSearch && this.itemToSearch.clientSearch) {
        itemAEnregistrer.dataClient = {
          contact: this.itemToSearch.clientContactSearch,
          libelle: this.itemToSearch.clientSearch.toUpperCase(),
          boutiqueId: this.userService.getCurrentBoutiqueUser(),
          searchString: this.itemToSearch.clientSearch + ' ' + this.itemToSearch.clientContactSearch
        };
      }

    }
    else {
      itemAEnregistrer.clientId = this.currentClient.id;
    }

    itemAEnregistrer.boutiqueId = this.storeSelected.id;
    itemAEnregistrer.id = this.oldCommande.id || null;
    if (!itemAEnregistrer.id) {
      itemAEnregistrer.statut = enumStatutStorePro.BROUILLON;
    }


    this.ListeVenteToSave.forEach(vente => {
      vente.quantiteCommandee = vente.quantiteSortie;
      vente.prixUnitaireDiscute = vente.prixTotalAvecRemise
    });

    swal({
      title: itemAEnregistrer.id ? "Modification d'une commande" : "Enregistrement d'une commande",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(itemAEnregistrer);
      }
    })

  }

  //****** FONCTION DE MODIFICATION */
  selectedCommande(commandeSelected) {
    this.getDataLigneCommande(commandeSelected);
  }

  getDataLigneCommande(commande) {

    var request = {
      user: this.user.proxy,
      "data": {
        commandeId: commande.id
      }
    }

    this.busyGet = this.api.post('ligneCommande/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat', res);
          if (res && res['items']) {
            //Recuperer ceux qui ont des quantités en stocks

            // res['items'] = _.filter(res['items'], function(o) { return o.quantiteDisponible>0; });
            this.ListeLigneCommandes = _.orderBy(res['items'], ['id'], ['asc']);

            this.ListeVenteToSave = [];
            // Reconstituer la liste des ventes
            this.ListeLigneCommandes.forEach(ligneCommande => {

              // On push ceux qui ne sont pas livree totalement
              // if(ligneCommande.statut != enumStatutStorePro.LIVRER_TOTALEMENT) {
              this.ListeVenteToSave.push(
                {
                  articleId: ligneCommande.articleId,
                  quantiteCommandee: ligneCommande.quantiteCommandee,
                  prixUnitaireDiscute: ligneCommande.prixUnitaireDiscute,
                  boutiqueId: this.userService.getCurrentBoutiqueUser(),
                  libelleArticle: ligneCommande.articleLibelle,
                  codeArticle: ligneCommande.articleCode,
                  quantiteSortie: ligneCommande.quantiteCommandee,
                  // numero : approToSave.numero,
                  quantite: ligneCommande.quantiteCommandee,
                  // prixAchat : approToSave.prixAchat,
                  prixUnitaire: ligneCommande.prixUnitaireDeVente,
                  prixVente: ligneCommande.prixUnitaireDeVente,
                  quantiteDispoAvantOperation: '-',
                  prixTotalAvecRemise: ligneCommande.prixUnitaireDiscute,
                  // imeiTelephone : this.codeImeiSearch,
                  ligneCommandeId: ligneCommande.id
                }
              )
              // }

            });

            this.getMontantTotalVente();

            // Preselectionner le nom du client
            this.currentClient = {
              id: commande.clientId,
              libelle: commande.clientLibelle,
              contact: commande.contactClient,
              boutiqueId: commande.boutiqueId
            };

          } else {
            this.ListeLigneCommandes = [];
          }
        },
        err => {

        }
      );
  }

  //*** FIN FONCTION DE MODIFICATION */


  cancelSave() {
    this.itemToSave = {
      clientSelected: null,
      produitSelected: "",
      dateSortie: new Date()
    };
    this.isUpdateVente = false;
    this.itemToSearch.clientContactSearch = null;
    this.itemToSearch.clientSearch = null;
    this.itemApproToSave = {};
    this.ListeVenteToSave = [];
  }

  saveItem(item) {

    item.datasLigneCommande = this.ListeVenteToSave;
    var request = {
      user: this.user.proxy,
      datas: [item]
    };

    console.log("le request ", request);

    this.busyGet = this.api.post('commande/' + (this.oldCommande.id ? 'updateCommande' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Commande", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            //this.currentPage = 1;
            this.cancelSave();
            this.counterRefreshHistorique++;
            this.resetitemInfoAfterVente = new Date().getTime();
            // this.confirmPrintRecu(res['items'][0])  
            //this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  addProductToSale(appro) {
    let approToSave = Object.assign({}, this.itemApproToSave);
    if (!approToSave.id) {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un article.");
      return;
    }

    if (!approToSave.quantiteSortie) {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner la quantité à vendre.");
      return;
    }

    if (!approToSave.quantiteDisponible) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la quantité disponible de l'article.");
      return;
    }

    if (!this.itemApproToSave.prixTotalAvecRemise || this.itemApproToSave.prixTotalAvecRemise < 0) {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner un montant valide.");
      return;
    }

    if (approToSave.quantiteSortie > approToSave.quantiteDisponible) {
      this.notificationService.showMessageError("Erreur", "Veuillez vérifier la quantité en stock.");
      return;
    }


    // Dans ce cas, il verifie si l'article et le code imei ne sont pas deja dans la liste
    // Si cela existe alors on ramene un message d'eereur sinon on continue

    let currentArticleExisteInListe = _.find(this.ListeVenteToSave, (o) => { return (o.articleId == approToSave.id) });

    if (currentArticleExisteInListe) {
      this.notificationService.showMessageError("Erreur", "Cet article est déjà dans la liste.");
      return;
    }

    this.ListeVenteToSave.push(
      {
        articleId: approToSave.id,
        quantiteCommandee: approToSave.quantiteSortie,
        prixUnitaireDiscute: approToSave.prixTotalAvecRemise,
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        libelleArticle: approToSave.libelle,
        codeArticle: approToSave.code,
        quantiteSortie: approToSave.quantiteSortie,
        numero: approToSave.numero,
        quantite: approToSave.quantite,
        prixAchat: approToSave.prixAchat,
        prixUnitaire: approToSave.prixVente,
        prixVente: approToSave.prixVente,
        quantiteDispoAvantOperation: approToSave.quantiteDisponible,
        prixTotalAvecRemise: approToSave.prixTotalAvecRemise,
        imeiTelephone: this.codeImeiSearch
      }
    )

    //Calculer le montant total
    // this.getMontantTotalVente();
    this.itemApproToSave = {};
    this.itemToSave.produitSelected = null;
    this.resetitemInfo = new Date().getTime();
  }

  getMontantTotalVente() {
    //Verifier s'il y a des lignes negatives
    let currentItemMontantNegatif = _.find(this.ListeVenteToSave, function (o) { return (!o.prixTotalAvecRemise || o.prixTotalAvecRemise <= 0); });
    if (currentItemMontantNegatif && currentItemMontantNegatif.articleId) {
      this.notificationService.showMessageError("Erreur", "Impossible de saisir un montant négatif.");
      currentItemMontantNegatif.prixTotalAvecRemise = Math.abs(currentItemMontantNegatif.prixTotalAvecRemise);
      return;
    }

    this.itemToSave.montantTotal = _.sumBy(this.ListeVenteToSave, function (o) { return (o.prixTotalAvecRemise || 0) * (o.quantiteSortie || 0); });
    this.itemToSave.montantTotalFormat = this.userService.getFormatMillierInput(this.itemToSave.montantTotal);
    this.getResteAPayer();

  }

  cancelSearch() {
    this.itemToSearch = {};
    // this.changeItermPerPage();
  }

  getResteAPayer() {
    if (this.itemToSave.montantPaye) {
      this.itemToSave.resteAPayer = this.itemToSave.montantTotal - this.itemToSave.montantPaye;
    }
    else {
      this.itemToSave.resteAPayer = this.itemToSave.montantTotal;
    }

    this.itemToSave.resteAPayerFormat = this.userService.getFormatMillierInput(parseFloat(this.itemToSave.resteAPayer).toFixed(2));

  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    // this.getDataCouleurs();
    // this.getDataCapactie();

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1;
        this.cancelSave();
        this.getDataProduits();
        // this.getDataListeCommande();
      }
    });

  }

  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }

}
