import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { Router } from '@angular/router';
const swal = require('sweetalert2');


@Component({
  selector: 'app-main-stock',
  templateUrl: './main-stock.component.html',
  styleUrls: ['./main-stock.component.css']
})
export class MainStockComponent implements OnInit {

  
  
  results: Array<any> = [];
  ListeDepartements: Array<any> = [];
  ListePays :  Array<any> = [];
  ListeDevises :  Array<any> = [];
  ListeProduits:  Array<any> = [];
  currentProduit : any = {};
  totalItemsRebutHisto :   Array<any> = [];
  ListeDetailsServices : Array<any> = [];
  ListeScumByProduit :  Array<any> = [];
  ListeScumByProduitAndConditionnement : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  currentPageHistoRebut : number;
  itemsPerPageHistoRebut : number;
  valeurStockTotal : any;
  currentConditionnementProduit : any;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  oldQuantityInStock : number = 0;
  statutCotisation: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  stationSelected : any = {};
  itemApproToSave : any = {};
  currentConditionnementToSave :any = {}; 
  oldDataItem : any = {};
  dataSupplyDetailsToUpdate : any = {};
  suscribStation : Subscription;
  oldValueSearch : any;
  isChangeItermPerPage : boolean = false;
  isAllStation :  boolean = false;


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

 


  constructor(private api: Api,public router: Router, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    this.currentPageHistoRebut = 1;
    this.oldValueSearch = "";
    // this.statutCotisation = enumStatutCotisation;
  }


  hideModal() {
    this.modalRef.hide();
  }

  // gotoEffectuerVidande() {

  //   if (!this.stationSelected || !this.stationSelected.id) {
  //     this.notificationService.showMessageInfo("Information", "Veuillez sélectionner une station.")
  //     return;
  //   }

  //   this.router.navigate(['/form-vidanges']);
  // }

  openFormModalModal(template,produit,conditionnement) {


    if(this.stationSelected && this.stationSelected.id == 0)
    {
      this.notificationService.showMessageInfo("Rébut", "Veuillez sélectionner une station!");
      return;
    }

    if(!conditionnement.quantiteStock || conditionnement.quantiteStock<=0)
    {
      this.notificationService.showMessageInfo("Rébut", "Produit non disponible en stock.");
      return;
    } 

    // form-vidanges
    this.cancelSave();
    this.ListeDetailsServices = [];
    if (produit) {

     this.currentConditionnementToSave = {
        designation: produit.designation,
        reference : conditionnement.reference,
        quantiteStock : conditionnement.quantiteStock,
        libelleTypeConditionnement : conditionnement.libelleTypeConditionnement,
        conditionnement : conditionnement.id
      };

    }
    
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-md' }));
  
  }

  openFormModalModalHistoriqueRebut(template,ListeRebuts,conditionnement,produit) {

    console.log("le conditionnement",conditionnement);
    // form-vidanges
    
    // if(ListeRebuts && ListeRebuts.length > 0)
    // {
    //    this.ListeScumByProduitAndConditionnement = _.filter(ListeRebuts, function(o) { return (o.codeConditionnement == conditionnement); });
   
    //    if(!this.ListeScumByProduitAndConditionnement || this.ListeScumByProduitAndConditionnement.length == 0)
    //    {
    //     this.notificationService.showMessageInfo("Attention", "Aucun rébut n'a été effectué sur cet conditionnement.");
    //     return;
    //    }
    // }
    // else
    // {
    //   this.notificationService.showMessageInfo("Attention", "Aucun rébut n'a été effectué sur cet conditionnement.");
    //   return;
    // }

    this.currentProduit = produit;
    this.currentPageHistoRebut = 1;
    this.itemsPerPageHistoRebut = 5;
    this.currentConditionnementProduit = conditionnement;
    this.getDataRebut(this.currentConditionnementProduit.id);
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-md modal-histo-rebut' }));
  
  }


  changeQuantityStock(item)
  {
    if (item.quantite) {
      item.QuantityAfterCorrection = (item.quantiteStock - item.quantite);
    } else {
        item.QuantityAfterCorrection = item.quantiteStock;
    }
  }

  
  getQuantityInStock(referenceConditionnement) {

    if (!referenceConditionnement) {
        this.notificationService.showMessageInfo("Attention", "Veuillez renseigner la reference!");
        return;
    };

    var request = {
        datasProduct: [{
            requester: this.user.parentId,
            productConditioningCode: referenceConditionnement
        }]
    };

    this.busyGet = this.api.post('stock/getProductInfos', request)
    .subscribe(
      res => {
        console.log(res);
        if (res && res['itemsProduct']) {

          this.currentConditionnementToSave.currentConditionnement.quantityInStock = res['itemsProduct'][0].quantityInStock;
          this.oldQuantityInStock = this.currentConditionnementToSave.currentConditionnement.quantityInStock;
          this.currentConditionnementToSave.currentConditionnement.QuantityAfterCorrection = this.currentConditionnementToSave.currentConditionnement.quantityInStock;


        } else {
          //this.results = [];
        }
      },
      err => {
        console.log("Error occured", err);
        this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        // this.not
      }
    );


};


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getData();
  }

  pageChangedHisto(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPageHistoRebut = event.page;
    this.getDataRebut(this.currentConditionnementProduit.id);
  }


  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  getData() {
   
    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;

    if(search_value && this.oldValueSearch)
    {
      if(search_value.trim() != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }
   
    if(search_value){
       search_value = search_value.trim();

       
       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
         //this.currentPage = 1;
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }

      this.oldValueSearch = ""; 
    } 


    var request = {
      user: this.user.proxy,
      data: {
        station : (this.stationSelected && this.stationSelected.id) ?  this.stationSelected.id : 0,
        searchFullText : search_value ? search_value : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('stocks/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.valeurStockTotal = res['valeurStockTotal'] ? res['valeurStockTotal'] : 0;
          } else {
            this.results = [];
            this.valeurStockTotal = 0;
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }



  getDataExport() {

    if(!this.results || this.results.length == 0) {
      this.notificationService.showMessageInfo("Export","Aucune donnée à exporter.");
      return;
    }

    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;
   
   
    if(search_value && this.oldValueSearch)
    {
      if(search_value != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }

    if(search_value){
       search_value = search_value.trim();

       if(search_value.length>0 && search_value.length<3)
       {
        search_value = null;
       }
    }
   

    var request = {
      user: this.user.proxy,
      data: {
        station : (this.stationSelected && this.stationSelected.id) ?  this.stationSelected.id : 0,
        searchFullText : search_value ? search_value : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('stocks/exporter', request)
      .subscribe(
        res => {
          console.log(res);
          //this.isChangeItermPerPage = false;
          if (res && !res['hasError']) {
            window.location.href = res ? res['urlFileToDownload'] : window.location.href; 
          } else {
            this.notificationService.showMessageInfo("Exporter",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }




  getDataRebut(idConditionnement ? : any) {

    var request = {
      user: this.user.proxy,
      data: {
        station : (this.stationSelected && this.stationSelected.id) ?  this.stationSelected.id : 0,
        conditionnement : idConditionnement
      },
      index: (this.currentPageHistoRebut - 1),
      size: this.itemsPerPageHistoRebut ? this.itemsPerPageHistoRebut : null
    }

    this.busySave = this.api.post('rebut/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeScumByProduitAndConditionnement = res['items'];
            this.totalItemsRebutHisto = res['count'] ? res['count'] : 0;
          } else {
            this.ListeScumByProduitAndConditionnement = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }


  openDetail(item, status) {
    console.log("itm", item);
    item.isOpen = status;
    this.oldDataItem.isOpen = false;
    if (status) {

    }
    if (this.oldDataItem.id == item.id) {
        this.oldDataItem = {};
        return
    }

    this.oldDataItem = item;
    this.ListeScumByProduit = this.oldDataItem.rebuts ? this.oldDataItem.rebuts : [];
    //this.getRebutProduit();
  }


  printRecu()
  {
    
  }

  confirmDeleteItem(obj,isActive) {
    //this.lgModal.show();

    swal({
      title: !isActive ? 'Fermer une station' : 'Ouvrir une station',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj,isActive);
      } else {
        console.log("bnjr");
      }
    })

  }

  checkCurrentItem(item) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemChecked = item;
    }
  }

  cancelSave() {
    this.itemToSave = {};
    this.itemApproToSave = {};
  }

  getRebutProduit()
  {
    var request = {
      user: this.user.id,
      dataScum: {
        product: this.oldDataItem.id,
        userId: this.user.id
      }
    }

    this.busyGet = this.api.post('rebut/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['itemsScum']) {
            this.ListeScumByProduit = res['itemsScum'];

          } else {
            this.ListeScumByProduit = [];
          }
        },
        err => {
         
        }
      );
  }

  confirmSaveRebuItem(obj) {

    let item = obj;
    if (!item) {
      this.notificationService.showMessageInfo("Attention", "Veuillez renseigner les champs obligatoires!");
      return;
    }

    if(!item.quantite || item.quantite<0)
    {
      this.notificationService.showMessageInfo("Attention", "Veuillez renseigner les champs obligatoires!");
      return;
    }

    if (item.quantiteStock < item.quantite) {
        this.notificationService.showMessageInfo("Erreur", "La quantité à rébuter ne peut pas être supérieur au stock actuel.");
        return;
    }

    if(!item.motif)
    {
      this.notificationService.showMessageInfo("Attention", "Veuillez renseigner le motif!");
      return;
    }

    swal({
        title: 'Rébut',
        text: 'Désirez-vous poursuivre cette action ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)", 
    }).then((result) => {
        if (result) {
            this.saveItemRebut(obj);
        } else {
            console.log("bnjr");
        }
    })
}

  saveItemRebut(item) {
    
    if (!item) {
      this.notificationService.showMessageInfo("Attention", "Veuillez renseigner les champs obligatoires!");
      return;
    }

    if(!item.quantite || item.quantite<0)
    {
      this.notificationService.showMessageInfo("Attention", "Veuillez renseigner les champs obligatoires!");
      return;
    }

    if (item.quantiteStock < item.quantite) {
        this.notificationService.showMessageInfo("Erreur", "La quantité à rébuter ne peut pas être supérieur au stock actuel.");
        return;
    }

    if(!item.motif)
    {
      this.notificationService.showMessageInfo("Attention", "Veuillez renseigner le motif!");
      return;
    }

    item.station = this.stationSelected.id;

    var request = {
        user : this.user.proxy,
        datas: [item]
    };


    this.busySave = this.api.post('rebut/create', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] ) {
            this.notificationService.showMessageSucces("Rebut", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave(); 
            this.getData();
            this.oldDataItem = {};
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }



  deleteItem(obj,isActive) {

    var request = {
      user: this.user.id,
      datasShop: [{
        id: obj.id,
        isActive: isActive,
        requester: this.user.id,
    }]
    }

    this.busySave = this.api.post('shop/update', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", 'Suppression effectuée avec succès');

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }


  ngOnInit() {

  // this.getData();
  // this.getDataProduits();
  //  this.getDataCurrency();
  window.scrollTo(0, 0);

  //   this.suscribStation = this.userService.currentData.subscribe(currentData => {
  //     this.stationSelected = currentData;
  //      console.log('filiale',this.stationSelected); 
  //      if(this.stationSelected && this.stationSelected.id)
  //      {  
  //        this.getData();
  //        //this.getDataProduits();

  //      }
      
  //  });


   this.suscribStation = this.userService.currentData.subscribe(currentData => {
    this.stationSelected = currentData;
     console.log('filiale',this.stationSelected); 
     if(this.stationSelected && this.stationSelected.libelle)
     { 
       if(this.stationSelected.id == 0)
       {
         this.isAllStation = true;
       }
       else
       {
         this.isAllStation = false;
       }

      this.isChangeItermPerPage = true;
      this.oldValueSearch = "";
       this.getData();
     
     }
     else
     {
       this.notificationService.showMessageInfo("Information","Veuillez sélectionner une station.");

     }
    
 });


  }


  ngOnDestroy(){
    if(this.suscribStation) this.suscribStation.unsubscribe();
  }



}
