import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-historique-troc-sans-sortie-article',
  templateUrl: './main-historique-troc-sans-sortie-article.component.html',
  styleUrls: ['./main-historique-troc-sans-sortie-article.component.css']
})
export class MainHistoriqueTrocSansSortieArticleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
