import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import {ActivatedRoute, Route, Router} from '@angular/router';
import { FormSearchReferenceSaisieCorrectionComponent } from '../../../+components/form-search-reference-saisie-correction/form-search-reference-saisie-correction.component';

import { FormPrintBulletinComponent } from '../../../+components//form-print-bulletin/form-print-bulletin.component';


import { FormPrintRecapitulatifPaieComponent } from '../../../+components/form-print-recapitulatif-paie/form-print-recapitulatif-paie.component';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserService} from "../../user/user.service";
import { enumStatutStorePro } from 'app/shared/utils/enumerator';
import { Api } from 'app/shared/utils/api/api';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';

@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  user:any;
  requestRefresh : Subscription;
  bsModalRef: BsModalRef;
  storeSelected : any;
  subscribeStoreSelected : Subscription;
  itemToSave : any;
  nombreCommandeEnAttente : any;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api,public router:Router,private modalService: BsModalService,private userService: UserService) {
  }
  
  getDataListeCommande() {

    var request = {
      user: this.user.proxy,
      "data": { 
        boutiqueId : this.userService.getCurrentBoutiqueUser(),
        statut : enumStatutStorePro.VALIDE
      }
    }
  
   this.api.post('commande/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici le resultat',res); 
          if (res && res['items']) {
            //Recuperer ceux qui ont des quantités en stocks
            this.nombreCommandeEnAttente = res['count']
          } else {
            this.nombreCommandeEnAttente = 0;
          }
        },
        err => {
         
        }
      );
  }

  refreshData() {
    this.requestRefresh = Observable.interval(5 * 1000)
      .subscribe(i => {
        this.getDataListeCommande()
      })
  }


  ngOnDestroy() {
    if (this.requestRefresh) this.requestRefresh.unsubscribe();
  }

   
  ngOnInit() {
    this.user = this.userService.getCurrentUser();

    if(this.user && this.user.profilCode =='CAISSIER') {
      this.refreshData();
      this.getDataListeCommande();
    }

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
      } 
    });
   
  }

}
