/**
 * Created by griga on 7/11/16.
 */


import {Routes, RouterModule} from '@angular/router';
import {MainLayoutComponent} from "./shared/layout/app-layouts/main-layout.component";
import {AuthLayoutComponent} from "./shared/layout/app-layouts/auth-layout.component";
import {LoginComponent} from "./+auth/+login/login.component";
import {ModuleWithProviders} from "@angular/core";

import {LoggedInGuardService} from "./shared/utils/guards/logged-in-guard.service";

import { MainStationComponent } from './+components/app-boutique/main-station/main-station.component';
import { MainApprovisionnementComponent } from './+components/app-boutique/main-approvisionnement/main-approvisionnement.component';
import { MainProduitComponent } from './+components/app-boutique/main-produit/main-produit.component';
import { MainGestionProduitComponent } from './+components/app-boutique/main-gestion-produit/main-gestion-produit.component';
import { MainVidangesComponent } from './+components/app-boutique/main-vidanges/main-vidanges.component';
import { FormVidangesComponent } from './+components/app-boutique/form-vidanges/form-vidanges.component';
import { MainStockComponent } from './+components/app-boutique/main-stock/main-stock.component';
import { MainProfilComponent } from './+components/app-boutique/main-profil/main-profil.component';
import { MainUtilisateurComponent } from './+components/app-boutique/main-utilisateur/main-utilisateur.component';
import { MainHistoriqueConnexionComponent } from './+components/app-boutique/main-historique-connexion/main-historique-connexion.component';
import { MainDepensesComponent } from './+components/app-boutique/main-depenses/main-depenses.component';
import { MainHistoriqueBrouillardComponent } from './+components/app-boutique/main-historique-brouillard/main-historique-brouillard.component';
import { MainClientsComponent } from './+components/app-boutique/main-clients/main-clients.component';
import { MainFournisseursComponent } from './+components/app-boutique/main-fournisseurs/main-fournisseurs.component';
import { MainBilanVentesComponent } from './+components/app-boutique/main-bilan-ventes/main-bilan-ventes.component';
import { MainBoutiqueComponent } from './+components/app-boutique/main-boutique/main-boutique.component';
import { MainFournisseurCreditComponent } from './+components/app-boutique/main-fournisseur-credit/main-fournisseur-credit.component';
import { MainCompteIcloudComponent } from './+components/app-boutique/main-compte-icloud/main-compte-icloud.component';
import { MainTechnicienComponent } from './+components/app-boutique/main-technicien/main-technicien.component';
import { MainReparationComponent } from './+components/app-boutique/main-reparation/main-reparation.component';
import { MainDashbordComponent } from './+components/app-boutique/main-dashbord/main-dashbord.component';
import { MainParametreBaseComponent } from './+components/app-boutique/main-parametre-base/main-parametre-base.component';
import { MainCouleurTelephoneComponent } from './+components/app-boutique/main-couleur-telephone/main-couleur-telephone.component';
import { MainCapaciteParametrageComponent } from './+components/app-boutique/main-capacite-parametrage/main-capacite-parametrage.component';
import { FormCommandeComponent } from './+components/app-boutique/form-commande/form-commande.component';
import { HistoriqueCommandeComponent } from './+components/app-boutique/historique-commande/historique-commande.component';
import { MainStatistiqueCommercialComponent } from './+components/app-boutique/main-statistique-commercial/main-statistique-commercial.component';
import { UtilisateurStoreProComponent } from './+components/app-boutique/utilisateur-store-pro/utilisateur-store-pro.component';


export const routes: Routes = [
  {
    path: '', redirectTo: 'auth', pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: {pageTitle: 'Accueil'},
    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full',

      },
      // {path: 'home', loadChildren: 'app/+home/home.module#HomeModule',data:{pageTitle: '', permission : ['DASH']},canActivate: [LoggedInGuardService]},
      {path: 'parametrages', loadChildren: 'app/+parametrage/parametrage.module#ParametrageModule',data:{pageTitle: 'Paramétrages'},canActivate: [LoggedInGuardService]},
      {path: 'administrations', loadChildren: 'app/+administration/administration.module#AdministrationModule',data:{pageTitle: 'Administrations' , permission : ['ADMIN-NAV']},canActivate: [LoggedInGuardService]},
      // {path: 'alertes', loadChildren: 'app/+actualites/actualites.module#ActualiteModule',data:{pageTitle: 'Gestion des alertes'}},

      /** DESKTOP */

      //{path: 'stations',  component: MainStationComponent,data: { pageTitle: 'Stations' , permission : ['BOUT-NAV']}, canActivate: [LoggedInGuardService]},
      
      {path: 'approvisionnement',  component: MainApprovisionnementComponent,data: { pageTitle: 'Approvisionnements' , permission : ['APPRO-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'produits',  component: MainGestionProduitComponent,data: { pageTitle: 'Produits' , permission : ['PROD-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'services',  component: MainVidangesComponent,data: { pageTitle: 'Historique des services' , permission : ['VENTE-NAV','ADMIN-HISTO-SERVICE']}, canActivate: [LoggedInGuardService]},
      {path: 'form-service',  component: FormVidangesComponent,data: { pageTitle: 'Service' , permission : ['DASH']}, canActivate: [LoggedInGuardService]},
      {path: 'stock',  component: MainStockComponent,data: { pageTitle: 'Stock' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'depenses',  component: MainDepensesComponent,data: { pageTitle: 'Depenses' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'fournisseurs',  component: MainFournisseurCreditComponent,data: { pageTitle: 'Fournisseurs et crédit' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'clients',  component: MainClientsComponent,data: { pageTitle: 'Clients' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'bilan-vente',  component: MainBilanVentesComponent,data: { pageTitle: 'Bilan vente' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'boutiques',  component: MainBoutiqueComponent,data: { pageTitle: 'Boutique' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'compte-icloud',  component: MainCompteIcloudComponent,data: { pageTitle: 'Compte iCloud' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'reparation',  component: MainReparationComponent,data: { pageTitle: 'Réparations' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'home',  component: MainDashbordComponent,data: { pageTitle: 'Tableau de bord' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'parametre-base',  component: MainParametreBaseComponent,data: { pageTitle: 'Parametre de base' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'couleur-telephone',  component: MainCouleurTelephoneComponent,data: { pageTitle: 'Couleur téléphone' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'capacite-telephone',  component: MainCapaciteParametrageComponent,data: { pageTitle: 'Capacité téléphone' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'enregistrement-commmande',  component: FormCommandeComponent,data: { pageTitle: 'Commande' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'update-commande/:update-commande',  component: FormCommandeComponent,data: { pageTitle: 'Modifier une Commande' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'historique-commande',  component: HistoriqueCommandeComponent,data: { pageTitle: 'Historique commande' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'statistique-commercial',  component: MainStatistiqueCommercialComponent,data: { pageTitle: 'Statisqtique commercial' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
      {path: 'ressources/:isRessouceHumaine',  component: UtilisateurStoreProComponent,data: { pageTitle: 'Ressources humaine' , permission : ['STOCK-NAV']}, canActivate: [LoggedInGuardService]},
    
      
    ]
  },

  {path: 'auth', component: AuthLayoutComponent,// loadChildren: 'app/+auth/auth.module#AuthModule',
  children: [
    {
      path: '', redirectTo: 'login', pathMatch: 'full',

    },
    {path: 'login', component: LoginComponent,data:{pageTitle: 'Connexion'}}
    ]
  },

  {path: '**', redirectTo: 'miscellaneous/error404'} 

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});
