import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsLocaleService } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { frLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { EtatVente } from '../../../shared/utils/enumerator';
import { Client } from '_debugger';
import { Router } from '@angular/router';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
const swal = require('sweetalert2');

@Component({
  selector: 'app-form-vidanges',
  templateUrl: './form-vidanges.component.html',
  styleUrls: ['./form-vidanges.component.css']
})
export class FormVidangesComponent implements OnInit {

 
  
  results: Array<any> = [];
  ListeDepartements: Array<any> = [];
  ListProductVenteToDisplay : Array<any> = [];
  ListeProduits : Array<any> = [];
  ListePays :  Array<any> = [];
  ListeDevises :  Array<any> = [];
  listProductVenteToSave : Array<any> = [];
  // : Array<any> = [];
  ListeCartes: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalAllVente : number;
  prixRemiseProduit :  number;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  stationSelected : any = {};
  vente : any = {};
  client : any = {};
  suscribStation : Subscription;


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private api: Api,public router: Router, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
    private _localeService: BsLocaleService) {
    
    defineLocale(frLocale.abbr, frLocale);
    this._localeService.use('fr');  
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    this.vente = {
      conditionnementToSave : {} 
    }
    // this.statutCotisation = enumStatutCotisation;
  }

  minDate = new Date(); //moment(new Date()).add(1,'day');


  hideModal() {
    this.modalRef.hide();
  }

  gotoHistoriqueVidande() {

    this.router.navigate(['/services']);
  }

  openFormModalModal(template, itemToModified) {
  
    if(!this.ListProductVenteToDisplay || this.ListProductVenteToDisplay.length == 0)
    {
      this.notificationService.showMessageInfo("Service","Veuillez préciser les informations du serice.");
      return;
    }

    if (itemToModified) {
      this.itemToSave = {};
      this.itemToSave = Object.assign({}, itemToModified);
    }

    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom-info-client' }));
   // this.modalRef = this.modalService.show(template);
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  selectProduct = function(item) {
    console.log(item);
    if (!item) {
      this.vente = {};
    };

    let newConditionningitem = Object.assign({}, item);
    if(this.vente.isModify)
    {
      let venteInfoToModify = Object.assign({}, this.vente);
      
      this.vente = newConditionningitem;
      this.vente.isModify = true;
      this.vente.indiceInListe = venteInfoToModify.indiceInListe;
    }
    else{
      this.vente = newConditionningitem;
    }
   

    //this.searchProductWithConditionning(newConditionningitem);
  };

  changeStatus(item)
  {
    console.log("le item en question",item);
  }

  getData() {
    var request = {
      user: this.user.id,
      data: {

      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('shop/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );

  }


  searchProductWithConditionning(reference)
  {
    if(!this.stationSelected || !this.stationSelected.id)
    {
      this.notificationService.showMessageInfo("Station","Veuillez sélectionner une station.");
      return;
    }

    if(!reference)
    {
      this.notificationService.showMessageInfo("Référence","Veuillez renseigner la référence.");
      return;
    }

    var request = {
      user: this.user.proxy,
      "data": {
        referenceConditionnement : reference,
        station : (this.stationSelected && this.stationSelected.id) ? this.stationSelected.id : null
      }

    }

    this.busyGet = this.api.post('produits/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            if(res['items'].length > 0)
            {
               if(res['items'][0].conditionnements && res['items'][0].conditionnements.length>0)
               {
                  this.vente = res['items'][0].conditionnements[0];
                  this.vente.designation = res['items'][0].designation;
                  this.vente.ref = reference;

                  if(res['items'][0].photo)
                  {
                    this.vente.photo = res['items'][0].photo;
                  }
                
               }
               else
               {
                this.vente = {};
               }
            }
           
            // if(this.itemApproToSave && this.itemApproToSave.dataProductConditioning && this.itemApproToSave.dataProductConditioning.photo)
            // {
            //   this.itemApproToSave.photoToDisplay = this.itemApproToSave.dataProductConditioning.photo;
            // }
            // else {
            //   this.itemApproToSave.photoToDisplay = null;
            // }

          } else {
            this.vente = {
              ref : reference
            };
            this.notificationService.showMessageInfo("Attention", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        },
        err => {
          //this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());

        }
      );
  }


  confirmSaveItem(obj) {

    if (!this.listProductVenteToSave || this.listProductVenteToSave.length == 0) {
      this.notificationService.showMessageInfo("Erreur", "Veuillez renseigner les informations de la vente");
        return;
    }

    if(!this.client || !this.client.contact)
    {
      this.notificationService.showMessageInfo("Erreur","Veuillez renseigner le contact du client.");
      return;
    }

    if(this.client.isVidange)
    {

      if(!this.client.immatriculation)
      {
        this.notificationService.showMessageInfo("Erreur","Veuillez renseigner l'immatriculation du véhicule.");
        return;
      }

      //Verifier si la date est renseignée 
      if(this.client && !this.client.dateProchaineVidange)
      {
        //Vérifier si les kilometrages ne sont renseignés
        if(!this.client.kilometrageActuel || !this.client.prochaineVidange)
        {
          this.notificationService.showMessageInfo("Erreur","Veuillez renseigner la date de prochaine vidange ou le kilométrage actuel et le kilométrage de prochaine vidange.");
          return;
        }
        else
        {
            if(this.client.kilometrageActuel >= this.client.prochaineVidange)
            {
              this.notificationService.showMessageInfo("Erreur","Veuillez vérifier le kilométrage de la prochaine vidange.");
              return;
            } 
        }
      }
      else
      {
        if(this.client && this.client.dateProchaineVidange)
        {
          this.client.dateProchaineVidange = this.client.dateProchaineVidange.toLocaleString().split(' ')[0];
          console.log("la date de prochaine vidange ",this.client.dateProchaineVidange);
        }
      }

    }
    else
    {
      this.client.dateProchaineVidange = null;
      this.client.kilometrageActuel = null;
      this.client.prochaineVidange = null;
    }


    swal({
      title: "Service",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
         
        this.saveVente(obj);

      } else {
        console.log("bnjr");
      }
    })
  }


  getDataProduits() {
    var request = {
      user: this.user.proxy,
      "data": {
        station : (this.stationSelected && this.stationSelected.id) ? this.stationSelected.id : null
      } 
    }
  
    this.busyGet = this.api.post('produits/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeProduits = [];

            //Formater la liste des produits
            // _.forEach(res['items'], (value, key) =>{
            //   console.log(key);

               //Formater la liste des produits
            _.forEach(res['items'], (value, key) =>{
              console.log(key);

                if(value && value.conditionnements && value.conditionnements.length>0)
                {
                  _.forEach(value.conditionnements, (valueSecond, keySecond) =>{
                    console.log(keySecond);
                    if(valueSecond.quantiteStock && valueSecond.quantiteStock>0) 
                    {
                      valueSecond.libelleCustom = value.designation +' - '+valueSecond.libelleTypeConditionnement+' - '+valueSecond.prixVente;
                      valueSecond.libelleCustom = valueSecond.libelleCustom.toUpperCase();
                      valueSecond.designation = value.designation.toUpperCase();
                      valueSecond.photoToDisplay = value.photo ? value.photo : null;
                      valueSecond.photo = value.photo ? value.photo : null;
                      this.ListeProduits.push(valueSecond);
                    }
                 
                  });
                }
              });


           // });

          } else {
            this.ListeProduits = [];
          }
        },
        err => {
          //this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());

        }
      );

  }


  changeSearch(item)
  {
    this.itemToSave.isSearchByRef = item;
    this.vente = {};
    this.itemToSave.produitSelected = null;
  }


  confirmDeleteItem(obj,isActive) {
    //this.lgModal.show();

    swal({
      title: !isActive ? 'Fermer une station' : 'Ouvrir une station',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj,isActive);
      } else {
        console.log("bnjr");
      }
    })

  }

  checkCurrentItem(item) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemChecked = item;
    }
  }

  cancelSave() {
    this.itemToSave = {
      produitSelected : null
    };
    this.client = {
      isVidange : true
    };
    this.vente = {};

    this.ListProductVenteToDisplay = [];
    this.listProductVenteToSave = [];
  }

  deleteItem(obj,isActive) {

    var request = {
      user: this.user.id,
      datasShop: [{
        id: obj.id,
        isActive: isActive,
        requester: this.user.id,
    }]
    }

    this.busySave = this.api.post('shop/update', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", 'Suppression effectuée avec succès');

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  addVente = function(itemVente) {
    console.log("add vente",itemVente); 

    let vente =  Object.assign({}, itemVente);

    if (!vente || !vente.designation) {
        this.notificationService.showMessageInfo("Information", "Veuillez rechercher un produit !");
        return;
    }

    if (vente.quantiteStock < vente.quantite) {
        this.notificationService.showMessageInfo("Attention", "Veuillez vérifier la quantité en stock.");
        return;
    }

    //verifier si ce produit avec cette mm reference n est pas renseigné
    if(this.listProductVenteToSave && this.listProductVenteToSave.length > 0)
    {
      let isRefExiste = _.find(this.listProductVenteToSave, { 'referenceConditionnement': vente.reference});

      if(isRefExiste)
      {
        if (!this.vente.isModify) {
          this.notificationService.showMessageInfo("Attention", "Ce produit existe déjà dans le panier.");
          return;
        }
  
        else
        {
          if(isRefExiste.indexOfInListe != this.vente.indexOfInListe)
          {
            this.notificationService.showMessageInfo("Attention", "Ce produit existe déjà dans le panier.");
            return;
          }
        }
      }
    
    }

    if(!vente.quantite || vente.quantite <= 0)
    {
      this.notificationService.showMessageInfo("Attention", "Veuillez vérifier la quantité à servir.");
      return;
    }
  
    if(vente.isModify)
    {
      let prod = this.listProductVenteToSave[vente.indiceInListe];
      prod.quantite = vente.quantite;
      prod.referenceConditionnement = vente.reference;

      let prodInVente = this.ListProductVenteToDisplay[vente.indiceInListe];
      prodInVente.quantite = vente.quantite;
      prodInVente.prixVente = vente.prixVente;
      prodInVente.prixTotal = (vente.quantite * vente.prixVente);
      prodInVente.designation = vente.designation;
      prodInVente.referenceConditionnement = vente.reference;
      prodInVente.libelleTypeConditionnement = vente.libelleTypeConditionnement;
      prodInVente.photo = vente.photo ? vente.photo : null;
      prodInVente.quantiteStock = vente.quantiteStock;
      prodInVente.reference = vente.reference;

      this.clearCommandeAfterAdd();
      this.searchMontantGlobal();
      return;

    }

    this.listProductVenteToSave.push({
        quantite: vente.quantite,
        referenceConditionnement: vente.reference,
        indexOfInListe : this.listProductVenteToSave.length
    });

    this.ListProductVenteToDisplay.push({
        quantite: vente.quantite,
        prixVente: vente.prixVente,
        prixTotal : (vente.quantite * vente.prixVente),
        designation : vente.designation,
        referenceConditionnement: vente.reference,
        reference : vente.reference,
        libelleTypeConditionnement: vente.libelleTypeConditionnement,
        photo : vente.photo ? vente.photo : null,
        quantiteStock : vente.quantiteStock,
        indexOfInListe : this.ListProductVenteToDisplay.length
      
    });

    this.clearCommandeAfterAdd();
    this.searchMontantGlobal();
};

clearCommandeAfterAdd() {
   this.vente = {};
   this.itemToSave.produitSelected = null;
}

searchMontantGlobal() {
  if (this.ListProductVenteToDisplay.length == 0) {

      this.totalAllVente = 0;
      return;
  };

  this.totalAllVente = _.sumBy(this.ListProductVenteToDisplay, function(o) {
      return o.prixTotal;
  });
}

calculerMontant(vente) {
  if (!vente) return;
  if (vente.quantite > 0 ) {
    if(!vente.quantiteStock || vente.quantiteStock <1)
    {
      this.notificationService.showMessageInfo("Erreur", "Veuillez verifier la quantité disponible.");
      return;
    }

    if(vente.quantiteStock < vente.quantite)
    {
      vente.quantite = 0;
      this.notificationService.showMessageInfo("Erreur", "Veuillez verifier la quantité disponible.");
      return;
    }
    
    vente.prixTotal = vente.quantite * vente.prixVente;
     
  } else {
      vente.quantite = 0;
      //this.notificationService.showMessageInfo("Erreur", "Veuillez verifier la quantité");
      return;
  }
}

updateProdInListe(produit,indice)
{
  console.log("Produit à modifier",produit);
  this.vente = Object.assign({}, produit);
  this.vente.isModify = true;
  this.vente.indiceInListe = indice;

  //Preselectionner le produit
  this.itemToSave.produitSelected = _.find(this.ListeProduits, { 'reference':this.vente.referenceConditionnement }); 
}


changeRemiseGlobale(vente) {
  console.log('vente', vente);
  if (this.vente.discountAmountGlobal == null || this.vente.discountAmountGlobal == 0) {
      this.totalAllVente = this.prixRemiseProduit;
      return;
  }
  if (!vente || !vente.discountAmountGlobal) return;

  if ((!vente) || (!vente.discountTypeGlobal) || (!vente.discountAmountGlobal)) {
      this.notificationService.showMessageInfo("Attention", "Verifier le montant de la remise");
      vente.discountAmountGlobal = 0;
      return;
  } else {
      if (vente.discountTypeGlobal == 1) {
          if (vente.discountAmountGlobal >= 100) {
              vente.discountAmountGlobal = 0;
          }
          //Clacul du montant global
          //  $scope.vente.prixTotal = prixRemiseProduit - Math.round((prixRemiseProduit * vente.discountAmountGlobal) / 100).toFixed(2);
          let m : any;
          m = Math.round((this.prixRemiseProduit * vente.discountAmountGlobal) / 100).toFixed(2);
          this.totalAllVente = this.prixRemiseProduit - m;

          //    $scope.totalAllVente = $scope.vente.prixTotal;
      } else {
          if (vente.discountAmountGlobal > this.prixRemiseProduit) {
              this.notificationService.showMessageInfo("Attention", "Verifier le montant de la remise");
              vente.discountAmountGlobal = 0;
          }
          //   $scope.vente.prixTotal = prixRemiseProduit - vente.discountAmountGlobal;
          this.totalAllVente = this.prixRemiseProduit - vente.discountAmountGlobal; 
          //  $scope.totalAllVente = angular.copy($scope.vente.prixTotal);
      }
      // $scope.totalAllVente = $scope.vente.priyxTotal;
  }
};

cancelVente = function() {
  {

      swal({
        title: "Service",
        text: 'Désirez-vous poursuivre cette action ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#f1444e",
        cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
        if (result) {
            this.ListProductVenteToDisplay = [];
            this.listProductVenteToSave = [];
            this.vente = {};
            this.itemToSave.produitSelected = null;
            this.searchMontantGlobal();
        } else {
          console.log("bnjr");
        }
      });
  }
}


saveVente = function(vente) {

  if(!this.client.isVidange)
  {
    this.client.kilometrageActuel = null;
    this.client.prochaineVidange = null;
  }

  var request = {
    user : this.user.proxy,
      datas: [{
        station : this.stationSelected.id,
        detailService : this.listProductVenteToSave,
        client : this.client,
        isVidange : this.client.isVidange
      }]
  };



  this.busySave = this.api.post('services/create', request)
  .subscribe(
    res => {
      console.log("resul", res);
      if (!res['hasError'] ) {
        this.notificationService.showMessageSucces("Service", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
        this.currentPage = 1;
        //this.printRecuVente(res.itemsSale[0]);
        //this.listProductVenteToSave = [];
        this.ListProductVenteToDisplay = [];
        this.modalRef.hide();
        this.vente = { };
        this.client = {};
        this.searchMontantGlobal();
        this.majQuantiteEnStock();
        //this.getDataProduits();

      } else {
        if (res['status'] && res['status']['message']) {
          this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
        }
      }
    },
    err => {
      console.log("Error occured", err);
      this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
    }
  );
}

majQuantiteEnStock()
{
  _.forEach(this.listProductVenteToSave, (value, key) =>{
    //console.log(key);
        //Recuperer le produit dans la liste des produits
       let currentProduit = _.find(this.ListeProduits, { 'reference':value.referenceConditionnement }); 
       currentProduit.quantiteStock = currentProduit.quantiteStock - value.quantite;
    });

    setTimeout(() => {
      this.listProductVenteToSave = [];
    }, 2000);
  
}

supprimerVente(indice) {
  this.ListProductVenteToDisplay.splice(indice, 1);
  this.listProductVenteToSave.splice(indice, 1);
  this.searchMontantGlobal();
};



 printRecuVente(vente) {
  console.log("Vente", vente);

  // swal({
  //         title: "Confirmation",
  //         text: "Voulez-vous imprimer le réçu ?",
  //         type: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#DD6B55",
  //         confirmButtonText: $rootScope.getWord("Oui"),
  //         cancelButtonText: $rootScope.getWord("Non"),
  //         closeOnConfirm: true,
  //         closeOnCancel: true
  //     },
  //     function(isConfirm) {
  //         if (isConfirm) {

  //             if (!boutiqueService.IsUndefinedOrNull(vente)) {
  //                 var request = {
  //                     dataSale: {
  //                         saleCode: vente.saleCode,
  //                         dataShop: $rootScope.spaceSeleceted,
  //                         userId: $rootScope.userConnected.id
  //                     }
  //                 };

  //                 var options = {
  //                     headers: {
  //                         'tenantID': $rootScope.spaceSeleceted.code,
  //                     },
  //                     responseType: 'arraybuffer'
  //                 }

  //                 $http.post(appConfig.serviceBaseUrl + 'billsMaker/generateBillsAndSendToFront', request, options).then(function(response) {

  //                     console.log('response', response);
  //                     if (!response || response.data.hasError) {
  //                         //  boutiqueService.showMessageError("Erreur",boutiqueService.formatMsgServeur(response.data.status.message));
  //                         return;
  //                     }
  //                     var pdf = new Blob([response.data], { type: 'application/pdf' });
  //                     saveAs(pdf, 'recu_vente_' + vente.saleCode + '.pdf');

  //                 }, function(response) {

  //                 });

  //             }
  //         }
  //     });
}



changeRemise(vente) {
  console.log(vente);
  console.log('discountProduct', this.vente.discountProduct);
  if (this.vente.discountProduct == null || this.vente.discountProduct == 0) {
    this.vente.prixRemiseProduitUnitaire = vente.conditionnementToSave.unitSalePrice;
    this.vente.prixTotal = this.vente.prixRemiseProduitUnitaire * this.vente.quantite;
  }
  if (!vente || !vente.discountProduct) return;

  if ((!vente) || (!vente.discountType) || (!vente.discountProduct)) {
      //boutiqueService.showMessageInfo("Information","Verifier le mode de remise/la remise !");
      vente.discountProduct = 0;
      return;
  } else {
      if (vente.discountType == 1) {

          //Verifier si la remise n'est pas supérieur a 100%
          if (vente.discountProduct >= 100) {
              //boutiqueService.showMessageInfo($rootScope.getWord("Attention"), $rootScope.getWord("Veuillez verifier le pourcentage de remise"));
              // boutiqueService.showMessageInfo("Information","Veuillez verifier le pourcentage de remise !");
              vente.discountProduct = 0;
          }

          //Clacul du montant global
          let montantInRemise :  any;
          montantInRemise = Math.round((this.vente.conditionnementToSave.unitSalePrice * this.vente.discountProduct) / 100).toFixed(2);
          this.vente.prixRemiseProduitUnitaire = vente.conditionnementToSave.unitSalePrice - montantInRemise;
      } else {
          if (vente.discountProduct > vente.conditionnementToSave.unitSalePrice) {
              //  boutiqueService.showMessageInfo("Information","Veuillez verifier le montant de la remise !");
              vente.discountProduct = 0;
              return;
          }

          this.vente.prixRemiseProduitUnitaire = vente.conditionnementToSave.unitSalePrice - vente.discountProduct;
      }

      this.vente.prixTotal = this.vente.prixRemiseProduitUnitaire * this.vente.quantite;
      this.prixRemiseProduit = this.vente.prixTotal;
  }
};

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  getWord(item)
  {
    return item;
  }

  ngOnInit() {
  
    window.scrollTo(0, 0);

    this.suscribStation = this.userService.currentData.subscribe(currentData => {
      this.stationSelected = currentData;
       console.log('filiale',this.stationSelected); 
       if(this.stationSelected && this.stationSelected.id)
       {
        //this.getData();
        //this.getDataPays();
        this.cancelSave();
        this.getDataProduits();
       
       } else
       {
         this.notificationService.showMessageInfo("Information","Veuillez sélectionner une station.");

       }
      
   });

  }


  ngOnDestroy(){
    if(this.suscribStation) this.suscribStation.unsubscribe();
  }



}
