import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-main-technicien-reparateur',
  templateUrl: './main-technicien-reparateur.component.html',
  styleUrls: ['./main-technicien-reparateur.component.css']
})
export class MainTechnicienReparateurComponent implements OnInit {

  indiceTab : number;
  
  constructor() { }

  selectedChangeValue(event : any)
  {
      this.indiceTab = event;
  } 
  

  ngOnInit() {
  }

}
