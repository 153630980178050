import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { Router } from '@angular/router';
const swal = require('sweetalert2');
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import {Observable} from 'rxjs';
import { ConnectionService } from 'ng-connection-service';


@Component({
  selector: 'app-main-historique-connexion',
  templateUrl: './main-historique-connexion.component.html',
  styleUrls: ['./main-historique-connexion.component.css']
})
export class MainHistoriqueConnexionComponent implements OnInit {

 
  results: Array<any> = [];
  ListeDepartements: Array<any> = [];
  ListePays :  Array<any> = [];
  ListeDevises :  Array<any> = [];
  ListeProduits:  Array<any> = [];
  ListeCartes: Array<any> = [];
  ListeDetailsServices : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  isPossibilityToRefresh : Boolean = false;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  stationSelected : any = {};
  itemApproToSave : any = {};
  dataSupplyDetailsToUpdate : any = {};
  suscribStation : Subscription;
  isAllStation : any;
  oldValueSearch : any;
  daterange: any = {};
  valeurServiceTotal : any;
  isChangeItermPerPage : boolean = false;
  montantTotalSum : any;
  requestRefresh : Subscription;
  isFirstRefresh : Boolean;
  isConnected : Boolean;
  status : String;
  itemsPerPageSize : number = 10;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  
  constructor(private api: Api,public router: Router, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};

    status = 'ONLINE';
    this.isConnected = true;

   // this.checkStatutsConnexion();
    this.itemToSearch = {
      dateDebut : moment(new Date()).format("DD/MM/YYYY"),
      dateFin : moment(new Date()).format("DD/MM/YYYY")
    };
    this.isAllStation = false;
    this.oldValueSearch = "";

    this.daterangepickerOptions.settings = {
      locale: {
            format: 'DD-MM-YYYY',
            cancelLabel: 'Annuler',
            applyLabel: 'Rechercher',

            "customRangeLabel": "Personnaliser",
            "daysOfWeek": [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ],
            "monthNames": [
                "Janvier",
                "Fevrier",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Aout",
                "Septembre",
                "Octobre",
                "Novembre",
                "Decembre"
            ],
        },
        alwaysShowCalendars: false,
        ranges: {
          "Aujourd'hui":  moment(),
          "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
          'Les 03 derniers jours': [moment().subtract(2, 'day'),  moment()],
          'Les 07 derniers jours': [moment().subtract(6, 'day'),  moment()],
          'Les 15 derniers jours': [moment().subtract(14, 'day'),  moment()],
          'Les 30 derniers jours': [moment().subtract(29, 'day'),  moment()],
          'Les 60 derniers jours': [moment().subtract(59, 'day'),  moment()],
          'Les 90 derniers jours': [moment().subtract(89, 'day'),  moment()],
        }
     };

  }


  selectedDate(value: any, datepicker?: any) { 
    // this is the date the iser selected
    console.log(value);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.itemToSearch.dateDebut =  moment(value.start).format('DD/MM/YYYY');
    this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');
    this.daterange.label = value.label;

    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    
    this.getData();
  }

  checkStatutsConnexion()
  {
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    //   if (this.isConnected) {
    //     this.status = "ONLINE";
    //   }
    //   else {
    //     this.status = "OFFLINE";
    //   }

    //   console.log("This statut connexion "+this.status);
    // })
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage); 
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  refreshData() {
    this.requestRefresh = Observable.interval(30000)
      .subscribe(i => {
          this.getDataRefresh()  
      })
  }
  
  getData() {

    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;

    if(search_value && this.oldValueSearch)
    {
      if(search_value.trim() != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }
   
    if(search_value){
       search_value = search_value.trim();

       
       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }
      this.oldValueSearch = ""; 
    } 
    
    var request = {
      user: this.user.proxy,
      data: {
        station : (this.stationSelected && this.stationSelected.id) ?  this.stationSelected.id : 0,
        searchFullText : search_value ? search_value : null,
        createdAt : null,
        createdAtParam : {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? parseInt(this.itemsPerPage) : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
          {
              "operator": "[]",
              "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
              "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
          }
    }

    this.busyGet = this.api.post('historiqueConnexions/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
            this.totalItems = 0;
          }

          if(this.requestRefresh) this.requestRefresh.unsubscribe(); 

          setTimeout(() => {
            this.refreshData();
          }, 1000);
        },
        err => {
          console.log("Error occured", err);
          //this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );
  }

  getDataRefresh() {

    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;

    var request = {
      user: this.user.proxy,
      data: {
        station : (this.stationSelected && this.stationSelected.id) ?  this.stationSelected.id : 0,
        searchFullText : (search_value && search_value.length>2) ? search_value : null,
        createdAt : null,
        createdAtParam : {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
          {
              "operator": "[]",
              "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
              "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
          }
    }

    this.api.post('historiqueConnexions/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          //this.isChangeItermPerPage = false;
          if (res && res['items']) { 

            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;

          } else {
            this.results = [];
            this.totalItems = 0;
          }
        },
        err => {
          console.log("Error occured", err);
          this.requestRefresh.unsubscribe();
          //this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );
  }

  getDataExport() {

    if(!this.results || this.results.length == 0) {
      this.notificationService.showMessageInfo("Export","Aucune donnée à exporter.");
      return;
    }


    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;


    if(search_value && this.oldValueSearch)
    {
      if(search_value != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }

    if(search_value){
       search_value = search_value.trim();

       if(search_value.length>0 && search_value.length<3)
       {
        search_value = null;
       }
    }
   
    var request = {
      user: this.user.proxy,
      data: {
        station : (this.stationSelected && this.stationSelected.id) ?  this.stationSelected.id : 0,
        searchFullText : search_value ? search_value : null,
        createdAt : null,
        createdAtParam : {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }


    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
          {
              "operator": "[]",
              "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
              "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
          }
    }

    this.busyGet = this.api.post('historiqueConnexions/exporter', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false;
          if (res && !res['hasError']) {

            window.location.href = res ? res['urlFileToDownload'] : window.location.href; 
          } else {
            this.notificationService.showMessageInfo("Exporter",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );
  }

  cancelSave() {
    this.itemToSave = {};
    this.itemApproToSave = {};
  }

  changeSearch(item)
  {
    this.itemToSave.modeSearchProduitByRef = item;
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }


  ngOnInit() {
    // this.getData();
    // this.getDataProduits();
  //  this.getDataCurrency();
    window.scrollTo(0, 0);

    
    this.suscribStation = this.userService.currentData.subscribe(currentData => {
      this.stationSelected = currentData;
       if(this.stationSelected && this.stationSelected.libelle)
       { 
         if(this.stationSelected.id == 0)
         {
           this.isAllStation = true;
         }
         else
         {
           this.isAllStation = false;  
         }
         
         this.currentPage = 1;
         this.isChangeItermPerPage = true;
         this.oldValueSearch = "";
         this.getData();
       }
       else
       {
         //this.notificationService.showMessageInfo("Information","Veuillez sélectionner une station.");

       }
      
   });

  }

  ngOnDestroy(){
    if(this.suscribStation) this.suscribStation.unsubscribe();
    if(this.requestRefresh) this.requestRefresh.unsubscribe();
  }

}
