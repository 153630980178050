import { Component, OnInit, Output,EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import {Api} from '../../../shared/utils/api/api';
import {NotificationService} from '../../../shared/utils/notification.service';
import {UtilitiesService} from '../../../shared/utils/utilities.service';
import {ModelService} from '../../../shared/utils/model.service';
import {UserService} from "../../../shared/user/user.service";
import * as _ from "lodash";
import { enumTypeTechnicien } from '../../../shared/utils/enumerator';
const swal = require('sweetalert2');

@Component({
  selector: 'app-search-input-intervenant',
  templateUrl: './search-input-intervenant.component.html',
  styleUrls: ['./search-input-intervenant.component.css']
})
export class SearchInputIntervenantComponent implements OnInit {


  user: any;
  itemToSave: any = {};
  ListeClients : Array<any> = [];
  busySave : Subscription;
  busyGetClientSearch : Subscription;
  currentClient : any = {};
  typePatientEnum : any = {};
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  currentPage = 1;
  itemToSearch : any = {}
  
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private userService: UserService, private api: Api, private notificationService: NotificationService, private utilities: UtilitiesService) {
    this.itemToSave = {
        coutPartAssurance : null,
        coutTotalActes : null
    };
    this.user = this.userService.getCurrentUser();
    setTimeout(() => {
      this.itemToSave = {};
    }, 1000);
  }

  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  @Input() resetClientInfo;
  @Input() resetitemInfo;
  @Input() isNewEnregistrement;
  @Input() clientSelectedDefault;
  @Input() isReparateurSearch;
  @Input() idReparateur;
  @Input() idTechnicien;

  getDataItem()
  {
        var request = {
            "user":this.user.id,
            "data":{
              searchString : this.itemToSearch.clientSearch,
              boutiqueId : this.userService.getCurrentBoutiqueUser(),
              typeTechnicien : this.isReparateurSearch ? enumTypeTechnicien.REPARATEUR : enumTypeTechnicien.TECHNICIEN,
              id : this.idTechnicien || this.idReparateur
            },
            index : 0,
            size : 10 
        }
        
        this.itemToSearch.isDisplayTable = true;

        this.busyGetClientSearch = this.api.post('technicien/getByCriteria', request)
            .subscribe(
                res => {
                    console.log('return client',res); 
                    if(res && res['items'])
                    {
                        this.ListeClients = res['items'];
                        this.itemToSearch.isDisplayTable = true; 

                        if(this.idTechnicien  || this.idReparateur)
                        {
                          this.itemToSearch.clientSearch = res['items'][0].libelle;
                          this.itemToSearch.clientContactSearch = res['items'][0].contact;
                          this.itemToSearch.isDisplayTable = false;
                        }
                    }
                    else
                    {
                      this.ListeClients = [];
                    }
                },
                err => { 
                }
            );
  }

  selectedClient(client)
  {
    this.itemToSearch.isDisplayTable = false;
    // console.log("le client search",client);  
    if(client && client.id)
    {
      
      this.currentClient = client;
      this.itemToSearch.clientSearch = client.libelle;
      this.itemToSearch.clientContactSearch = client.contact;
      this.itemToSearch.isDisplayTable = false;
      
      this.selectedItem.emit(client);
    }
    else
    {
      this.currentClient = {};
      this.itemToSave = {};

      this.selectedItem.emit({
        libelle : this.itemToSearch.clientSearch,
        contact : this.itemToSearch.clientContactSearch
      });
    }
  }
  
  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes['resetitemInfo'] && changes['resetitemInfo'].currentValue)
    {
      this.selectedClient({});
    }

    if(changes['isNewEnregistrement'] && changes['isNewEnregistrement'].currentValue)
    {
      this.selectedClient({});
      this.itemToSearch.clientSearch = null;
      this.itemToSearch.clientContactSearch = null;
    }

    if(changes['clientSelectedDefault'] && changes['clientSelectedDefault'].currentValue  && changes['isUpdateVente'] && changes['isUpdateVente'].currentValue)
    {
      let currentClientParam = changes['clientSelectedDefault'].currentValue;
      this.selectedClient(currentClientParam);
    }

    if(changes['idTechnicien'] && changes['idTechnicien'].currentValue)
    {
      this.getDataItem();
    }

    if(changes['idReparateur'] && changes['idReparateur'].currentValue)
    {
      this.getDataItem();
    }

  }


}
