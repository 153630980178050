import {Injectable} from '@angular/core';


import {JsonApiService} from "../../core/api/json-api.service";
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import * as _ from "lodash";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class UserService {

  public user: Subject<any>;
  private data = new BehaviorSubject('');
  currentData = this.data.asObservable();

  private dataRefreshNewStation = new BehaviorSubject('');
  currentDataRefreshNewStation = this.dataRefreshNewStation.asObservable();

  public userInfo = {
    username: 'Guest'
  };

  activite_detectee = false;
  intervalle = 1000;
  countTer = 0;

  constructor(private jsonApiService:JsonApiService) {
    this.user = new Subject();
  }

  updateRefreshStationInformation(item: any) {
    this.dataRefreshNewStation.next(item);
  }

  updateStationInformation(item: any) {
    sessionStorage.setItem("currentBoutique",JSON.stringify(item));
    this.data.next(item);
  }

  isLoggedIn():boolean {
    return !!localStorage.getItem('yz-backoffice-app-boutique-store-pro');
  }

  getLoginInfo():Observable<any> {
    return this.jsonApiService.fetch('/user/login-info.json')
      .do((user)=>{
        this.userInfo = user;
      this.user.next(user)
    })
  }

  getExtensionsImage()
  {
    return [
      {extension:'png'},
      {extension:'jpeg'},
      {extension:'jpg'}
    ];
  }


  getExtensionsXlsx()
  {
    return [
      {extension:'xlsx'},
      {extension:'xls'}
    ];
  }
  
  getPermission(code, code1 ? : String)
  {
    code = code.trim()
    var returnValue = null;
    var ListeFonctionnalites = (this.getCurrentUser() && this.getCurrentUser().fonctionnalites) ? this.getCurrentUser().fonctionnalites : [];
    
    if(ListeFonctionnalites.length > 0)
    {
      _.forEach(ListeFonctionnalites, function(valueChildSecond, keyChild) {
        if(valueChildSecond.code == code || (code1 && valueChildSecond.code == code1))
        {
             returnValue = true;
        }
      });
    }

    return returnValue;
     
  }

  getCurrentBoutiqueUser()
  {
    let objBoutique = JSON.parse(sessionStorage.getItem("currentBoutique"));

    if(objBoutique) {
      return objBoutique.id;
    }
   
  }

  getCurrentDevise()
  {
    return " F ";
  }

  getCurrentLibelleDevise()
  {
    return " F ";
  }


  getCurrentUser()
  {
    return JSON.parse(localStorage.getItem("yz-backoffice-app-boutique-store-pro"));
  }

  getFormatMillierInput(input,withOutDevise?)
  {
    
      if(!input) return;

      var nombre=input;
      nombre += '';
      var sep = ' ';
      var reg = /(\d+)(\d{3})/;

      while (reg.test(nombre)) {
          nombre = nombre.replace(reg, '$1' + sep + '$2');
      }

      return nombre +' '+(withOutDevise ? '' : this.getCurrentDevise());
  
  }

  initSetTimout()
  {
    setTimeout(() => {
      this.getActiviteInScream(true)
   }, this.intervalle);

  }

   getActiviteInScream(activite_detectee) {

    if(activite_detectee)
      {
            activite_detectee = false; 
            this.countTer = 0;
      }
      else
      {
            this.countTer = this.countTer + 100;
            console.log("le counter component ts",this.countTer); 
            if(this.countTer == 1000)
            {
              console.log("le counter component ts est a 1000",this.countTer); 
                // Deconnecter l'utilisateur
                // document.getElementById('click-btn').click();
                // clearTimeout(this.t()) 
                this.countTer = 0;
            }
      }
      
      setTimeout(() => {
        this.getActiviteInScream(activite_detectee)
      }, this.intervalle);

  }


}
