import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import { enumStatutStorePro } from '../../../shared/utils/enumerator';
import { ActivatedRoute } from '@angular/router';
const swal = require('sweetalert2');

@Component({
  selector: 'app-utilisateur-store-pro',
  templateUrl: './utilisateur-store-pro.component.html',
  styleUrls: ['./utilisateur-store-pro.component.css']
})
export class UtilisateurStoreProComponent implements OnInit {

  results: Array<any> = [];
  ListeRoles :  Array<any> = [];
  ListeBoutiques :  Array<any> = [];
  itemSelectedBoutiqueSelected : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  dropdownSettings: any = {};
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  oldValueSearch : String;
  isChangeItermPerPage : boolean = false;
  enumStatut : any = {};
  subscribeStoreSelected: Subscription;
  storeSelected : any = {};
  isRessourceHumaine : boolean = false;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService, private route: ActivatedRoute) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.enumStatut = enumStatutStorePro;
    this.itemToSearch = {};
    this.oldValueSearch = "";  


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'libelle',
      selectAllText: 'Tout cocher',
      unSelectAllText: 'Tout decocher',
      // itemsShowLimit: 3,
      allowSearchFilter: false,
      searchPlaceholderText: "Rechercher ..."
    };

    this.route.params.subscribe(params => {
      console.log(params);
      if (params['isRessouceHumaine']) {
        //Recuperer la commande dans le localstorage
        this.isRessourceHumaine = true;
      }
    });
  }

  maxDate = new Date();

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
   
    if (itemToModified) {
      // console.log("le item to modified ",itemToModified);
      this.itemToSave = Object.assign({}, itemToModified);

      this.itemToSave.profilSelected =  _.find(this.ListeRoles, { 'id': parseInt(itemToModified.profilId) });  

      if(itemToModified.boutiqueId)
      {
        this.itemToSave.boutiqueSelected =  _.find(this.ListeBoutiques, { 'id': parseInt(itemToModified.boutiqueId) });
      }

      if(itemToModified.datasBoutique) {
        itemToModified.datasBoutique.forEach(boutique => {
          
          this.itemSelectedBoutiqueSelected.push({
            id : boutique.boutiqueId,
            libelle : boutique.boutiqueLibelle
          });

        });
      }
       
     }
    
    this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom' }));
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }


  getData() {

    let search_value = this.itemToSearch.designation;
    let isSearch = this.itemToSearch.designation ? true : false;

    if(search_value && this.oldValueSearch)
    {
      if(search_value != this.oldValueSearch)
      {
        this.currentPage = 1;
      }
    }
   
    if(search_value){
       search_value = search_value.trim();
       
       if(search_value.length>0 && search_value.length<3)
       {
          if(!this.isChangeItermPerPage)
          {
            return; 
          }
       }
       else
       {
         if(search_value == this.oldValueSearch)
         {
           return;
         }
         this.oldValueSearch = search_value; 
         //this.currentPage = 1;
       }
    }
    else
    {
      if(this.oldValueSearch == "" && isSearch)
      {
        return;
      }

      this.oldValueSearch = ""; 
    } 

    var request = {
      user: this.user.proxy,
      data: {
        nom : search_value ? search_value : null,
        // boutiqueId : this.userService.getCurrentBoutiqueUser() 
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('user/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          this.isChangeItermPerPage = false; 
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
        }
      );

  }

  //Recuperation des roles
  getDataRole() {

    var request = {
      user: this.user.proxy,
      data: {
        // boutiqueId : this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busyGet = this.api.post('profil/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeRoles = res['items'];
          } else {
            this.ListeRoles = [];
          }
        },
        err => {
        }
      );

  }

  //Recuperation des roles
  getDataBoutique() {

    var request = {
      user: this.user.proxy,
      data: {
        //boutiqueId : this.userService.getCurrentBoutiqueUser()
      }
    }

    this.busyGet = this.api.post('boutique/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeBoutiques = res['items'];
          } else {
            this.ListeBoutiques = [];
          }
        },
        err => {
        }
      );

  }


  confirmSaveItem(obj,isReinitPassword,isUpdateStatut) {

    let item =  Object.assign({}, obj);

    if(!isReinitPassword && !isUpdateStatut)
    {
      if (!item || !item.nom  || !item.prenom  || !item.login  || !item.profilSelected ) {
        this.notificationService.showMessageError("Information", "Veuillez renseigner les champs obligatoires.")
        return;
      }
      item.profilId = item.profilSelected.id;
    }

    item.boutiqueId = (this.itemToSave.boutiqueSelected && !this.itemToSave.isSuperAdmin) ? this.itemToSave.boutiqueSelected.id : null;
    item.isSuperAdmin = this.itemToSave.isSuperAdmin ? this.itemToSave.isSuperAdmin : false;

    if(!item.id || isReinitPassword)
    {
      item.isLock = false;
      item.password = "000000";
      item.isDefaultPassword = true;
    }

    if(isUpdateStatut)
    {
      item.isLock = !item.isLock; 
    }

    item.datasBoutique = [];

    console.log(" this.itemSelectedBoutiqueSelected ",this.itemSelectedBoutiqueSelected);
   
    if(this.itemSelectedBoutiqueSelected && this.itemSelectedBoutiqueSelected.length > 0) {
      this.itemSelectedBoutiqueSelected.forEach(boutique => {
        item.datasBoutique.push({
          boutiqueId : boutique.id
        })
      });
    }


    swal({
      title: (!obj.id) ? "Enregistrement d'un utilisateur" : "Modification d'un utilisateur",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item,(isUpdateStatut || isReinitPassword) ? true : false); 
      }
    })
  }



  cancelSave() {
    this.itemToSave = {};
    this.itemSelectedBoutiqueSelected = [];
  }

  saveItem(item,isUpdateMainPage?) {
  
    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('user/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Utilisateur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            if(!isUpdateMainPage)
            {
              this.hideModal();
            }
          
            this.cancelSave(); 
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }


  ngOnInit() {
    window.scrollTo(0, 0);
   
    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.getData();
        this.getDataRole();
        this.getDataBoutique();
    
      }
  });

  }



  ngOnDestroy() {

    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }

  }


}
