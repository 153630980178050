import { Component, OnInit, ViewChild, Input,Output, SimpleChanges,EventEmitter } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import * as _ from "lodash";
import * as moment from 'moment';
import { enumStatutStorePro, enumStatutReglementStorePro } from '../../../shared/utils/enumerator';
import { Router } from '@angular/router';
const swal = require('sweetalert2');

@Component({
  selector: 'app-historique-commande',
  templateUrl: './historique-commande.component.html',
  styleUrls: ['./historique-commande.component.css']
})
export class HistoriqueCommandeComponent implements OnInit {

  results: Array<any> = [];
  ListeProduits: Array<any> = [];
  ListeApproToSave: Array<any> = [];
  LigneArticleVendus: Array<any> = [];
  ListeReglements :  Array<any> = [];
  itemDetails: any = {};
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  itemApproToSave: any = {};
  daterange: any = {};
  oldValueSearch: any;
  isChangeItermPerPage: boolean = false;
  enumStatutVente : any = {};
  linkPdfFile : String;
  bsModalRefPreview: BsModalRef;
  enumStatutReglement : any = {};
  subscribeStoreSelected: Subscription;
  storeSelected: any = {};
  sortieIdAfterSearchByCodeImei : number;
  
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService,public router: Router, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService,
    private daterangepickerOptions: DaterangepickerConfig) {

    this.cancelSave();
    let date = new Date();
    let moisActuel = date.getMonth() + 1;
    let anneeActuel = date.getFullYear();
    let jrfinMoisCourant = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let dateDebutMonth = ("01-" + moisActuel + "-" + anneeActuel);
    let dateFinMonth = (jrfinMoisCourant + "-" + moisActuel + "-" + anneeActuel);
    let dateDebutIso = new Date(2022,2,1);
    let dateFinIso = new Date();

    // this.picker.datePicker.setStartDate(moment().subtract(89, 'day'));
    // this.picker.datePicker.setEndDate(new Date()); 

    this.user = this.userService.getCurrentUser();
    this.enumStatutVente = enumStatutStorePro;
    this.enumStatutReglement = enumStatutReglementStorePro;

    this.itemsPerPage = 10;
    this.itemToSearch = {
      dateDebut: dateDebutIso, //this.isHistorique ? dateDebutIso : new Date(),
      dateFin: dateFinIso, //this.isHistorique ? dateFinIso : new Date()
    };
    this.oldValueSearch = "";

    const tableauMois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    let libelleMois = "Ce mois de " + tableauMois[new Date().getMonth()];

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Rechercher',

        "customRangeLabel": "Personnaliser",
        "daysOfWeek": [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ],
        "monthNames": [
          "Janvier",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
      },
      alwaysShowCalendars: false,
      ranges: {

        "Aujourd'hui": moment(),
        "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Les 03 derniers jours': [moment().subtract(2, 'day'), moment()],
        'Les 07 derniers jours': [moment().subtract(6, 'day'), moment()],
        'Les 15 derniers jours': [moment().subtract(14, 'day'), moment()],
        'Les 30 derniers jours': [moment().subtract(29, 'day'), moment()],
        'Les 60 derniers jours': [moment().subtract(59, 'day'), moment()],
        'Les 90 derniers jours': [moment().subtract(89, 'day'), moment()],
        // [libelleMois]: [this.itemToSearch.dateDebut, this.itemToSearch.dateFin],
      }
    };
  }

  @Input() isHistorique;
  @Input() isReadyOnly;
  @Input() isRefresh;
  @Input() idClient;
  @Output() selectedHistoToUpdate : EventEmitter<any> = new EventEmitter();

  maxDate = new Date();


  resetDate() {
    this.picker.datePicker.setStartDate(moment().subtract(89, 'day'));
    this.picker.datePicker.setEndDate(new Date());
  }

  selectedDate(value: any, datepicker?: any, firstRefresh?: boolean) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    if (!firstRefresh) {
      this.itemToSearch.dateDebut = moment(value.start).format('DD/MM/YYYY');
      this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');
      this.daterange.label = value.label;
    }

    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";

    this.getData();
  }

  ngAfterViewInit() {
    this.resetDate();

    setTimeout(() => {
      this.selectedDate(this.picker, this.daterange, true)
    }, 1000);
  }



  openFormModalModal(template, itemToModified, isDetails) {
    this.cancelSave();
  
    if (itemToModified) {

      this.itemDetails = {};
      this.itemDetails = Object.assign({}, itemToModified);
      this.itemToSave = Object.assign({}, itemToModified);
      this.itemToSave.dateSortie =  new Date();
      this.itemToSave.montantPaye = 0;
      this.itemToSave.montantTotalVerse = this.itemToSave.montantTotal - (this.itemToSave.resteAPayer || 0)
      this.LigneArticleVendus = [];
      this.ListeReglements = [];
      //Recuperer les lignes 
      if(isDetails)
      {
        this.getDataLigneCommande(itemToModified.id);
      }
    }

    if(isDetails) {
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-appro' }));
    }else {
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));
    }
   
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  getData() {

    let search_value = this.itemToSearch.designation;
    
    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        clientLibelle: search_value ? search_value : null,
        clientId : this.idClient || null,
        id: this.sortieIdAfterSearchByCodeImei || null,
        createdBy : this.user.profilCode =='COMMERCIAL' ? this.user.id : null,
        createdAt: null,
        createdAtParam: {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post('commande/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            // this.isChangeItermPerPage = false;
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
        }
      );

  }

  

  hideModal() {
    this.modalRef.hide();
  }

  getDataLigneCommande(idCommande) {

    var request = {
      user: this.user.proxy,
      data: {
        boutiqueId: this.userService.getCurrentBoutiqueUser(),
        commandeId : idCommande
      }
    }

    this.busySave = this.api.post('ligneCommande/getByCriteria', request)
      .subscribe(
        res => {
          console.log('voici les lignes sortie', res);
          if (res && res['items']) {
            this.LigneArticleVendus = _.orderBy(res['items'], ['id'], ['desc']);
          } else {
            this.LigneArticleVendus = [];
          }
        },
        err => {
        }
      );
  }

  cancelSave() {
    this.itemToSave = {};
    this.itemDetails = {};
  }

  confirmAnnulerVente(item) {
   
    let objToSave =  Object.assign({}, item);
    objToSave.statut = enumStatutStorePro.ANNULE;

    if(!objToSave.motifStatus) {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le motif de l'annulation !");
      return;
    }

    swal({
      title: 'Annulation commande',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItemAnnulerCommande(objToSave);
      }
    })
  }

  saveItemAnnulerCommande(obj) {
    
    var request = {
      user: this.user.proxy,
      datas: [obj]
    };

    this.busySave = this.api.post('commande/update', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Annulation vente", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.getData();
            this.hideModal();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  
  confirmValiderCommande(item) {
   
    let objToSave =  Object.assign({}, item);

    // if(!objToSave.motifStatus) {
    //   this.notificationService.showMessageError("Erreur","Veuillez renseigner le motif de l'annulation !");
    //   return;
    // }

    swal({
      title: 'Validation commande',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f1444e",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        // objToSave.validateBy = this.user.id;
        this.saveItemValiderCommande(objToSave);
      }
    }) 
  }

  saveItemValiderCommande(obj) {
    
    var request = {
      user: this.user.proxy,
      data: {id : obj.id}
    };

    this.busyGet = this.api.post('commande/validerCommande', request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Valider commande", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  gotoUpdateCommande(commande) {

    localStorage.setItem("commande",JSON.stringify(commande));
    this.router.navigate(['/update-commande/update-commande']);
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscribeStoreSelected = this.userService.currentData.subscribe(currentData => {
      this.storeSelected = currentData;
      if (this.storeSelected && this.storeSelected.id) {
        this.currentPage = 1; 
       this.getData();
      } 
    });

  }

  ngOnDestroy() {
    if (this.subscribeStoreSelected) {
      this.subscribeStoreSelected.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if(changes['isHistorique'] && !changes['isHistorique'].currentValue)
    // {
    //   this.itemToSearch = {
    //     dateDebut:  new Date(),
    //     dateFin:  new Date()
    //   };
    // }

    // if(changes['isRefresh'] && changes['isRefresh'].currentValue)
    // {
    //   this.getData();
    // }
    
    // if(changes['idClient'] && changes['idClient'].currentValue)
    // {
    //   this.getData();
    // }
  }

}
