import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { Router } from '@angular/router';
const swal = require('sweetalert2');
import * as moment from 'moment';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';
import {Observable} from 'rxjs';
import { ConnectionService } from 'ng-connection-service';


@Component({
  selector: 'app-main-vidanges',
  templateUrl: './main-vidanges.component.html',
  styleUrls: ['./main-vidanges.component.css'],
  providers : [] 
}) 
export class MainVidangesComponent implements OnInit {

  
  
  constructor(private api: Api,public router: Router, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig) {
   
  }

  selectedChangeValue(event : any)
  {
   
  } 

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
