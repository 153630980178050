import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";

import {HeaderModule} from "./header/header.module";
import {FooterComponent} from "./footer/footer.component";
import {NavigationModule} from "./navigation/navigation.module";
import {RibbonComponent} from "./ribbon/ribbon.component";
import {ShortcutComponent} from "./shortcut/shortcut.component";
import {ToggleActiveDirective} from "../utils/toggle-active.directive";
import { LayoutSwitcherComponent} from "./layout-switcher.component";
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import { RouterModule} from "@angular/router";
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { TooltipModule, BsDropdownModule, AccordionModule} from "ngx-bootstrap";
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';
import { UtilsModule} from "../utils/utils.module";
import { BusyModule } from "angular2-busy";
import { Preview,FormatMiller,SafeUrlPipe,JsonDate} from '../utils/pipe_volontarait';
import { Api} from '../utils/api/api';
import { UtilitiesService} from '../utils/utilities.service';
import { ModelService} from '../utils/model.service';
import { PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TypeaheadModule } from 'ngx-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import { NgxMaskModule} from 'ngx-mask';
// import { CKEditorModule} from 'ng2-ckeditor';
import { ChartModule } from 'angular-highcharts';
import { MatTabsModule} from '@angular/material/tabs';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SmartadminInputModule} from "../forms/input/smartadmin-input.module";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormReinitPasswordComponent } from '../../+components/form-reinit-password/form-reinit-password.component';
import { MainUpdateProfilUserConnectedComponent } from '../../+components/main-update-profil-user-connected/main-update-profil-user-connected.component';
import { MainStationComponent } from "../../+components/app-boutique/main-station/main-station.component";
import { MainApprovisionnementComponent } from "../../+components/app-boutique/main-approvisionnement/main-approvisionnement.component";
import { MainCategorieProduitComponent } from "../../+components/app-boutique/main-categorie-produit/main-categorie-produit.component";
import { MainConditionnementComponent } from "../../+components/app-boutique/main-conditionnement/main-conditionnement.component";
import { MainProduitComponent } from "../../+components/app-boutique/main-produit/main-produit.component";
import { MainGestionProduitComponent } from "../../+components/app-boutique/main-gestion-produit/main-gestion-produit.component";
import { MainVidangesComponent } from "../../+components/app-boutique/main-vidanges/main-vidanges.component";
import { FormVidangesComponent } from "../../+components/app-boutique/form-vidanges/form-vidanges.component";
import { MainStockComponent } from "../../+components/app-boutique/main-stock/main-stock.component";
import { MainProfilComponent } from "../../+components/main-profil/main-profil.component";
import { MainUtilisateurComponent } from "app/+components/main-utilisateur/main-utilisateur.component";
import { Daterangepicker } from 'ng2-daterangepicker';
import {UiSwitchModule} from "angular2-ui-switch";
import { MainHistoriqueConnexionComponent } from "../../+components/app-boutique/main-historique-connexion/main-historique-connexion.component";
import { FormReconnectComponent } from "../../+components/app-boutique/form-reconnect/form-reconnect.component";
import { MainDepensesComponent } from "../../+components/app-boutique/main-depenses/main-depenses.component";
import { MainSortieHorsVenteComponent } from "../../+components/app-boutique/main-sortie-hors-vente/main-sortie-hors-vente.component";
import { MainComptoireVenteComponent } from "../../+components/app-boutique/main-comptoire-vente/main-comptoire-vente.component";
import { MainHistoriqueVenteComponent } from "../../+components/app-boutique/main-historique-vente/main-historique-vente.component";
import { MainHistoriqueBrouillardComponent } from "../../+components/app-boutique/main-historique-brouillard/main-historique-brouillard.component";
import { MainClientsComponent } from "../../+components/app-boutique/main-clients/main-clients.component";
import { MainFournisseursComponent } from "app/+components/app-boutique/main-fournisseurs/main-fournisseurs.component";
import { MainBilanVentesComponent } from "../../+components/app-boutique/main-bilan-ventes/main-bilan-ventes.component";
import { MainBilanVentesGlobalComponent } from "../../+components/app-boutique/main-bilan-ventes-global/main-bilan-ventes-global.component";
import { MainBilanVentesParClientComponent } from "app/+components/app-boutique/main-bilan-ventes-par-client/main-bilan-ventes-par-client.component";
import { MainBilanVentesParArticleComponent } from "../../+components/app-boutique/main-bilan-ventes-par-article/main-bilan-ventes-par-article.component";
import { FormReglementComponent } from "../../+components/app-boutique/form-reglement/form-reglement.component";
import { MainEcartApproVenteComponent } from "../../+components/app-boutique/main-ecart-appro-vente/main-ecart-appro-vente.component";
import { MainCreditVersementComponent } from "../../+components/app-boutique/main-credit-versement/main-credit-versement.component";
import { UtilisateurStoreProComponent } from "../../+components/app-boutique/utilisateur-store-pro/utilisateur-store-pro.component";
import { ProfilStoreProComponent } from "../../+components/app-boutique/profil-store-pro/profil-store-pro.component";
import { FormPreviewPrintComponent } from "../../+components/app-boutique/form-preview-print/form-preview-print.component";
import { SearchInputClientComponent } from "../../+components/app-boutique/search-input-client/search-input-client.component";
import { SearchInputProduitComponent } from "../../+components/app-boutique/search-input-produit/search-input-produit.component";
import { MainCreditPourToutLeMondeComponent } from "../../+components/app-boutique/main-credit-pour-tout-le-monde/main-credit-pour-tout-le-monde.component";
import { MainBoutiqueComponent } from "../../+components/app-boutique/main-boutique/main-boutique.component";
import { MainFournisseurCreditComponent } from "../../+components/app-boutique/main-fournisseur-credit/main-fournisseur-credit.component";
import { MainFournisseurCreditPourToutLeMondeComponent } from "../../+components/app-boutique/main-fournisseur-credit-pour-tout-le-monde/main-fournisseur-credit-pour-tout-le-monde.component";
import { MainFournisseurCreditParFournisseurComponent } from "../../+components/app-boutique/main-fournisseur-credit-par-fournisseur/main-fournisseur-credit-par-fournisseur.component";
import { MainImeiFournisseurComponent } from "../../+components/app-boutique/main-imei-fournisseur/main-imei-fournisseur.component";
import { MainCompteIcloudComponent } from "../../+components/app-boutique/main-compte-icloud/main-compte-icloud.component";
import { FormTrocSansSortieArticleComponent } from "../../+components/app-boutique/form-troc-sans-sortie-article/form-troc-sans-sortie-article.component";
import { FormTrocAvecSortieArticleComponent } from "../../+components/app-boutique/form-troc-avec-sortie-article/form-troc-avec-sortie-article.component";
import { MainTechnicienComponent } from "../../+components/app-boutique/main-technicien/main-technicien.component";
import { MainReparationComponent } from "../../+components/app-boutique/main-reparation/main-reparation.component";
import { MainTechnicienReparateurComponent } from "../../+components/app-boutique/main-technicien-reparateur/main-technicien-reparateur.component";
import { SearchInputIntervenantComponent } from "../../+components/app-boutique/search-input-intervenant/search-input-intervenant.component";
import { SearchInputArticleComponent } from "../../+components/app-boutique/search-input-article/search-input-article.component";
import { SmartadminModule } from "../smartadmin.module";
import { MainDashbordComponent } from "../../+components/app-boutique/main-dashbord/main-dashbord.component";
import { MainParametreBaseComponent } from "../../+components/app-boutique/main-parametre-base/main-parametre-base.component";
import { MainHistoriqueTrocSansSortieArticleComponent } from "../../+components/app-boutique/main-historique-troc-sans-sortie-article/main-historique-troc-sans-sortie-article.component";
import { MainHistoriqueTrocAvecSortieArticleComponent } from "../../+components/app-boutique/main-historique-troc-avec-sortie-article/main-historique-troc-avec-sortie-article.component";
import { MainCouleurTelephoneComponent } from "../../+components/app-boutique/main-couleur-telephone/main-couleur-telephone.component";
import { MainCapaciteParametrageComponent } from "../../+components/app-boutique/main-capacite-parametrage/main-capacite-parametrage.component";
import { FormCommandeComponent } from "app/+components/app-boutique/form-commande/form-commande.component";
import { HistoriqueCommandeComponent } from "app/+components/app-boutique/historique-commande/historique-commande.component";
import { MainStatistiqueCommercialComponent } from "app/+components/app-boutique/main-statistique-commercial/main-statistique-commercial.component";


@NgModule({
  imports: [
    
    CommonModule,
    HeaderModule, 
    NavigationModule,
    FormsModule,
    RouterModule,
    UtilsModule,
    BusyModule,
    TooltipModule,
    BsDropdownModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    OrderModule,
    NgxMaskModule.forRoot(),
    ChartModule,
    MatTabsModule,
    NgDatepickerModule,
    ImageCropperModule,
    // CKEditorModule,
    NgSelectModule, 
    //SmartadminFormsModule
    SmartadminInputModule,
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    Daterangepicker,
    UiSwitchModule,
    NgMultiSelectDropDownModule,
    //BrowserAnimationsModule,

    // NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyA67CzCCzdTGz8LifE6DvD1JC5VMhCMH2s'})

  ],
  declarations: [
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    RouteBreadcrumbsComponent,
    Preview,
    FormatMiller,
    SafeUrlPipe,
    JsonDate,

    /*SMART HOME */

    FormReinitPasswordComponent,
    MainUpdateProfilUserConnectedComponent,

    /* SHELL COMPONENT */
    MainStationComponent,
    MainApprovisionnementComponent,
    MainCategorieProduitComponent,
    MainConditionnementComponent,
    MainProduitComponent,
    MainGestionProduitComponent,
    MainVidangesComponent,
    FormVidangesComponent,
    MainStockComponent,
    MainProfilComponent,
    MainUtilisateurComponent,
    MainHistoriqueConnexionComponent,
    FormReconnectComponent,
    MainDepensesComponent,
    MainSortieHorsVenteComponent,

    MainComptoireVenteComponent,
    MainHistoriqueVenteComponent,
    MainHistoriqueBrouillardComponent,
    MainFournisseursComponent,
    MainClientsComponent,

    MainBilanVentesComponent,
    MainBilanVentesGlobalComponent,
    MainBilanVentesParClientComponent,
    MainBilanVentesParArticleComponent,
    FormReglementComponent,
    MainEcartApproVenteComponent,
    MainCreditVersementComponent,
    UtilisateurStoreProComponent,
    ProfilStoreProComponent,
    FormPreviewPrintComponent,
    SearchInputClientComponent,
    // SearchInputProduitComponent,
    MainCreditPourToutLeMondeComponent,
    MainBoutiqueComponent,
    MainFournisseurCreditComponent,
    MainFournisseurCreditPourToutLeMondeComponent,
    MainFournisseurCreditParFournisseurComponent,
    MainImeiFournisseurComponent,
    MainCompteIcloudComponent,
    FormTrocSansSortieArticleComponent,
    FormTrocAvecSortieArticleComponent,
    MainTechnicienComponent,
    MainReparationComponent,
    MainTechnicienReparateurComponent,
    SearchInputIntervenantComponent,
    SearchInputArticleComponent,
    MainDashbordComponent,
    SearchInputProduitComponent,
    MainParametreBaseComponent,
    MainHistoriqueTrocAvecSortieArticleComponent,
    MainHistoriqueTrocSansSortieArticleComponent,
    MainCouleurTelephoneComponent,
    MainCapaciteParametrageComponent,
    FormCommandeComponent,
    HistoriqueCommandeComponent,
    MainStatistiqueCommercialComponent,
  ],
 
  exports:[
    HeaderModule,
    NavigationModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,

    /* SMART HOME */
    FormReinitPasswordComponent,
    MainUpdateProfilUserConnectedComponent,

    /* SHELL COMPONENT */
    MainStationComponent,
    MainApprovisionnementComponent,
    MainCategorieProduitComponent,
    MainConditionnementComponent,
    MainProduitComponent,
    MainGestionProduitComponent,
    MainVidangesComponent,
    FormVidangesComponent,
    MainStockComponent,
    MainProfilComponent,
    MainUtilisateurComponent,
    MainHistoriqueConnexionComponent,
    FormReconnectComponent,
    MainDepensesComponent,
    MainSortieHorsVenteComponent,

    MainComptoireVenteComponent,
    MainHistoriqueVenteComponent,
    MainHistoriqueBrouillardComponent,
    MainFournisseursComponent,
    MainClientsComponent,
    MainBilanVentesComponent,
    MainBilanVentesGlobalComponent,
    MainBilanVentesParClientComponent,
    MainBilanVentesParArticleComponent,
    FormReglementComponent,
    MainEcartApproVenteComponent,
    MainCreditVersementComponent,
    UtilisateurStoreProComponent,
    ProfilStoreProComponent,
    FormPreviewPrintComponent,
    SearchInputClientComponent,
    // SearchInputProduitComponent,
    MainCreditPourToutLeMondeComponent,
    MainBoutiqueComponent,
    MainFournisseurCreditComponent,
    MainFournisseurCreditPourToutLeMondeComponent,
    MainFournisseurCreditParFournisseurComponent,
    MainImeiFournisseurComponent,
    MainCompteIcloudComponent,
    FormTrocSansSortieArticleComponent,
    FormTrocAvecSortieArticleComponent,
    MainTechnicienComponent,
    MainReparationComponent,
    MainTechnicienReparateurComponent,
    SearchInputIntervenantComponent,
    SearchInputArticleComponent,
    MainDashbordComponent,
    SearchInputProduitComponent,
    MainParametreBaseComponent,
    MainHistoriqueTrocAvecSortieArticleComponent,
    MainHistoriqueTrocSansSortieArticleComponent,
    MainCouleurTelephoneComponent,
    MainCapaciteParametrageComponent,
    FormCommandeComponent,
    HistoriqueCommandeComponent,
    MainStatistiqueCommercialComponent
  ],
  providers : [Api,UtilitiesService,ModelService],
  bootstrap: [FormReinitPasswordComponent, FormReconnectComponent,FormPreviewPrintComponent,FormTrocSansSortieArticleComponent,FormTrocAvecSortieArticleComponent] 
})
export class SmartadminLayoutModule{

}
